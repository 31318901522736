<template>

  <div>
    <filter-slot
      :filter="visibleFilters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @onChangeCurrentPage="myProvider"
      @reload="myProvider"
    >
      <template #table>
        <b-table-simple
          hover
          small
          responsive
          sticky-header="60vh"
          table-class="text-nowrap"
          class="table_request"
          :busy.sync="isBusy"
        >
          <!-- :::::::::::HEAD:::::::::::::: -->
          <b-thead>
            <b-tr>
              <b-th
                v-for="(value, index) in activeFields"
                :key="'contn' + index"
                class="text-center"
              >
                <div class="text-center" :class="value.tdClass">
                  {{ value.label }}
                </div>
              </b-th>
            </b-tr>
          </b-thead>
          <!-- :::::::::::HEAD:::::::::::::: -->
          <b-tbody>
            <b-tr v-if="isBusy">
              <b-td colspan="11" class="text-center py-2">
                <div class="text-center text-primary">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </b-td>
            </b-tr>

            <b-tr v-if="requestsList.length < 1 && isBusy === false">
              <b-td colspan="11" class="text-center py-2">
                There are no records to show
              </b-td>
            </b-tr>
            <!-- REQUESTS -->
            <template
              v-if="!isBusy"
              v-for="req in requestsList"
            >
              <b-tr
                :key="req.design_request_id"
              >
                <b-td>
                  <div
                    class="d-flex justify-content-between"
                    style="padding-top: 12px; font-size: 14px; font-weight: bold;"
                  >
                    <div class="d-flex">
                      <feather-icon
                        v-if="req.has_contents"
                        size="20"
                        class="cursor-pointer"
                        :class="
                          req.show_contents ? 'text-primary' : 'text-success'
                        "
                        style="margin-top: auto; margin-bottom: auto; transform: scale(1.2);"
                        :icon="
                          req.show_contents
                            ? 'ChevronDownIcon'
                            : 'ChevronRightIcon'
                        "
                        @click="req.show_contents = !req.show_contents"
                      />
                      <span
                        class="ml-1"
                      >
                        {{ req.program }}
                      </span>
                    </div>
                    <div
                      v-if="req.is_draft === 1"
                    >
                      <tabler-icon
                        class="text-info"
                        v-b-tooltip.hover.top="'Draft'"
                        size="20"
                        icon="ClipboardListIcon"
                      />
                    </div>
                  </div>
                </b-td>
                <b-td>
                  <div class="ml-3">
                    {{ req.document_name }}
                  </div>
                </b-td>
                <b-td>
                  <div class="text-center" style="padding-top: 12px">
                    <b-badge variant="primary"    @click="creativeFileModal(req, 'request')" style="padding: 5px 10px 5px 10px">
                      <span style="font-size: 12px">
                        {{ req.pieces }}
                      </span>
                    </b-badge>
                  </div>
                </b-td>
                <b-td v-if="isParagonModule"></b-td>
                <b-td>
                  <div
                    id="flat_picker_due_date_for_task"
                    class="text-center"
                    style="padding-top: 5px"
                  >
                    <flat-pickr
                      :ref="`ref_flat_picker_due_date_for_task_${req.design_request_id}`"
                      v-model="req.due_date"
                      class="form-control cursor-pointer text-center"
                      placeholder="Deadline to be defined"
                      style="width: 15px; border: none; outline: none"
                      :disabled="isAnyRoleAllowed"
                      :config="{
                        allowInvalidPreload: true,
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:S',
                        altFormat: 'm/d/Y h:i K',
                        altInput: true,
                        time_24hr: true,
                      }"
                      @on-close="
                        onStatusChangeOrchangeStageDueDate(req, 'due_date')
                      "
                    />
                  </div>
                </b-td>
                <b-td>
                  <div class="d-flex justify-content-center">
                    <div
                      style="padding-top: 2px; width: 180px"
                      :class="!req.edit_status ? '' : 'd-none'"
                      class="text-center"
                    >
                      <label
                        :for="'req-' + req.design_request_id + 'creative'"
                        class="cursor-pointer"
                        style="padding-left: 15px"
                        @click="setStatusEdition(req)"
                      >
                        <b-badge
                          :variant="statusVariant(req.status_id)"
                          class="cursor-pointer unselectable-text text-center"
                          :class="isTeamLeaderDesign && isPendingTab ? 'my-animated-button' : ''"
                          style="padding: 7px 11px 7px 11px"
                        >
                          <span style="font-size: 12px">
                            {{ req.status }}
                          </span>
                        </b-badge>
                      </label>
                    </div>
                    <v-select
                      v-if="req.edit_status"
                      v-model="req.status_id"
                      :input-id="'req-' + req.design_request_id + 'creative'"
                      :options="filteredStatuses"
                      :reduce="(option) => option.status_id"
                      class="text-center cursor-pointer select-size-sm"
                      label="status"
                      style="width: 150px"
                      :disabled="false"
                      :clearable="false"
                      :append-to-body="true"
                      @input="onStatusChangeOrchangeStageDueDate(req, 'status')"
                      @close="closeSelectStatus(req)"
                    >
                      <template #option="{ status_id }">
                        <div class="text-center">
                          <b-badge :variant="statusVariant(status_id)">
                            {{ statusName(status_id) }}
                          </b-badge>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </b-td>
                <b-td>
                  <div class="text-center" style="padding-top: 4px">
                    <span class="text-center">
                      {{
                        `${
                          req.created_by_user_id === currentUserId
                            ? "You"
                            : req.created_by
                        }`
                      }}
                    </span>
                    <br />
                    <span class="text-center">
                      {{ req.created_at | myGlobalDay }}
                    </span>
                  </div>
                </b-td>
                <b-td>
                  <div class="text-center" style="padding-top: 0px">
                    <div
                      class="d-flex justify-content-center align-content-center"
                    >
                      <div style="padding-top: 0px">
                        <b-button
                          v-if="
                            (isPendingTab && currentUser.modul_id == 27 && req.is_draft == 1)
                          "
                          variant="success"
                          size="sm"
                          class="ml-1"
                          v-b-tooltip.hover.v-success
                          v-b-tooltip.hover.bottom
                          title="Send to Paragon"
                          @click="sendToParagon(req.design_request_id)"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            icon="CheckCircleIcon"
                            size="16"
                          />
                        </b-button>
                        <b-button
                          v-if="
                            (isPendingTab && currentUser.modul_id == 27)
                          "
                          variant="warning"
                          size="sm"
                          class="ml-1"
                          v-b-tooltip.hover.v-warning
                          v-b-tooltip.hover.bottom
                          title="Update Request"
                          @click="openModalNewRequest(req.design_request_id)"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            icon="EditIcon"
                            size="16"
                          />
                        </b-button>
                        <b-button
                          v-if="
                            ((isProcessTab ||
                              isFinishedTab ||
                              isObservedTab ||
                              isApprovedTab) &&
                              isParagonModule ||
                              isCreativeModule) ||                            
                              (isPendingTab && isParagonModule)
                          "
                          variant="primary"
                          size="sm"
                          class="ml-1"
                          style="position: relative;"
                          v-b-tooltip.hover.v-primary
                          v-b-tooltip.hover.bottom
                          title="View Request"
                          @click="openModalShowView(req)"
                        >
                          <div
                            v-if="req.has_pending_tasks && (isPendingTab || isParagonDesigner)"
                            style="
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            z-index: 30;
                            background-color: red;
                            position: absolute;
                            right: -7px;
                            top: -5px;
                            "
                          />
                          <feather-icon

                            icon="EyeIcon"
                            size="16"
                          />
                        </b-button>
                        <b-button
                          v-if="((isProcessTab && !isCreativeModule) || isFinishedTab || isObservedTab || isApprovedTab) && !isParagonDesigner"
                          variant="primary"
                          size="sm"
                          class="ml-1"
                          v-b-tooltip.hover.v-primary
                          v-b-tooltip.hover.bottom
                          style="position: relative;"
                          title="New Handle Design Files"
                          @click="creativeFileModal(req, 'request')"
                        >
                          <div
                            v-if="req.has_pending_tasks || req.has_pending_notifications > 0"
                            style="
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            z-index: 30;
                            background-color: red;
                            position: absolute;
                            right: -7px;
                            top: -5px;
                            "
                          />
                          <feather-icon
                            size="16"
                            icon="FileIcon"
                          />
                        </b-button>
                        <b-button
                          v-if="
                            (isPendingTab && isCreativeModule)
                          "
                          variant="danger"
                          size="sm"
                          class="ml-1"
                          style="position: relative;"
                          v-b-tooltip.hover.v-danger
                          v-b-tooltip.hover.bottom
                          title="Delete Request"
                          @click="deleteRequest(req)"
                        >
                          <feather-icon

                            icon="TrashIcon"
                            size="16"
                          />
                        </b-button>
                        <b-button
                          variant="info"
                          size="sm"
                          class="ml-1"
                          v-b-tooltip.hover.v-info
                          v-b-tooltip.hover.bottom
                          title="Request Tracking"
                          @click="trackingModal(req.design_request_id)"
                        >
                          <feather-icon
                            size="16"
                            icon="ListIcon"
                            class="cursor-pointer"
                          />
                        </b-button>
                      </div>
                      <!--NEW-->
                    </div>
                  </div>
                </b-td>
              </b-tr>
              <!-- CONTENTS -->
              <template
                v-if="req.show_contents"
                v-for="(content, contentIndex) in req.contents"
              >
                <b-tr
                  :key="content.design_request_id"
                  :class="borderOnContentWithoutTasks(contentIndex, req.contents.length, content.has_tasks)"
                >
                  <b-td></b-td>
                  <b-td>
                    <div
                      style="padding-top: 12px;"
                    >
                      <feather-icon
                        v-if="!isCreativeModule && content.has_tasks"
                        size="20"
                        class="cursor-pointer"
                        :class="
                            content.show_tasks ? 'text-primary' : 'text-success'
                          "
                          style="margin-top: auto; margin-bottom: auto; transform: scale(1.2);"
                          :icon="
                            content.show_tasks
                              ? 'ChevronDownIcon'
                              : 'ChevronRightIcon'
                          "
                          @click="content.show_tasks = !content.show_tasks"
                          />
                      <div
                        style="padding-top: 12px; display: inline-block;"
                        :style="content.has_tasks ? 'margin-left: 20px;' : 'margin-left: 43px;'"
                      >
                        {{ content.document_name }}
                      </div>
                    </div>
                  </b-td>
                  <b-td>
                    <div class="text-center">
                      <b-badge
                        variant="info"
                        style="padding: 5px 10px 5px 10px"
                        @click="creativeFileModal(content, 'content')"
                      >
                        <span style="font-size: 12px">
                          {{ content.pieces }}
                        </span>
                      </b-badge>
                    </div>
                  </b-td>
                  <b-td v-if="isParagonModule">
                    <div class="d-flex justify-content-center">
                      <div
                        style="padding-top: 5px; width: 180px"
                        :class="!content.edit_designer ? '' : 'd-none'"
                        class="text-center"
                      >
                        <label
                          :for="'set-' + content.design_request_id + 'designer'"
                          class="cursor-pointer"
                          :class="content.designer_name ? '' : 'text-danger'"
                          style="padding-left: 15px"
                          @click="setDesignerEdition(content)"
                        >
                          {{
                            requestDesignerName(content.designer_name, content.has_tasks)
                          }}
                        </label>
                      </div>

                      <v-select
                        v-if="content.edit_designer"
                        v-model="content.designer_id"
                        :input-id="'set-' + content.design_request_id + 'designer'"
                        :reduce="(user) => user.user_id"
                        :options="optionsAssignee"
                        :clearable="false"
                        style="width: 200px"
                        :append-to-body="true"
                        placeholder="None"
                        label="user_name"
                        @input="changeUserAssigned(content)"
                        @close="closeSelectDesigner(content)"
                      >
                        <template #option="{ user_name }">
                          <div class="custom-option text-center">
                            {{ user_name }}
                          </div>
                        </template>

                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >Please select an option</b-form-select-option
                          >
                        </template>

                        <template #selected-option="{ user_name }">
                          <div class="text-center">
                            <div style="height: 26.88px !important">
                              {{ user_name }}
                            </div>
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </b-td>
                  <b-td>
                    <!-- ::::::Deadline::::::: -->
                    <div id="flat_picker_due_date_for_content" class="text-center">
                      <flat-pickr
                        v-if="!isCreativeModule"
                        :ref="`ref_flat_picker_due_date_for_content_${content.design_request_id}`"
                        v-model="content.due_date"
                        class="form-control cursor-pointer text-center"
                        placeholder="Deadline to be defined"
                        style="width: 15px; border: none; outline: none"
                        :disabled="isAnyRoleAllowed"
                        :config="{
                          allowInvalidPreload: true,
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:S',
                          altFormat: 'm/d/Y h:i K',
                          altInput: true,
                          time_24hr: true,
                        }"
                        @on-close="
                          onStatusChangeOrchangeStageDueDate(content, 'due_date')
                        "
                      />
                    </div>
                  </b-td>

                  <b-td>
                    <div class="d-flex justify-content-center">
                      <div
                        v-if="!isCreativeModule && !isPendingTab"
                        style="padding-top: 2px; width: 180px"
                        :class="!content.edit_status ? '' : 'd-none'"
                        class="text-center"
                      >
                        <label
                          :for="'content-' + content.design_request_id + 'creative'"
                          class="cursor-pointer"
                          style="padding-left: 15px"
                          @click="setStatusEdition(content)"
                        >
                          <b-badge
                            :variant="statusVariant(content.status_id)"
                            class="cursor-pointer unselectable-text text-center"
                            :class="isTeamLeaderDesign && isPendingTab ? 'my-animated-button' : ''"
                            style="padding: 7px 11px 7px 11px"
                          >
                            <span style="font-size: 12px">
                              {{ content.status }}
                            </span>
                          </b-badge>
                        </label>
                      </div>
                      <v-select
                        v-if="content.edit_status"
                        v-model="content.status_id"
                        :input-id="'content-' + content.design_request_id + 'creative'"
                        :options="filteredStatuses"
                        :reduce="(option) => option.status_id"
                        class="text-center cursor-pointer select-size-sm"
                        label="status"
                        style="width: 150px"
                        :disabled="false"
                        :clearable="false"
                        :append-to-body="true"
                        @input="onStatusChangeOrchangeStageDueDate(content, 'status')"
                        @close="closeSelectStatus(content)"
                      >
                        <template #option="{ status_id }">
                          <div class="text-center">
                            <b-badge :variant="statusVariant(status_id)">
                              {{ statusName(status_id) }}
                            </b-badge>
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </b-td>
                  <b-td></b-td>
                  <b-td>
                    <div class="text-center" style="padding-top: 6px">
                      <div
                        class="d-flex justify-content-center"
                        style="padding-top: 0px"
                      >
                        <b-button
                          v-if="(isProcessTab && !isCreativeModule) || isFinishedTab || isObservedTab"
                          variant="primary"
                          size="sm"
                          class="ml-1"
                          v-b-tooltip.hover.v-primary
                          v-b-tooltip.hover.bottom
                          title="New Handle Design Files"
                          style="position: relative;"
                          @click="creativeFileModal(content, 'content')"
                        >
                          <div
                            v-if="content.content_has_unseen_messages || content.content_has_unseen_notifications > 0"
                            style="
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            z-index: 30;
                            background-color: red;
                            position: absolute;
                            right: -7px;
                            top: -5px;
                            "
                          />
                          <feather-icon
                            size="16"
                            icon="FileIcon"
                          />
                        </b-button>
                        <b-button
                          v-if="
                            !isPendingTab &&
                            isParagonModule &&
                            isTeamLeaderDesign
                          "
                          :variant="isDarkSkin ? 'dark' : 'light'"
                          size="sm"
                          class="ml-1"
                          v-b-tooltip.hover.v-primary
                          v-b-tooltip.hover.bottom
                          title="Create task"
                          @click="
                            openTaskSidebar(
                              content.design_request_id,
                              content.document_name
                            )
                          "
                        >
                          <feather-icon icon="LayersIcon" size="16" />
                        </b-button>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <template
                  v-if="content.show_tasks && !isCreativeModule"
                  v-for="(task, taskIndex) in content.tasks"
                >
                  <b-tr
                    :key="task.design_request_id"
                    :class="borderOnLastRow(taskIndex, content.tasks.length, contentIndex, req.contents.length)"
                  >
                    <b-td></b-td>
                    <b-td>
                      <div class="ml-3">
                        {{ task.document_name }}
                      </div>
                    </b-td>
                    <b-td></b-td>
                    <b-td v-if="isParagonModule">
                      <div class="d-flex justify-content-center">
                        <div
                          style="padding-top: 4px; width: 180px"
                          :class="!task.edit_designer ? '' : 'd-none'"
                          class="text-center"
                        >
                          <label
                            :for="'set-' + task.design_request_id + 'designer'"
                            class="cursor-pointer"
                            :class="task.designer_name ? '' : 'text-danger'"
                            style="padding-left: 15px"
                            @click="setDesignerEdition(task)"
                          >
                            {{
                              requestDesignerName(task.designer_name, task.has_contents)
                            }}
                          </label>
                        </div>
                        <v-select
                          v-if="task.edit_designer"
                          v-model="task.designer_id"
                          :input-id="'set-' + task.design_request_id + 'designer'"
                          :reduce="(user) => user.user_id"
                          :options="optionsAssignee"
                          :clearable="false"
                          style="width: 200px"
                          placeholder="To be defined"
                          label="user_name"
                          :append-to-body="true"
                          @input="changeUserAssigned(task)"
                          @close="closeSelectDesigner(task)"
                        >
                          <template #option="{ user_name }">
                            <div class="custom-option text-center">
                              {{ user_name }}
                            </div>
                          </template>
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >Please select an option</b-form-select-option
                            >
                          </template>
                          <template #selected-option="{ user_name }">
                            <div style="height: 26.88px !important">
                              {{ user_name }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </b-td>
                    <b-td>
                      <!-- ::::::Deadline::::::: -->
                      <div id="flat_picker_due_date_for_content" class="text-center">
                        <flat-pickr
                          :ref="`ref_flat_picker_due_date_for_content_${task.design_request_id}`"
                          v-model="task.due_date"
                          class="form-control cursor-pointer text-center"
                          placeholder="Deadline to be defined"
                          style="width: 15px; border: none; outline: none"
                          :disabled="isAnyRoleAllowed"
                          :config="{
                            allowInvalidPreload: true,
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:S',
                            altFormat: 'm/d/Y h:i K',
                            altInput: true,
                            time_24hr: true,
                          }"
                          @on-close="
                            onStatusChangeOrchangeStageDueDate(task, 'due_date')
                          "
                        />
                      </div>
                    </b-td>
                    <b-td>
                      <div class="d-flex justify-content-center">
                        <div
                          style="padding-top: 2px; width: 180px"
                          :class="!task.edit_status ? '' : 'd-none'"
                          class="text-center"
                        >
                          <label
                            :for="'task-' + task.design_request_id + 'creative'"
                            class="cursor-pointer"
                            style="padding-left: 15px"
                            @click="setStatusEdition(task)"
                          >
                            <b-badge
                              :variant="statusVariant(task.status_id)"
                              class="cursor-pointer unselectable-text text-center"
                              :class="isTeamLeaderDesign && isPendingTab ? 'my-animated-button' : ''"
                              style="padding: 7px 11px 7px 11px"
                            >
                              <span style="font-size: 12px">
                                {{ task.status }}
                              </span>
                            </b-badge>
                          </label>
                        </div>
                        <v-select
                          v-if="task.edit_status"
                          v-model="task.status_id"
                          :input-id="'task-' + task.design_request_id + 'creative'"
                          :options="filteredStatuses"
                          :reduce="(option) => option.status_id"
                          class="text-center cursor-pointer select-size-sm"
                          label="status"
                          style="width: 150px"
                          :disabled="false"
                          :clearable="false"
                          :append-to-body="true"
                          @input="onStatusChangeOrchangeStageDueDate(task, 'status')"
                          @close="closeSelectStatus(task)"
                        >
                          <template #option="{ status_id }">
                            <div class="text-center">
                              <b-badge :variant="statusVariant(status_id)">
                                {{ statusName(status_id) }}
                              </b-badge>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </b-td>
                    <b-td></b-td>
                    <b-td>
                      <div class="text-center" style="padding-top: 6px">
                        <div
                          class="d-flex justify-content-center"
                          style="padding-top: 0px"
                        >
                          <b-button
                            v-if="isParagonModule && isTeamLeaderDesign"
                            variant="warning"
                            size="sm"
                            class="ml-1"
                            v-b-tooltip.hover.v-warning
                            v-b-tooltip.hover.bottom
                            title="Edit task"
                            @click="
                              openEditRequestTask(
                                task.design_request_id,
                                task.document_name,
                                task
                              )
                            "
                          >
                            <feather-icon icon="EditIcon" size="16" />
                          </b-button>
                          <b-button
                            v-if="!isParagonDesigner"
                            variant="info"
                            size="sm"
                            class="ml-1"
                            v-b-tooltip.hover.v-info
                            v-b-tooltip.hover.bottom
                            title="Request Tracking"
                            @click="trackingModal(task.design_request_id)"
                          >
                            <feather-icon size="16" icon="ListIcon" />
                          </b-button>
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                </template>
              </template>
              <!-- CHILDREN -->
            </template>
          </b-tbody>
        </b-table-simple>
        <b-modal
          v-if="responseQuestion"
          v-model="responseQuestion"
          modal-class="modal-primary"
          title-class="h3 text-white"
          title="TIME LINE TICKET"
          size="modal-timeline"
          hide-footer
          centered
          hide-header-close
          hide-header
          @hidden="close()"
        >
          <div>
            <div class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2">
              <!-- <div class="ml-auto bg-close text-secondary cursor-pointer" @click="closeResponseQuestion()">
                <feather-icon icon="XIcon" size="16" variant="dark" />
              </div> -->
            </div>
            <div class="d-flex align-items-center justify-content-center mt-1">
              <div class="icon-badge mr-1 pending">
                <feather-icon
                  icon="AlertCircleIcon"
                  size="30"
                  class="text-warning"
                ></feather-icon>
              </div>
            </div>

            <div class="text-center mt-1">
              <span class="text-warning" style="font-size: 25px">
                Are You Sure?
              </span>
            </div>

            <div class="text-center">
              <span style="font-size: 15px">
                You won't be able to revert This!
              </span>
            </div>
            <div class="text-center mt-2">
                               <span>
                SELECT A NEW DEADLINE?
              </span>
            </div>
            <div class="text-center">
              <div class="d-inline-block">
              <flat-pickr
                v-model="newDueDate"
                class="form-control cursor-pointer text-center w-100"
                placeholder="SELECT A NEW DEADLINE?"
                style="border: none; outline: none"
                :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i:S',
                  altFormat: 'm/d/Y h:i K',
                  altInput: true,
                  time_24hr: true,
                }"
              />
              </div>
            </div>
            <br>
            <div class="d-flex justify-content-center">
              <b-button class="mr-2" variant="primary" @click="saveAlertDueDate()" target="_blank">
                Yes
              </b-button>
              <b-button variant="outline-danger" @click="cancelAlertDueDate()" target="_blank">
                Cancel
              </b-button>
            </div>
            <br>
          </div>
        </b-modal>

      </template>
    </filter-slot>
    <tracking-modal
      v-if="showTrackingModal"
      :request-id="requestId"
      @closeModal="showTrackingModal = false"
    />
    <creative-sidebar-task
      v-if="showSiderbarTask"
      :creative-request-id="currentCreativeRequestId"
      :creative-request-name="currentCreativeRequestName"
      :task-edition-mode="taskEditionMode"
      :request-task-data="currentRequestTask"
      @taskCreated="
        myProvider();
        closeTaskSidebar();
      "
      @close="closeTaskSidebar()"
    />
    <creative-revision-modal
      v-if="revisionModalIsOpened"
      :request-id="currentRequestId"
      :data-request="selectDataReques"
      :prgramIdItem="prgramIdItem"
      @close="closeRevisionModal"
      @reloadTable="myProvider()"
    />
    <CreativeFileModal 
      v-if="creativeFileModalOpen"
      :creativeFileModalDesignRequestId = "creativeFileModalDesignRequestId"
      :creativeFileModalTypeRow= "creativeFileModalTypeRow"
      :creativeFileModalDesignRequestStatus="creativeFileModalDesignRequestStatus"
      @reloadTable="myProvider()"
      @close="closeCreativeModal"
    />
  </div>
</template>
<script>
import CreativeSidebarTask from "@/views/commons/components/creative-requests/sidebars/CreativeSidebarTask.vue";
import gridCreativeFilter from "@/views/commons/components/creative-requests/filter/gridCreativeFilter";
import CreativeRequestService from "@/views/commons/components/creative-requests/services/creative.request";
import TrackingModal from "@/views/commons/components/creative-requests/modals/TrackingRequestModal.vue";
import creativeGridFields from "@/views/commons/components/creative-requests/data/creativeGrid.fields";
import CreativeRevisionModal from "@/views/commons/components/creative-requests/components/CreativeRevisionModal.vue";
import CreativeFileModal from "@/views/commons/components/creative-requests/components/CreativeFileModal.vue";
import flatPickr from "vue-flatpickr-component";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    flatPickr,
    TrackingModal,
    CreativeSidebarTask,
    CreativeRevisionModal,
    CreativeFileModal,
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
    }),
    currentModuleId() {
      return this.$route.matched[0].meta.module;
    },
    isCreativeModule() {
      return this.currentModuleId === 27;
    },
    isParagonModule() {
      return this.currentModuleId === 12;
    },
    currentUserId() {
      return this.currentUser.user_id;
    },
    isAnyRoleAllowed() {
      if(this.isApprovedTab){
        return true;
      }
      return !this.isTeamLeaderDesign && !this.isSupervisor && !this.isCeo;
    },
    filteredStatuses() {
      const validStatuses = this.statuses.filter((status) => {
        if (this.currentUser.modul_id === 27) {
          if (this.$route.meta.status === "pending") {
            return false;
          }
          if (this.$route.meta.status === "in process") {
            return false;
          }
          if (this.$route.meta.status === "finished") {
            return status.status_id === 4 || status.status_id === 5;
          }
          if (this.$route.meta.status === "observed") {
            return status.status_id === 4;
          }
        } else if (this.currentUser.modul_id === 12) {
          if (this.$route.meta.status === "pending") {
            return status.status_id === 2;
          }
          if (this.$route.meta.status === "in process") {
            return status.status_id === 2;
          }
          if (this.$route.meta.status === "finished") {
            return false;
          }
          if (this.$route.meta.status === "observed") {
            return status.status_id === 3;
          }
        }
        return true;
      });

      return validStatuses;
    },
    isPendingTab() {
      return this.$route.meta.status == "pending";
    },
    isProcessTab() {
      return this.$route.meta.status == "in process" ? true : false;
    },
    isFinishedTab() {
      return this.$route.meta.status == "finished";
    },
    isObservedTab() {
      return this.$route.meta.status == "observed";
    },
    isApprovedTab() {
      return this.$route.meta.status == "approved";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    activeFields() {
      return this.listFields.filter((field) => field.visible === true);
    },
    providerDesignerId() {
      if (this.isParagonDesigner) {
        return this.currentUserId;
      }
      if (this.isTeamLeaderDesign) {
        return this.visibleFilters[5].model;
      }

      return null;
    },
    visibleFilters() {
      return this.filter.filter(f => f.visible);
    },
  },
  async created() {
    if (this.isCreativeModule) {
      this.listFields[6].visible = false;
    }

    if (!this.isTeamLeaderDesign) {
      this.filter[5].visible = false;
    }
    await Promise.all([
      this.getParagonDesignerUsers(),
      this.getPrograms(),
      this.getDesigners(),
      this.getDesignRequestStatuses(),
    ]);
  },
  data() {
    return {
      prgramIdItem: null,
      currentRequestTask: {
        title: "",
        assignee: null,
        pieces_quantity: null,
        variants_quantity: null,
        status_id: 1,
        due_date: null,
        details: null,
        request_task_id: null,
        document_name: null,
      },
      taskEditionMode: false,
      optionsAssignee: [],
      showChildren: false,
      showSiderbarTask: false,
      filter: gridCreativeFilter,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      nextPage: "",
      requestsList: [],
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      showTrackingModal: false,
      listFields: creativeGridFields,
      revisionModalIsOpened: false,
      creativeFileModalOpen: false,
      selectDataReques: null,
      isBusy: false,
      responseQuestion: false,
      newDueDate: null,
      creativeFileModalDesignRequestId: null,
      idDesignRequest: null,
      statusDesignRequestId: null,
      DesignRequestOldStatusId: null,
      DesignRequesOldDueDate: null,
      creativeFileModalTypeRow: null,
      creativeFileModalDesignRequestStatus: null,
    };
  },
  methods: {
    ...mapActions({
      setDesignRequestChosen: "CreativeDesign/A_DESIGN_REQUEST_CHOSEN",
      refreshCounterSidebar: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
    ...mapMutations({
      refreshTabsCounters: "CreativeDesignStore/SET_COUNTERS",
    }),
    async creativeFileModal(param, type) {
      param.has_pending_tasks = type === 'request' && param.has_pending_tasks ? false : param.has_pending_tasks;
      this.setDesignRequestChosen(param);
      this.creativeFileModalDesignRequestId = param.design_request_id;
      this.creativeFileModalTypeRow = param.type;
      this.creativeFileModalDesignRequestStatus = param.status;
      this.creativeFileModalOpen = true;
    },
    async myProvider(ctx) {
      try {
        this.isBusy = true;
        const params = {
          search: this.filterPrincipal.model,
          program_id: this.visibleFilters[4].model,
          status: this.$route.meta.status,
          designer: this.providerDesignerId,
          from_request: this.visibleFilters[0].model,
          to_request: this.visibleFilters[1].model,
          from_done: this.visibleFilters[2].model,
          to_done: this.visibleFilters[3].model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          user_id: this.currentUserId,
          module_id: this.currentModuleId,
          role_id: this.currentUser.role_id,
        };
        const { data, status } = await CreativeRequestService.getRequests(
          params
        );
        if (status === 200) {
          this.isBusy = false;
          this.startPage = data.from;
          this.nextPage = this.startPage + 1;
          this.toPage = data.to;
          this.totalRows = data.total;
          this.paginate.perPage = data.per_page;
          this.requestsList = data.data || [];
          this.requestsList = this.requestsList.map((req) => {
            let contents = [];
            if (req.contents !== null && req.contents !== undefined) {
              contents = req.contents.map((content) => {
                if (content.tasks !== null && content.tasks !== undefined) {
                  content.tasks = content.tasks.map((task) => {
                    return {
                      ...task,
                      old_designer_id: task.designer_id,
                      edit_status: false,
                      edit_designer: false,
                      old_status_id: task.status_id,
                      old_due_date: task.due_date,
                    };
                  });
                  content.has_tasks = true;
                } else {
                  content.has_tasks = false;
                }

                return {
                  ...content,
                  show_tasks: true,
                  old_designer_id: content.designer_id,
                  edit_status: false,
                  edit_designer: false,
                  old_status_id: content.status_id,
                  old_due_date: content.due_date,
                };
              });
              req.has_contents = true;
            } else {
              req.has_contents = false;
            }
            return {
              ...req,
              old_designer_id: req.designer_id,
              show_contents: false,
              edit_status: false,
              contents,
              edit_designer: false,
              old_status_id: req.status_id,
              old_due_date: req.due_date,
            };
          });
        }
      } catch (e) {
        this.showErrorSwal(e);
        this.isBusy = false;
        return [];
      }
    },
    async getPrograms() {
      const { data } = await CreativeRequestService.getPrograms();
      this.filter[4].options = data;
    },
    async getDesigners() {
      const { data } = await CreativeRequestService.getParagonDesignerUsers();
      this.filter[5].options = data;
    },
    contenttypeVariant(contenType) {
      switch (contenType) {
        case "Flyer":
          return "warning";
        case "Reel":
          return "primary";
        case "GIF":
          return "success";
        case "IGTV":
          return "secondary";
        case "Corporate video":
          return "info";
      }
    },
    async sendToParagon(requestId){
      const { isConfirmed } = await this.showConfirmSwal();
      if(!isConfirmed) return;
      try {
        this.addPreloader();
        const params = {  
          userId: this.currentUser.user_id,
          requestId: requestId
        };
        const { data } = await CreativeRequestService.sendToParagon(params)
        if(data.success){
          this.myProvider();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
        }else{
          this.showToast(
            "danger",
            "top-right",
            "Failed!",
            "XIcon",
            "Failed operation"
          );
        }        
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
    trackingModal(id) {
      this.requestId = id;
      this.showTrackingModal = true;
    },
    cancelAlertDueDate(){
    this.responseQuestion = false;
    this.requestsList.forEach(request => {
        if (request.design_request_id === this.idDesignRequest) {
          request.status_id = this.DesignRequestOldStatusId;
          request.due_date = this.DesignRequesOldDueDate;
          request.edit_status = false;
        }
      });      
      this.idDesignRequest = null;
      this.statusDesignRequestId = null;
      this.DesignRequestOldStatusId = null;
      this.DesignRequesOldDueDate = null;

    },
    async saveAlertDueDate(){
      this.addPreloader();
          const params = {
            "status": this.statusDesignRequestId,
            "dueDate": this.newDueDate,
            "requestId": this.idDesignRequest,
            "userId": this.currentUser.user_id,
            "moduleId" : this.currentUser.modul_id,
            "columnChanged": 'STATUS',
          };
           try {
        const { data } = await CreativeRequestService.changeRequestStatusDuedate(params);
        if(data.success){
              await this.myProvider();
              if(this.$route.meta.status == 'pending'){
                const params2 = {
                  request_id: this.idDesignRequest,
                  module_id: this.currentUser.modul_id,
                  tab: this.$route.meta.status,
                };
                await CreativeRequestService.updateStatusViewMessages(params2);
                this.refreshCounterNotifications();
              }

        this.responseQuestion = false;
        this.idDesignRequest = null;
        this.statusDesignRequestId = null;
        this.DesignRequestOldStatusId = null;
        this.DesignRequesOldDueDate = null;

          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
        }else{
          this.showToast(
            "danger",
            "top-right",
            "Failed!",
            "XIcon",
            "Failed operation"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async onStatusChangeOrchangeStageDueDate(item, type) {
      console.log("onStatusChangeOrchangeStageDueDate", item)
      if (type == "status") {        
        this.idDesignRequest = item.design_request_id;
        this.statusDesignRequestId  = item.status_id;
        this.DesignRequestOldStatusId = item.old_status_id;
        this.DesignRequesOldDueDate = item.old_due_date;
        this.responseQuestion = true;        
        this.newDueDate = item.due_date;
      }

      if (type === 'due_date' && ((item.due_date === item.old_due_date) || item.due_date === '' || item.due_date === null)) return;
      if (!this.isTeamLeaderDesign && !this.isSupervisor && !this.isCeo) return;

      if(!this.responseQuestion){
          const { isConfirmed } = await this.showConfirmSwal();
          if (!isConfirmed) {
            item.status_id = item.old_status_id;
            item.edit_status = false;
            item.due_date = item.old_due_date;
            return;
          }
      //  this.addPreloader();
      const params = {
        "status": type == 'status' ? item.status_id : null,
        "dueDate": type == 'due_date' ? item.due_date : null,
        "requestId": item.design_request_id,
        "userId": this.currentUser.user_id,
        "moduleId" : this.currentUser.modul_id,
        "columnChanged": type == 'status' ? 'STATUS' : 'DUE_DATE',
        "user_name": this.currentUser.fullName,
        "document_name": item.document_name,
        "old_due_date": item.old_due_date,
        "option": item.type,
        "designer_id" : item.designer_id,
        "parent_document_name" : item.parent_document_name
      };
      try {
        const { data } = await CreativeRequestService.changeRequestStatusDuedate(params);
        if(data.success){
          if (type !== 'due_date') {
              await this.myProvider();
          }
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
        }else{
          this.showToast(
            "danger",
            "top-right",
            "Failed!",
            "XIcon",
            "Failed operation"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
      }
     
    },
    statusVariant(statusId) {
      switch (statusId) {
        case 1:
          return "warning";
        case 2:
          return "primary";
        case 3:
          return "secondary";
        case 4:
          return "info";
        case 5:
          return "success";
      }
    },
    statusName(statusId) {
      return this.statuses.find((status) => status.status_id === statusId)
        .status;
    },
    openTaskSidebar(creativeRequestId, creativeRequestName) {
      this.currentCreativeRequestName = creativeRequestName;
      this.currentCreativeRequestId = creativeRequestId;
      this.showSiderbarTask = true;
    },
    closeTaskSidebar() {
      this.currentCreativeRequestName = null;
      this.currentCreativeRequestId = null;
      this.currentRequestTask = {
        title: "",
        assignee: null,
        pieces_quantity: null,
        variants_quantity: null,
        status_id: null,
        due_date: null,
        details: null,
        request_task_id: null,
        document_name: null,
      };
      this.taskEditionMode = false;
      this.showSiderbarTask = false;
    },
    async setStatusEdition(req) {
      if (req.type === 'task' && req.status_id !== 2) { // Enable change status on tasks
        req.edit_status = true;
      }

      if (!this.isTeamLeaderDesign && !this.isCeo && !this.isSupervisor) {
        return;
      }

      if (this.isCreativeModule) {
        return;
      }

      if (this.isParagonModule && !this.isPendingTab && !this.isProcessTab) {
        return;
      }

      if (this.isParagonModule && req.status_id === 2) {
        return;
      }

      req.edit_status = true;
    },
    setDesignerEdition(req) {
      if (
        !this.isParagonModule ||
        !this.isProcessTab ||
        !this.isTeamLeaderDesign
      ) {
        return;
      }
      req.edit_designer = true;
    },
    closeSelectDesigner(item) {
      this.$set(item, "edit_designer", false);
    },
    closeSelectStatus(item) {
      this.$set(item, "edit_status", false);
    },
    openEditRequestTask(creativeRequestId, creativeRequestName, task) {
      this.currentRequestTask = {
        title: creativeRequestName,
        assignee: task.designer_id,
        pieces_quantity: task.pieces,
        variants_quantity: task.variants,
        status_id: task.status_id,
        due_date: task.due_date,
        details: task.observation,
        request_task_id: task.design_request_id,
        document_name: task.document_name,
      };
      this.currentCreativeRequestName = creativeRequestName;
      this.currentCreativeRequestId = creativeRequestId;
      this.taskEditionMode = true;
      this.showSiderbarTask = true;
    },
    async getParagonDesignerUsers() {
      try {
        const { data } = await CreativeRequestService.getParagonDesignerUsers();
        this.optionsAssignee = data;
      } catch (err) {
        console.error("Error in getParagonDesignerUsers", err);
      }
    },
    async changeUserAssigned(item) {
      if (!this.isTeamLeaderDesign && !this.isSupervisor && !this.isCeo) return;
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) {
        item.edit_designer = false;
        item.designer_id = item.old_designer_id;
        return;
      }
      try {
        const params = {
          requestId: item.design_request_id,
          userId: this.currentUserId,
          designerId: item.designer_id,
        };
        const { success } =
          await CreativeRequestService.changeRequestStatusDuedate(params);
        if (!success) {
          const newDesignerName = this.optionsAssignee.find(
            (user) => user.user_id === item.designer_id
          );
          this.$set(item, "designer_name", newDesignerName.user_name);
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            `A task for ${newDesignerName.user_name} has been created`
          );
        }
      } catch (err) {
        console.error("Error in changeUserAssigned", err);
      } finally {
        item.edit_designer = false;
      }
    },
    async getDesignRequestStatuses() {
      try {
        const { data } =
          await CreativeRequestService.getDesignRequestStatuses();
        this.statuses = data;
      } catch (err) {
        console.error("Error in getDesignRequestStatuses", err);
      }
    },
    openModalNewRequest(design_request_id) {
      this.$emit("currentTabStatus", this.$route.meta.status)
      this.$emit("openModalNewRequest", design_request_id);
    },
    async openRevisionModal(item) {
      this.currentRequestId = item.design_request_id;
      this.selectDataReques = item;
      this.revisionModalIsOpened = true;
      this.prgramIdItem = item.program_id;

      if (item.module_id == this.currentUser.modul_id) {
        const params = {
          request_id: item.design_request_id,
          module_id: this.currentUser.modul_id,
          tab: item.status,
        };
        await CreativeRequestService.updateStatusViewMessages(params);
        this.refreshCounterNotifications();
      }
    },
    async openModalShowView(data) {
      this.$emit("currentTabStatus", this.$route.meta.status)
      this.$emit("openModalOnlyViewRequest", data.design_request_id);
      if (data.has_pending_tasks && this.isPendingTab) {
        const params = {
          request_id: data.design_request_id,
          module_id: this.currentUser.modul_id,
          tab: data.status,
        };
        await CreativeRequestService.updateStatusViewMessages(params);
        this.refreshCounterNotifications();
        data.has_pending_tasks = data.has_pending_tasks ? false : data.has_pending_tasks;
      }
    },
    openCreativeFileModal(param){
      console.log("openCreativeFileModal", param)
      this.creativeFileModalDesignRequestId = param.design_request_id;
      this.creativeFileModalOpen = true;
    },
    closeCreativeModal(params) {
      const parentRequestId = params.parent_request_id;
      const designRequestId = params.design_request_id;
      const { type } = params;
      const hasUnseenNotifications = params.notificationCounter > 0 ? 1 : 0;
      this.creativeFileModalOpen = false;

      // check messages
      this.requestsList.some(item => {
        if (item.parent_request_id === parentRequestId) {
          item.contents.forEach(itemC => {
            if (itemC.design_request_id === designRequestId) {
              itemC.content_has_unseen_messages = params.hasUnseenMessages === false ? 0 : 1;
            }
          });

          return true; // Exit the loop once the condition is met
        }

        return false; // Continue to the next iteration of the outer loop
      });

      // check notifications
      if (hasUnseenNotifications === 0) {
        if (type == 'request') {
          this.requestsList.some(item => {
            if (item.parent_request_id === parentRequestId) {
              item.has_pending_notifications = 0;

              item.contents.forEach(itemC => {
                if (itemC.parent_request_id === parentRequestId) {
                  itemC.content_has_unseen_notifications = 0;
                }
              });

              return true; // Exit the loop once the condition is met
            }

            return false; // Continue to the next iteration of the outer loop
          });
        }

        if (type == 'content') {
          this.requestsList.some(item => {
            if (item.parent_request_id === parentRequestId) {
              item.has_pending_notifications -= 1;

              return item.contents.some(itemC => {
                if (itemC.design_request_id === designRequestId) {
                  itemC.content_has_unseen_notifications = 0;
                  return true; // Exit the inner loop
                }
                return false;
              });
            }
            return false; // Continue to the next iteration of the outer loop
          });
        }
      }
    },
    closeResponseQuestion() {
      this.responseQuestion = false;
      this.idDesignRequest = null;
      this.statusDesignRequest = null;
    },
    async closeRevisionModal() {
      this.currentRequestId = null;
      this.revisionModalIsOpened = false;
    },
    setCounter(item) {
      if (this.moduleId === 12) {
        return item.messages_paragon;
      }
      return item.messages_creative;
    },
    borderOnLastRow(nTask, numberOfTasks, nContent, numberOfContents) {
      if (nTask === numberOfTasks - 1 && nContent === numberOfContents - 1) {
        if (this.isDarkSkin) {
          return 'border-limiter-for-dark';
        }
        return 'border-limiter-for-light';
      }
      return '';
    },
    borderOnContentWithoutTasks(nContent, numberOfContents, hasTasks) {
      if (nContent === numberOfContents - 1 && !hasTasks) {
        return this.isDarkSkin ? 'border-limiter-for-dark' : 'border-limiter-for-light';
      }
      return '';
    },
    requestDesignerName(designerName, hasTasks) {
      // if (hasTasks) {
      //   return "";
      // }
      return designerName ?? "To be defined";
    },
    async refreshCounterNotifications() {
      const params = {
        modul_id: this.moduleId,
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
      };

      const module = this.moduleId;
      if (module == 27) {
        const { data } =
          await CreativeRequestService.getdesignRequestCounterNotifications(
            params
          );
        const payload = {
          routeName: "creative-request-options",
          tag: data[0].total,
        };
        this.refreshCounterSidebar(payload);
        this.refreshTabsCounters(data[0]);
      } else {
        const { data } =
          await CreativeRequestService.getdesignRequestCounterNotifications(
            params
          );
        const payload = {
          routeName: "creative-request-options-ti",
          tag: data[0].total,
        };
        this.refreshCounterSidebar(payload);
        this.refreshTabsCounters(data[0]);
      }
    },
    async deleteRequest(req) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        const params = {
          designRequestId: req.design_request_id,
          userId: this.currentUser.user_id,
        };
        const { status } = await CreativeRequestService.deleteRequest(params);
        this.addPreloader();
        if (status === 200) {
          this.removePreloader();
          await this.myProvider();
          this.showSuccessSwal();
        }
      } catch (error) {
        this.removePreloader();
        console.log('src/views/commons/components/creative-requests/components/RequestInProcessGrid.vue in method deleteRequest', error);
      }
    },
  },
};
</script>
<style scoped>
td {
  border: none !important;
}

.unselectable-text {
  user-select: none;
}

.nested-enter-active,
.nested-leave-active {
  transition: all 1.3s ease-in-out;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

/* we can also transition nested elements using nested selectors */
.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}
/* delay enter of nested element */
.nested-enter-active .inner {
  transition-delay: 1.25s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
.table_request tbody tr td div {
  padding-top: 10px;
}
</style>
<style>
.border-limiter-for-dark {
  border-bottom: 1px solid #313133 !important;
}

.border-limiter-for-light {
  border-bottom: 1px solid rgb(221, 221, 223) !important;
}

#flat_picker_due_date_for_task input {
  border: 0px;
  padding-right: 0px;
}

#flat_picker_due_date_for_task .flatpickr-calendar {
  position: absolute !important;
  display: block !important;
  left: 810px !important;
}

#flat_picker_due_date_for_content input {
  border: 0px;
  padding-right: 0px;
}

#flat_picker_due_date_for_content .flatpickr-calendar {
  position: absolute !important;
  display: block !important;
  left: 810px !important;
}

#flat_picker_due_date_for_parent input {
  border: 0px;
  padding-right: 0px;
}

#flat_picker_due_date_for_parent .flatpickr-calendar {
  position: absolute !important;
  display: block !important;
  left: 810px !important;
}
</style>
<style>
#flat_picker_due_date_parent input {
  border: 0px;
  padding-right: 0px;
}

#flat_picker_start_date_parent input {
  border: 0px;
  padding-right: 0px;
}

#flat_picker_start_date_parent .flatpickr-calendar {
  position: absolute !important;
  display: block !important;
  left: 810px !important;
}

#flat_picker_due_date_parent .flatpickr-calendar {
  position: absolute !important;
  display: block !important;
  left: 810px !important;
}

.pulsating-icon {
  animation: pulse 0.4s infinite alternate;
}
.pulsating-iconblue {
  animation: pulseblue 0.4s infinite alternate;
}
@keyframes pulse {
  from {
    color: #3c96f1;
  }
  to {
    color: rgb(109, 206, 231);
  }
}
@keyframes pulseblue {
  from {
    color: #3c96f1;
  }
  to {
    color: rgb(109, 206, 231);
  }
}

@keyframes scaleBadgeAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.my-animated-button {
  animation: scaleBadgeAnimation 1.5s infinite ease-in-out;
}

.my-animated-button:hover {
  animation-play-state: paused;
}
</style>
