export default [
    {
        key: "status",
        label: "STATUS",
        tdClass: "text-center",
        thClass: "text-center",
    },
    {
        key: "due_date",
        label: "DEADLINE",
        tdClass: "text-center",
        thClass: "text-center",
    },
    {
        key: "created_by",
        label: "CREATED BY",
        tdClass: "text-center",
        thClass: "text-center",
    },
    {
        key: "created_at",
        label: "CREATED AT",
        tdClass: "text-center",
        thClass: "text-center",
    },
]