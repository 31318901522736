export default [
    {
        key: "preview",
        label: "PREVIEW",
        tdClass: "text-center",
        thClass: "text-center",
    },
    {
        key: "status",
        label: "STATUS",
        tdClass: "text-center",
        thClass: "text-center",
    },
    {
        key: "created_by",
        label: "UPLOADED BY",
        tdClass: "text-center",
        thClass: "text-center",
    },
]