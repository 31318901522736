<template>
  <div>
    <!-- Table Top -->
    <b-row class="justify-content-end mb-1">
      <!-- Search -->
      <b-col cols="12" md="4">
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
      </b-col>
      <!-- Search -->
      <b-col cols="12" md="1">
        <b-button
          variant="outline-info"
          class="w-100"
          v-b-tooltip.hover.top="'Refresh Table'"
          @click="myProvider"
          ><tabler-icon icon="RefreshIcon"
        /></b-button>
      </b-col>
    </b-row>
    <b-table
      ref="mm-setting-source-table"
      no-border-collapse
      show-empty
      sticky-header="50vh"
      class="position-relative"
      thead-class="text-nowrap"
      :busy.sync="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="items"
      :filter="searchQuery"
      :filter-included-fields="filterOn"
      :fields="showFields"
    >
      <template #cell(name)="data">
        <span class="text-capitalize"> {{ data.value }} </span><br />
      </template>
      <template #cell(initial)="data">
        <span class="text-capitalize"> {{ data.value }} </span><br />
      </template>
      <template #cell(icon_url)="data">
        <b-img
          v-if="data.value != null || data.value != ''"
          :src="data.value"
          thumbnail
          style="width: 35px"
        ></b-img>
      </template>
      <template #cell(source_detail)="data">
        <b-badge
          v-for="(source, index) in data.item.source_detail"
          :key="index"
          pill
          v-b-tooltip.hover.top="
            source.status == 'Active' ? 'Activate' : 'Inactivate'
          "
          :variant="
            source.status == 'Active' ? 'light-primary' : 'light-danger'
          "
          :class="{ 'contact-inactive': source.status == 'Inactive' }"
          class="mr-1 cursor-pointer"
          style="margin-top: 8px"
        >
          <span> {{ source.name }}</span>
        </b-badge>
      </template>
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${data.value == 'Active' ? 'success' : 'danger'}`"
        >
          <span>{{ data.value }}</span>
        </b-badge>
      </template>
      <template #cell(created_by)="data">
        <span> {{ data.value }} </span><br />
        <span> {{ data.item.created_at | myGlobalDay }} </span>
      </template>
      <template #cell(updated_by)="data">
        <span> {{ data.value }} </span><br />
        <span> {{ data.item.updated_at | myGlobalDay }} </span>
      </template>
      <template #cell(id)="data">
        <div class="d-flex">
          <b-form-checkbox
            :checked="data.item.status == 'Active' ? true : false"
            v-b-tooltip.hover.top="
              data.item.status == 'Active'
                ? 'Inactivate Source'
                : 'Activate Source'
            "
            :ref="'status' + data.index"
            @change="updateStatusContactMethod(data.item, 2, data.index)"
            class="custom-control-success"
            name="check-button"
            switch
          />
          <tabler-icon
            v-if="getTab == 1 && data.item.status == 'Active'"
            icon="EditIcon"
            v-b-tooltip.hover.right="'Edit Source'"
            size="20"
            class="text-warning cursor-pointer ml-2"
            @click="editSource(data.item)"
          />
          <tabler-icon
            v-if="getTab == 2 && data.item.status == 'Active'"
            icon="EditIcon"
            v-b-tooltip.hover.right="'Edit Contact Method'"
            size="20"
            class="text-warning cursor-pointer ml-2"
            @click="editContactMethod(data.item)"
          />
          <feather-icon
            v-if="getTab == 1 && data.item.status == 'Inactive'"
            icon="TrashIcon"
            size="20"
            class="text-danger mr-2 cursor-pointer ml-2"
            @click="deleteSource(data.item)"
          />
          <feather-icon
            v-if="getTab == 2 && data.item.status == 'Inactive'"
            icon="TrashIcon"
            size="20"
            class="text-danger mr-2 cursor-pointer ml-2"
            @click="deleteContactMethod(data.item)"
          />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FieldsSoruce from "@/views/social-network/views/settings/data/fields-source.data.js";
import SettingsService from "@/views/social-network/views/settings/settings.service.js";

export default {
  data() {
    return {
      filters: [],
      items: [],
      searchQuery: null,
      filterOn: [],
      fields: FieldsSoruce,
      searchInput: "",
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      sortBy: "",
      sortDesc: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getTab() {
      return this.$route.meta.tab;
    },
    showFields() {
      return this.fields.filter((item) => item.visible);
    },
  },
  created() {
    this.fields.map((item) => (item.visible = true));
    if (this.getTab == 1) {
      this.fields[4].visible = false;
    } else {
      this.fields[1].visible = false;
      this.fields[2].visible = false;
      this.fields[3].visible = false;
    }
    this.myProvider();
  },
  methods: {
    myProvider: async function () {
      try {
        this.addPreloader();
        let data = [];
        if (this.getTab == 1) {
          const params = {};
          data = await SettingsService.getSources(params);
          this.items = data.data || [];
        } else {
          const params = { id: null };
          data = await SettingsService.getContactMethod(params);
          this.items = data.data || [];
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    async updateStatusContactMethod(item, status, index) {
      try {
        let status = this.$refs[`status${index}`].$refs.input.checked;
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          const _status = item.status == "Active" ? 2 : 1;
          const params = {
            id: item.id,
            status: _status,
            user_id: this.currentUser.user_id,
          };
          let data;
          if (this.getTab == 1) {
            data = await SettingsService.updateStatusSource(params);
          } else {
            data = await SettingsService.updateStatusContactMethod(params);
          }
          if (data.status == 200) {
            this.showSuccessSwal("Success", "Operation Success.");
            this.myProvider();
          }
        } else {
          this.$refs[`status${index}`].$refs.input.checked = !status;
        }
      } catch (error) {
        if (error.response?.data?.message) {
          this.showErrorSwal(error.response?.data?.message);
        } else {
          this.showErrorSwal(error);
        }
      } finally {
        this.removePreloader();
      }
    },
    refreshTable() {
      this.myProvider();
    },
    editContactMethod(item) {
      this.$emit("showContactMethodModal", item);
    },
    editSource(item) {
      this.$emit("showSourceModal", item);
    },
    async deleteContactMethod(item) {
      const params ={
        id : item.id,
        user_id: this.currentUser.user_id,
      }
      try{
        const confirm = await this.showConfirmSwal();
        if (confirm.value){
          const response = await SettingsService.deleteContactMethod(params);
          if(response.status==200){
            this.refreshTable();
            this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
          }
        }
      }catch(error){
        this.showErrorSwal(error);
      }
    },
    async deleteSource(item) {
      const params ={
        id : item.id,
        user_id: this.currentUser.user_id,
      }
      try{
        const confirm = await this.showConfirmSwal();
        if (confirm.value){
          const response = await SettingsService.deleteSource(params);
          if(response.status==200){
            this.refreshTable();
            this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
          }
        }
      }catch(error){
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style>
.contact-inactive {
  opacity: 0.5;
}
</style>