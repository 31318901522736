<template>
  <div>
    <b-modal
      ref="source-modal"
      :title="
        modalData.id != null ? 'EDIT CONTACT METHOD' : 'CREATE CONTACT METHOD'
      "
      v-model="modalSearch"
      size="sm"
      modal-class="modal-primary"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      title-tag="h3"
    >
      <validation-observer ref="formContactMethod">
        <b-form>
          <b-row class="pt-1">
            <b-col md="12" class="">
              <validation-provider rules="required|max:30" v-slot="{ errors }">
                <b-form-group label="Name" label-for="name">
                  <b-form-input
                    class="text-uppercase"
                    v-model="form.name"
                    :class="errors[0] ? 'border-danger' : ''"
                    id="name"
                  />
                  <span v-if="errors[0]" class="text-danger">
                    Name
                    {{
                      errors[0] == "is max" ? "is max 30 characters" : errors[0]
                    }}</span
                  >
                </b-form-group>
              </validation-provider>

              <validation-provider rules="required|max:150" v-slot="{ errors }">
                <b-form-group label="Description" label-for="description">
                  <b-form-textarea
                    id="description"
                    v-model="form.description"
                    placeholder="Enter something..."
                    :class="errors[0] ? 'border-danger' : ''"
                    rows="3"
                    max-rows="3"
                  ></b-form-textarea>
                  <span v-if="errors[0]" class="text-danger">
                    Description
                    {{
                      errors[0] == "is max"
                        ? "is max 150 characters"
                        : errors[0]
                    }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <!-- save button -->
            <b-col cols="12" md="12" class="mt-1 text-center">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="saveContactMethod"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import SettingsService from "@/views/social-network/views/settings/settings.service.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Import Data:class="smsImage.switch ? '' : ''"
import { mapGetters } from "vuex";
export default {
  components: {
    ToastificationContent,
  },
  props: {
    modalData: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        id: this.modalData.id,
        name: this.modalData.name,
        description: this.modalData.description,
        user_id: null,
        status: true,
      },
      modalSearch: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    imageShow() {
      return this.form.icon_url;
    },
  },
  mounted() {
    this.toggleModal("source-modal");
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("hideModal");
    },
    async saveContactMethod() {
      try {
        const result = await this.$refs.formContactMethod.validate();
        if (result) {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            this.addPreloader();
            this.form.user_id = this.currentUser.user_id;
            const { data } = await SettingsService.saveContactMethod(this.form);
            if (data.type == "success") {
              this.showSuccessSwal(data.message);
              this.$emit("hideModal", true);
            } else {
              this.showInfoSwal(data.type, data.message);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.my {
  margin: 6px 0 0 6px;
}
</style>
