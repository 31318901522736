<template>
  <div>
    <b-modal
      ref="source-modal"
      :title="modalData.id != null ? 'EDIT SOURCE' : 'CREATE SOURCE'"
      v-model="modalSearch"
      size="lg"
      modal-class="modal-primary"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      title-tag="h3"
    >
      <validation-observer ref="formSource">
        <b-form>
          <h4 class="mt-2 p-0">Source Information</h4>
          <hr class="p-0 m-0" />
          <b-row class="pl-1 pr-1 pt-1">
            <b-col md="8" class="">
              <validation-provider rules="required|max:30" v-slot="{ errors }">
                <b-form-group label="Name" label-for="name">
                  <b-form-input
                    class="text-uppercase"
                    v-model="form.name"
                    :class="errors[0] ? 'border-danger' : ''"
                    id="name"
                  />
                  <span v-if="errors[0]" class="text-danger">
                    Name
                    {{
                      errors[0] == "is max" ? "is max 30 characters" : errors[0]
                    }}</span
                  >
                </b-form-group>
              </validation-provider>

              <validation-provider rules="required|max:10" v-slot="{ errors }">
                <b-form-group label="Initial" label-for="initial">
                  <b-form-input
                    class="text-uppercase"
                    v-model="form.initial"
                    :class="errors[0] ? 'border-danger' : ''"
                    id="initial"
                  />
                  <span v-if="errors[0]" class="text-danger">
                    Initial
                    {{
                      errors[0] == "is max" ? "is max 10 characters" : errors[0]
                    }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="4"
              class="d-flex align-item-center justify-content-center"
            >
              <b-form-group>
                <label for="file_input_1" class="mb-1">Icon: </label>
                <div class="image-upload">
                  <label for="file_input_1" class="label-image">
                    <div class="form-group cursor-pointer m-0">
                      <input
                        type="file"
                        id="file_input_1"
                        accept="image/*"
                        @change="getImage"
                        hidden
                      />
                      <figure class="figure">
                        <img
                          class="image-message"
                          :src="imageShow"
                          :style="form.width_image"
                          style="max-height: 80px; min-height: 80px"
                          alt="Icon"
                        />
                      </figure>
                    </div>
                  </label>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <h4 class="mt-3 p-0">List of Contact Methods</h4>
          <hr />
          <b-row class="pr-1 pl-1">
            <b-col sm="6" md="6" lg="8">
              <b-form-group label-for="hid">
                <template #label>
                  <div class="d-flex justify-content-between">
                    <span>Contact Method</span>
                    <tabler-icon
                      icon="PlusIcon"
                      size="18"
                      class="text-success cursor-pointer"
                      v-b-tooltip.hover.right="'Create Contact Method'"
                      @click="createContactMethod"
                    />
                  </div>
                </template>
                <v-select
                  :options="contactMethods"
                  label="name"
                  v-model="sourceSelect"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" md="3" lg="2">
              <b-form-group label="From Flayer">
                <b-form-checkbox
                  checked="true"
                  v-model="is_from_flyer"
                  class="custom-control-success"
                  name="check-button"
                  style="margin-top: 6px"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" md="3" lg="2">
              <b-form-group>
                <template #label>&nbsp;</template>
                <b-button
                  variant="outline-success w-100"
                  @click="addItemSource"
                >
                  <feather-icon icon="PlusIcon" class="text-success" />
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div
                style="max-height: 300px; overflow-y: auto; overflow-x: hidden"
              >
                <div
                  v-for="(source, index) in form.source_detail"
                  :key="index"
                  class="mt-1 mb-1 border-bottom row justify-content-between overflow-auto"
                  :class="{ 'contact-inactive': !source.status }"
                >
                  <b-col sm="5" md="5" lg="8" class="d-flex">
                    <tabler-icon icon="SocialIcon" class="text-success" />
                    <span class="ml-1" style="opacity: 1">
                      {{ source.name }}
                    </span>
                  </b-col>
                  <b-col sm="3" md="3" lg="2">
                    <b-badge
                      pill
                      :variant="
                        source.is_from_flyer == 1
                          ? 'light-success'
                          : 'light-danger'
                      "
                      class="mr-1"
                    >
                      <span>
                        {{
                          source.is_from_flyer == 1
                            ? "From Flyer"
                            : "Not From Flyer"
                        }}</span
                      >
                    </b-badge>
                  </b-col>
                  <b-col sm="2" md="2" lg="1">
                    <b-form-checkbox
                      checked="true"
                      v-model="source.status"
                      v-b-tooltip.hover.right="
                        source.status
                          ? 'Inactivate Contact Method'
                          : 'Activate Contact Method'
                      "
                      :ref="'status' + index"
                      @change="changeStatus(index)"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    />
                  </b-col>
                  <b-col sm="2" md="2" lg="1">
                    <feather-icon
                      v-if="source.id == null"
                      icon="DeleteIcon"
                      size="20"
                      class="text-danger mr-2 cursor-pointer"
                      @click="deleteItem(source)"
                    />
                  </b-col>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12" class="mt-1 text-center">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="saveSource"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import SettingsService from "@/views/social-network/views/settings/settings.service.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ToastificationContent,
  },
  props: {
    modalData: {
      type: Object,
    },
  },
  data() {
    return {
      modalSearch: true,
      contactMethods: [],
      sourceSelect: null,
      is_from_flyer: false,
      form: {
        id: this.modalData.id,
        name: this.modalData.name,
        initial: this.modalData.initial,
        icon_url:
          this.modalData.icon_url == null || this.modalData.icon_url == ""
            ? "/assets/images/icons/sms-img.png"
            : this.modalData.icon_url,
        icon_name: this.modalData.icon_name,
        width_image: "width:100%",
        user_id: null,
        source_detail: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    imageShow() {
      return this.form.icon_url;
    },
  },
  mounted() {
    this.toggleModal("source-modal");
  },
  created() {
    if (this.modalData.id != null) {
      this.getContactMethodBySource();
    }
    this.getContactMethod();
  },
  methods: {
    closeModal() {
      this.$emit("hideModal");
    },
    async saveSource() {
      try {
        const result = await this.$refs.formSource.validate();
        if (result) {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            this.addPreloader();
            if (this.form.icon_url == "/assets/images/icons/sms-img.png") {
              this.form.icon_name = null;
            }
            this.form.user_id = this.currentUser.user_id;
            const { data } = await SettingsService.saveSource(this.form);
            if (data.type == "success") {
              this.showSuccessSwal(data.message);
              this.$emit("hideModal", true);
            } else {
              this.showInfoSwal(data.type, data.message);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    async getContactMethodBySource() {
      try {
        const { data } = await SettingsService.getContactMethodBySource({
          id: this.modalData.id,
        });
        data.map((item) => {
          item.status = Boolean(item.status);
          item.is_from_flyer = Boolean(item.is_from_flyer);
        });
        this.form.source_detail = data;
      } catch (error) {
        console.log(error);
      }
    },
    changeStatus(index) {
      const isChecked = this.$refs[`status${index}`][0].$refs.input.checked;
      if (isChecked) {
        this.form.source_detail[index].status_name = "Active";
      } else {
        this.form.source_detail[index].status_name = "Inactive";
      }
    },
    async getContactMethod() {
      try {
        this.addPreloader();
        const { data } = await SettingsService.getContactMethod({});
        this.contactMethods = data.filter((item) => item.status == "Active");
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    getImage(e) {
      let file = e.target.files[0];
      if (file != undefined) {
        this.form.icon_url = file;
        this.form.icon_name = file.name;
        this.uploadImage(file);
      } else {
        this.form.icon_name = null;
        this.form.icon_url = "/assets/images/icons/sms-img.png";
      }
    },
    uploadImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.form.icon_url = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    addItemSource() {
      if (this.sourceSelect.id == null) {
        return;
      }
      if (
        this.form.source_detail.find(
          (item) => item.contact_method_id == this.sourceSelect.id
        )
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-center",
          props: {
            title: "Warning",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Source already registered.",
          },
        });
        this.sourceSelect = null;
        return;
      }
      this.form.source_detail.push({
        id: null,
        source_id: null,
        contact_method_id: this.sourceSelect.id,
        name: this.sourceSelect.name,
        is_from_flyer: this.is_from_flyer,
        status_name: "Active",
        status: true,
      });
      this.sourceSelect = null;
    },
    deleteItem(contact_method) {
      this.form.source_detail = this.form.source_detail.filter(
        (item) => item.contact_method_id !== contact_method.contact_method_id
      );
    },
    createContactMethod() {
      const item = {
        created_at: null,
        created_by: null,
        description: null,
        id: null,
        name: null,
        parent_id: null,
        slug: null,
        status: null,
        updated_at: null,
        updated_by: null,
      };
      this.$emit("createContactMethod", item);
    },
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.my {
  margin: 6px 0 0 6px;
}
.hidde-label {
  opacity: 0;
}
.contact-inactive {
  opacity: 0.5;
}

</style>
