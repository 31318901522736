<template>
  <div>
    <b-modal
      v-model="showModal"
      title-class="h3 text-white"
      header-bg-variant="primary"
      centered
      scrollable
      size="md"
      no-close-on-backdrop
      :title="`UPLOAD DESIGN MATERIALS`"
      modal-class="modal-primary"
      @hidden="closeModal"
    >
        <vue-dropzone
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        ></vue-dropzone>
    </b-modal>
  </div>
</template>
<script>
import CreativeRequestService from '@/views/commons/components/creative-requests/services/creative.request';
import CoolLightBox from "vue-cool-lightbox";
import { createPopper } from '@popperjs/core';
import { mapGetters } from 'vuex';
import { amgApi } from "@/service/axios";

import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: 'HandleCreativeRequestModal',
  components: {
    CoolLightBox,
    vueDropzone
  },
  props: {
    requestId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      fileToUpload: null,
      request: null,
      pieceData: {
        amazon_path_full_version: null,
        local_file_path: '',
        design_material_id: null,
        design_material_code: null,
        loading_status: 'not_uploaded',
        designer_id: null,
        creative_design_request_id: null,
        program_name: '',
      },
      requestPiecesList: [],
      showImage: null,
      requestData: null,
      dropzoneOptions: {
        url: "http://127.0.0.1:8001/api/v1/creative/creative-request/upload-design-files-for-creative-request",
        // url: "http://127.0.0.1:8001/api/v1/file-manager/save-file-by-module",
        maxFilesize: 10000000000,
        dictDefaultMessage: 'Upload Files Here!!!!',
        includeStyling: false,
        previewsContainer: false,
        thumbnailWidth: 250,
        thumbnailHeight: 140,
        uploadMultiple: true,
        parallelUploads: 20,
        headers: {        
            // 'Content-Type': 'multipart/form-data'
        },
        params: {
          "program_name": null,
          "creative_design_request_id": null,
          "design_material_id": null,
          "design_material_code": null,
          "user_id": null,
        },
      },
      
    }
  },
  computed: {
    ...mapGetters({
        currentUser: "auth/currentUser"
    })
  },
  async created() {
    await this.getRequestData();
    this.setUploadData();
    this.showModal = true;
  },
  methods: {
    closeModal(){
        this.$emit("closeModal")
    },
    async getRequestData(){
      const params = {
        requestId: this.requestId
      }
      const { data } = await CreativeRequestService.getRequestData(params);
      this.requestData = data[0];
    },
    setUploadData(){
      this.dropzoneOptions.params.program_name = this.requestData.program_name;
      this.dropzoneOptions.params.creative_design_request_id = this.requestId;
      this.dropzoneOptions.params.user_id = this.currentUser.user_id;
    }
  },
};
</script>