<template>
  <div>
    <b-modal
      v-model="control"
      title-class="h3 text-white"
      header-bg-variant="primary"
      centered
      size="xlg"
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      modal-class="modal-primary"
    >
    <template #modal-header>
      <div
        class="container-header-pdf px-1 d-flex justify-content-between align-items-center w-100 h3 text-white"
        style="margin-top: 0.5rem"
      >
        <div>{{ 'HANDLE DESIGN FILES (' + designRequestChosen.parent_document_name + ')' }}</div>
        <feather-icon
          icon="XIcon"
          class="icon-pdf cursor-pointer"
          style="transform: scale(2.0)"
          @click="closeModal"
        ></feather-icon>
      </div>
    </template>
      <div
        style="max-height: 700px; overflow: scroll"
        class="mx-2"
      >
        <div
          class="d-flex flex-row-reverse"
        >
          <div v-if="isTeamLeaderDesign">
            <b-nav-item-dropdown
              class="dropdown-notification mr-25"
              menu-class="dropdown-menu-media"
              right
              no-caret
              @show="seeRequestNotifications"
            >
              <template #button-content>
                <tabler-icon
                  v-b-tooltip.hover.top="'Designer Notifications'"
                  :badge="notificationCount"
                  badge-classes="badge-important"
                  class="text-body cursor-pointer"
                  icon="BellIcon"
                  size="23"
                />
              </template>
              <!-- Header -->
              <li class="dropdown-menu-header" style="border-bottom: 1px solid rgb(65, 60, 60);">
                <div
                  class="dropdown-header d-flex"
                  style="padding-bottom: 10px;
                    padding-top: 5px;"
                >
                  <h4
                    class="notification-title mb-0 mr-auto fw-bolder"
                    style="font-weight: 700;"
                  >
                    Designer Notifications
                  </h4>
                  <b-badge
                    pill
                    :variant="notificationCount > 0 ? 'light-primary' : 'light-danger'"
                    class="ml-1"
                  >{{ notificationCount > 0 ? notificationCount + ' New' : 'No news' }}</b-badge>
                </div>
              </li>
              <!-- Notifications -->
              <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="scrollable-container media-list scroll-area"
                style="max-height: 300px; position: relative; overflow: hidden; width: auto;"
                tagname="li"
              >
                <!-- Account Notification -->
                <template v-for="notification in notificationData">
                  <b-media
                    :key="notification.id"
                    style="cursor: pointer"
                    @click="updateNotification(notification)"
                  >
                    <template #aside>
                      <b-avatar
                        size="32"
                        :variant="'success'"
                        style="margin-bottom: 0px;
                          padding-bottom: 0px;
                          margin-top: 12px;"
                      >
                        <feather-icon icon="InfoIcon" />
                      </b-avatar>
                    </template>
                    <div>
                      <div class="media-heading" style="margin: 0px; padding: 0px;">
                        <span
                          style="margin: 0px;
                          padding: 0px;
                          font-weight: bold"
                        >
                          {{ notification.message }}
                        </span>
                      </div>
                      <div
                        style="margin: 0px; padding: 0px; font-size: 12px;"
                      >
                        <div>
                          {{ notification.sender }}
                        </div>
                        <div>{{ notification.sent_at | myGlobalWithHour }}</div>
                      </div>
                    </div>
                  </b-media>
                </template>
                <template v-if="notificationData.length === 0">
                  <b-media class="text-center text-primary my-2">
                    <div>No notifications found</div>
                  </b-media>
                </template>
              </vue-perfect-scrollbar>
            </b-nav-item-dropdown>
          </div>
        </div>
        <br>
        <div         
          v-for="(request, index) in dataRequestContents"
          :key="index"
          class="rounded-border"
        >
          <h4 class="font-weight-bolder mb-2">
            {{ request.title }}
          </h4>
          <b-button          
            variant="success"
            v-if="moduleId == 27 && $route.meta.status !== 'observed'"
            @click="saveSelectGlobal(index)"
            :disabled="request.has_all_status_approved_by_creative"
          >
           Approved by Creative
          </b-button>

          <grid-file-modal
            class="mt-1"
            :ref="'grid-'+index"
            :data-request="dataRequestContents"
            :data-list="request.dataTable"
            :index-table="index"
            @reloadTable="getDataByRequest(true)"     
          />
        </div>
      </div>
      <template #modal-footer>
        <!-- for creative -->
        <b-button
          v-if="moduleId === 27 && isFinishedTab && designRequestChosen.parent_status_id != 4 && designRequestChosen.type == 'request'"
          variant="success"
          :disabled="allStatusAsignedGlobal"
          @click="sendRequestResponse"
        >
          <feather-icon icon="SaveIcon" />
          SAVE REVISSION
        </b-button>
        <b-button          
        v-if="currentUser.role_id === 30"
          variant="primary"
          @click="openModalQuestion()"
        >
          NOTIFY SUPERVISOR
        </b-button>
        <!-- for Paragon -->
        <!-- v-if="moduleId === 12 && $route.meta.status != 'finished' && designMaterials.length > 0" -->
        <b-button
          v-if="moduleId === 12 && !isFinishedTab && !isApprovedTab && (isTeamLeaderDesign || isCeo) && designRequestChosen.type == 'request'"
          variant="primary"
          title="You won't be able to press this button until all pieces have at least one content uploaded and all the materials have the status Approved by Paragon"
          :disabled="allStatusAsignedGlobal || allPiecesWithContent || (!isTeamLeaderDesign && !isSupervisor && !isCeo)"
          @click="setAsFinished"
        >
          SET AS FINISHED
        </b-button>
      </template>
              <b-modal
          v-if="modalQuestion"
          v-model="modalQuestion"
          modal-class="modal-primary"
          title-class="h3 text-white"
          title="TIME LINE TICKET"
          size="modal-timeline"
          hide-footer
          centered
          hide-header-close
          hide-header
          @hidden="closeModalQuestion()"
        >
          <div>
            <div class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2">
            </div>
            <div class="d-flex align-items-center justify-content-center mt-1">
              <div class="icon-badge mr-1 pending">
                <feather-icon
                  icon="AlertCircleIcon"
                  size="30"
                  class="text-warning"
                ></feather-icon>
              </div>
            </div>

            <div class="text-center mt-1">
              <span class="text-warning" style="font-size: 15px">
              Would you like to add an additional comment?
              </span>
            </div>
            <div class="text-center mt-1">              
            <b-form-textarea
              placeholder="Enter something..."
              v-model="comment"
              style=" width: 100%; "
            ></b-form-textarea>
            </div>
            <br>
            <div class="d-flex justify-content-center">
              <b-button class="mr-2" variant="primary" @click="saveModalQuestion()" target="_blank">
                Save
              </b-button>
              <b-button variant="outline-danger" @click="closeModalQuestion()" target="_blank">
                Cancel
              </b-button>
            </div>
            <br>
          </div>
        </b-modal>
    </b-modal>
      <creative-desing-flyer-modal
      v-if="statusRequest"
      :program-id-item="programIdItem"
      :multipleData="multipleData"
      @close="closeCreativeDesingModal"
    />
  </div>
</template>
<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import GridFileModal from "@/views/commons/components/creative-requests/components/GridFile.vue";
import { mapGetters, mapMutations } from "vuex";
import CreativeRequestService from '@/views/commons/components/creative-requests/services/creative.request';
import CreativeDesingFlyerModal from "@/views/commons/components/creative-requests/components/creativeDesingFlyerModal.vue";
export default {
  components: {
    GridFileModal,
    CreativeDesingFlyerModal,
      BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
    directives: {
    Ripple,
  },
  props: {
    creativeFileModalDesignRequestId: {
      type: Number,
      required: true,
    },
    creativeFileModalTypeRow: {
      type: String,
      required: true,
    },
    creativeFileModalDesignRequestStatus: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
      designRequestChosen: "CreativeDesign/G_DESIGN_REQUEST_CHOSEN",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isFinishedTab() {
      return this.$route.meta.status == 'finished';
    },
    isApprovedTab(){
      return this.$route.meta.status == 'approved';
    },
    allStatusAsignedGlobal() {
      if (this.gridsAlreadyExists) {
        const allStatusAsignedR = [];
        this.tableRefsArray.forEach(tableRef => {
          const currentStatusAsigned = this.$refs[tableRef][0].allStatusAsigned();
          allStatusAsignedR.push(currentStatusAsigned);
        });
        const atLeastOneTrue = allStatusAsignedR.some(status => status);

        return atLeastOneTrue;
      }
      return false;
    },
    allPiecesWithContent() {
      if (this.gridsAlreadyExists) {
        const atLeastOneByPieceArray = [];
        this.tableRefsArray.forEach(tableRef => {
          const atLeastOneByPiece = this.$refs[tableRef][0].atLeastOneByPiece();
          atLeastOneByPieceArray.push(atLeastOneByPiece);
        });

        const oneIsNotCompleted = atLeastOneByPieceArray.some(piece => piece === true);

        return oneIsNotCompleted;
      }
      return false;
    },
  },
  async created() {
    try {
      this.addPreloader();
      this.control = true;
      const initFunctions = [this.getDataByRequest()];
      if (this.creativeFileModalTypeRow === 'request') {
        await this.updateStatusViewMessages();
        initFunctions.push(this.refreshCounterNotifications());
      }
      await Promise.all(
        initFunctions,
      );
    } catch (error) {
      this.removePreloader();
    } finally {
      this.removePreloader();
    }
    this.getPendingDesignerNotifications(this.creativeFileModalDesignRequestId);
  },
  async mounted() {
    try {
      this.addPreloader();
      await this.delay(2000);
      await this.accessGridFileModals();
    } catch (error) {
      this.removePreloader();
    } finally {
      this.removePreloader();
    }
  },
  data() {
    return {
      control: false,
      dataRequestContents: [],
      designMaterials: [],
      tableRefsArray: [],
      gridsAlreadyExists: false,
      isBusy: false,
      modalQuestion: false,
      comment: "",
      approveAllSection: false,
      updatedDataList: [],
      approveAllExecuted: false,
      multipleData: [],
      programIdItem: null,
      statusRequest:false,
      showDropdown: false,
      commentList: [],
      disableButton: false,
      notificationData: [],
      notificationCount: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  methods: {
    ...mapMutations({
      refreshCounterSidebar: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
      refreshTabsCounters: "CreativeDesignStore/SET_COUNTERS",
    }),
    openModalQuestion(){
      this.modalQuestion = true;
    },
    async saveSelectGlobal(index) {
      const gridFileModal = this.$refs['grid-' + index];
      const dataTable = gridFileModal[0].prueba;
      const detailIds = [];

      dataTable.forEach(obj => {
        obj.materials_array.forEach(material => {
          if (material.selected === 1) {
            const dataObject = {
              design_material_id: material.design_material_id,
              code: material.code,
              full_version: material.full_version,
              file_name: material.file_name,
              preview: material.preview,
              requestId: material.requestId,
              extension: material.extension,
            };
            detailIds.push(dataObject);
          }
        });
      });

      this.multipleData = detailIds;
      this.programIdItem = this.designRequestChosen.program_id;
      if (this.multipleData && this.multipleData.length > 0) {
          this.statusRequest = true;
      } else {
          this.showInfoSwal("Select some brochures to approve or the brochures are already approved by creative.");
      }
      await this.accessGridFileModals();
    },
    async closeCreativeDesingModal(){
      this.statusRequest = false;
      this.multipleData = [];
      this.programIdItem = null;
      await this.getDataByRequest(true);
      // this.disableButton = true;
    },

    closeModalQuestion(){
      this.modalQuestion = false;
      this.comment = "";
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async saveModalQuestion(){
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;

      this.addPreloader();
      const params = {
        comment: this.comment,
        request_id: this.designRequestChosen.design_request_id,
        user_id: this.currentUser.user_id,
        user_name: this.currentUser.fullName,
      };
      try {
        const { data } = await CreativeRequestService.NotificationCommentTeamLeaderDesignByDesigner(params);
        if (data.success) {
          this.showSuccessSwal();
          this.closeModalQuestion();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getPendingDesignerNotifications(param){
       const parameter = {
        design_request_id : param,
       }
        try {
        const { data } = await CreativeRequestService.getListByDesigner(parameter);
        if (data.success) {
          this.notificationData = data.data;
          this.notificationCount = this.notificationData[0]?.cc ?? 0;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    closeModal() {
      let allMaterialsArray = [];
      const notificationCounter = this.notificationCount;

      if (this.gridsAlreadyExists) {
        this.tableRefsArray.forEach(tableRef => {
          const materialsArray = this.$refs[tableRef][0].allMaterialsArrayFn();
          allMaterialsArray = [...allMaterialsArray, ...materialsArray];
        });
        const hasUnseenMessages = allMaterialsArray.some(design => design.has_unseen_messages == 1); // still has something to see
        const emitParams = {
          notificationCounter,
          ...this.designRequestChosen,
          hasUnseenMessages,
        };
        this.control = false;
        this.$emit('close', emitParams);
      } else {
        this.$emit('close');
      }
    },
    delay(ms) { // delay para esperar a que se cierre el v-select
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async getDataByRequest(preloaderRemove = false) {
      try {
        this.isBusy = true;
        this.addPreloader();
        const params = {
          requestId: this.creativeFileModalDesignRequestId,
          type: this.creativeFileModalTypeRow,
          moduleId:this.moduleId,
          userId: this.currentUser.user_id,
        };
        const { data, status } = await CreativeRequestService.getDataByRequest(params);
        if (status === 200) {
          this.dataRequestContents = data;
          this.dataRequestContents.forEach(request => {
            // Loop through each datatable object
            request.dataTable.forEach(datatableItem => {
              // Loop through each object in materials_array
              datatableItem.materials_array.forEach(materialItem => {
                if ((this.currentUser.modul_id == 12 && materialItem.status_id == 1 && this.$route.meta.status == 'in process') 
                || (this.currentUser.modul_id == 27 && materialItem.status_id == 4 && this.$route.meta.status == 'finished')) {
                  materialItem.status_id = null;
                }
              });
            });
          });
          this.tableRefsArray = [];
          this.dataRequestContents.forEach((request, index) => {
            this.tableRefsArray.push(`grid-${index}`);
          });
          this.isBusy = false;
          // this.removePreloader();

        }
      } catch (error) {
        // this.removePreloader();
        this.isBusy = false;
        console.log(error);
      } finally {
        if (preloaderRemove) {
          this.removePreloader();
        }
      }
    },
    accessGridFileModals() {
      this.$nextTick(() => {
        this.dataRequestContents.forEach((request, index) => {
          const gridFileModalRef = this.$refs[`grid-${index}`];

          if (gridFileModalRef) {
            const allStatusApprovedByCreative = gridFileModalRef[0].allStatusAreApprovedByCreative();
            this.$set(this.dataRequestContents[index], 'has_all_status_approved_by_creative', allStatusApprovedByCreative);
          }
        });
        this.gridsAlreadyExists = true;
      });
    },
    async setAsFinished() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      this.addPreloader();
      const params = {
        status: 3, // finished
        requestId: this.designRequestChosen.type == 'content' ? this.designRequestChosen.design_request_id : this.designRequestChosen.parent_request_id,
        userId: this.currentUser.user_id,
        moduleId: this.moduleId,
        "columnChanged": 'STATUS',
      };
      try {
        const { data } = await CreativeRequestService.changeRequestStatusDuedate(params);
        if (data.success) {
          this.showSuccessSwal();
          this.closeModal();
          this.$emit('reloadTable');
          // this.$emit('close');
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async sendRequestResponse() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (isConfirmed) {
        try {
          this.addPreloader();
          let allMaterialsArray = [];
          this.tableRefsArray.forEach(tableRef => {
            const materialsArray = this.$refs[tableRef][0].allMaterialsArrayFn();
            allMaterialsArray = [...allMaterialsArray, ...materialsArray];
          });
          const isObserved = allMaterialsArray.some(design => design.status_id != 7);
          const params = {
            requestId: this.designRequestChosen.parent_request_id,
            statusId: isObserved ? 5 : 4,
            userId: this.currentUser.user_id,
          };
          const { data } = await CreativeRequestService.saveRequestRevission(params);
          if (data.success) {
            this.$emit("reloadTable");
            this.closeModal();
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            );
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    async updateStatusViewMessages() {
      try {
        await CreativeRequestService.updateStatusViewMessages({
          request_id: this.creativeFileModalDesignRequestId,
          module_id: this.currentUser.modul_id,
          tab: this.creativeFileModalDesignRequestStatus,
        });
      } catch (err) {
        console.error('Error when update status view messages', err);
      }
    },
    async refreshCounterNotifications() {
      const params = {
        modul_id: this.moduleId,
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
      };
      
      const module = this.moduleId;
      if (module == 27) {
        const { data } =
          await CreativeRequestService.getdesignRequestCounterNotifications(
            params
          );
        const payload = {
          routeName: "creative-request-options",
          tag: data[0].total,
        };
        await this.refreshCounterSidebar(payload);
        await this.refreshTabsCounters(data[0]);
      } else {
        const { data } =
          await CreativeRequestService.getdesignRequestCounterNotifications(
            params
          );
        const payload = {
          routeName: "creative-request-options-ti",
          tag: data[0].total,
        };
        await this.refreshCounterSidebar(payload);
        await this.refreshTabsCounters(data[0]);
      }
    },
    async seeRequestNotifications() {
      const { status } = await CreativeRequestService.seeRequestNotifications({
        requestId: this.designRequestChosen.design_request_id,
        type: this.designRequestChosen.type,
        userId: this.currentUser.user_id,
      });
      this.refreshCounterNotifications();
      if (status === 200) {
        this.notificationCount = 0;
      }
    },
    getNotifications() {
      console.log('GET NOTIFICATIONS');
    },
  },
   setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  watch: {
    isBusy(val) {
      console.log('CREATIVE FILE MODAL ', val);
    },
  }
};
</script>
<style scoped>
.rounded-border {
            border: 2px solid #3498db; /* Replace with your primary color */
            border-radius: 10px; /* Adjust the border-radius for the desired level of roundness */
            padding: 20px; /* Adjust the padding as needed */
            margin: 10px; /* Optional: Add margin to create space around the div */
        }
        
</style>
<style>
.glow {
  /* Estilos normales */
}

.blink {
  animation: glowing 1.5s infinite;
}

@keyframes glowing {
  0% { color: red; }
  50% { color: white; }
  100% { color: red; }
}
</style>

