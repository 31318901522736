<template>
    <div>
        <b-modal v-model="oncontrol" size="xlg" title="Image" @hide="closeModal" hide-footer>
            
         <div class="mt-2 mb-2">
            <div class="carousel-container">
                <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="0"
                indicators
                background="#ababab"
                class="custom-carousel"
                    >
                <!-- Slide with blank fluid image to maintain slide aspect ratio -->
                <b-carousel-slide v-for="item in arrMaterialAnotation" :key="item.id" > 
                    <template #img>
                        <TrackingMarkerImage 
                            :sendMarkerImage="item"
                        />
                    </template>
                </b-carousel-slide>
                </b-carousel>
        
                <button v-if="!showCarouselControl"
                @click="prevSlide" class="carousel-control left" 
                :class="!isDarkSkin ? 'carousel-control-dark' : ''">&#8249;</button>
                <button v-if="!showCarouselControl"
                @click="nextSlide" class="carousel-control right"
                :class="!isDarkSkin ? 'carousel-control-dark' : ''">&#8250;</button>

            </div> 
      </div>

        </b-modal>
    </div>
</template>
<script>

import { mapGetters } from "vuex";
import TrackingMarkerImage from "@/views/commons/components/creative-requests/components/TrackingMarkerImage.vue"
import RequestService from '@/views/commons/components/creative-requests/services/creative.request.js';
export default {
    components:{
        TrackingMarkerImage
    },
    props: {
        sendIdMaterial:{
            type:Number,
            required:true
        },
        sendIdMaterialTracking:{
            type:Number,
            required:true
        }
    },
    data(){
        return {
            // showModal: false,
            idMaterial:null,
            idTracking:null,
            materialAnotation:{},
            anotationsImg:[],
            arrMaterialAnotation:[],
            osdViewer: null, 
            isFullScreen: false,
            hasHandledOpenEvent:false,
            typeFile:null,
            extensionFile:null,
            slide: 0,
            oncontrol:false,
            showCarouselControl:false,
        }
    },

    async mounted() {

        await this.getMaterialAnotationTracking();
        this.oncontrol = true;  
    },

    computed:{
            ...mapGetters({
            skin: "appConfig/skin",
            }),
        },

   async created() {
        this.idMaterial = this.sendIdMaterial;
        this.idTracking = this.sendIdMaterialTracking;


    },
    methods:{

        initializeOpendragonAnotorious(){
            const isAnotations = this.anotationsImg.length === 0 ;
            this.initializeOpenSeadragon();

            if (!isAnotations) {
                const annotationsJSON = JSON.stringify(this.anotationsImg);
                this.anno.loadAnnotations(`data:application/json,${encodeURIComponent(annotationsJSON)}`);
            } 
            
        },
        initializeOpenSeadragon() {
            var viewer = OpenSeadragon({
                id: "openseadragon1",
                prefixUrl: '/assets//images/openseadragon/images/',
                showZoomControl: false,   
                showHomeControl: false,   
                showFullPageControl: false,
                showRotationControl: false,  
                showSequenceControl: false,  
                showNavigator: true, 
                tileSources: {
                    type: "image",
                    url: this.materialAnotation.amazon_path_full_version
                    }
                });


                this.osdViewer = viewer;

                var options = {
                    disableEditor: false,
                    readOnly:true

                };

                // Asigna 'anno' a la instancia de Annotorious
                this.anno = Annotorious(viewer, options);

                this.anno.on('mouseEnterAnnotation', function(annotation, event) {
                    event.classList.remove("hover");
                });

                this.hasHandledOpenEvent = false;

                const openHandler = () => {
                    if (!this.hasHandledOpenEvent) {
                        this.hasHandledOpenEvent = true;
                    }
                };

                viewer.addHandler('open', openHandler);
        },

        closeModal() {
            this.$emit("closeModal");
        },
       async getMaterialAnotationTracking(){
        this.addPreloader();
          try {

            const params = {
                materialId: this.idMaterial
            };
            const { data } = await RequestService.getMaterialAnotationsTracking(params);
            this.arrMaterialAnotation = data;

            this.arrMaterialAnotation.forEach(obj =>{
                obj.typeFile = ( obj.extension === 'gif' || obj.extension === 'mp4') ? 'video' : 'image'
              })
              this.showCarouselControl  =  this.arrMaterialAnotation.length > 1 ? false : true ;
              const indice = this.arrMaterialAnotation.findIndex(item => item.id === this.idTracking);
              this.slide = indice;

          } catch (err) {
              console.error('Error in getMaterialAnotacionRequest', err);
          } finally {
              this.removePreloader();
          }

        },

        nextSlide() {
        this.slide = (this.slide + 1) % this.arrMaterialAnotation.length;
    
        },
        prevSlide() {
        this.slide = (this.slide - 1 + this.arrMaterialAnotation.length) % this.arrMaterialAnotation.length;
        }



    }
}
</script>


<style scoped>

.carousel-container {
position: relative;
display: flex;
justify-content: center;
/* align-items: center; */

}

.carousel-control {
position: absolute;
top: 50%;
transform: translateY(-50%);
font-size: 3.2rem;
background: none;
border: none;
color: #fff;
cursor: pointer;
outline: none;
}
.carousel-control-dark{
color:#000000;

}

.left {
left: 10px;
}

.right {
right: 10px;
}

.carousel-image {
width: 100%;
height: auto;
}

.carousel-item{
background:transparent !important;
}

.carousel{
background:transparent !important;
}

</style>
