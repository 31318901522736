<template>
  <div>
    <b-modal
      v-model="ownInsertControl"
      title-class="h3 text-white font-weight-bolder"
      size="sm"
      title="CREATE CAMPAIGN"
      @hidden="closeInsertModal"
      scrollable
    >
      <ValidationObserver ref="formNewCampain">
        <ValidationProvider
          v-slot="{ errors }"
          name="newcampaign"
          rules="required"
        >
          <b-form-group
            id="input-group-1"
            label="Campaign"
            label-for="input-1"
            class="my-1"
          >
            <b-form-input
              id="newcampaign"
              v-model="newCampaign"
              type="text"
              placeholder="Enter campaign"
              required
              :state="errors[0] ? false : null"
              :class="{ 'is-border-danger': errors[0] }"
            />
          </b-form-group>
        </ValidationProvider>
      </ValidationObserver>

      <template #modal-footer>
        <b-button
          :disabled="creatingCampaign"
          variant="primary"
          @click="createCampaign"
        >
          <span v-if="!creatingCampaign"> SAVE </span>
          <span v-else>
            <b-spinner small class="mr-2"></b-spinner>Loading...
          </span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="ownControl"
      title-class="h3 text-white font-weight-bolder"
      hide-footer
      size="xmd"
      title="FLYER"
      no-close-on-backdrop
      no-close-on-esc
      @hidden="closeModal"
    >
      <div class="ml-2">
        <b-container>
          <ValidationObserver ref="form">
            <b-row>
              <b-col  lg="12" style="margin-left: 55px;">
                <b-row class="justify-content-center">
                  <b-col lg="12" class="justify-content-center">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="state"
                      rules="required"
                    >
                      <b-form-group  class="justify-content-center">
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-input-group-text
                              class="bg-primary text-white"
                              style="padding-right: 7.15em"
                            >
                              <span>STATE </span>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <div class="w-75">
                            <v-select
                              id="state"
                              v-model="stateSelect"
                              :options="states"
                              :reduce="(val) => val.slug"
                              @input="reloadCampaign"
                              label="state"
                              :state="errors[0] ? false : null"
                              :class="{ 'is-border-danger': errors[0] }"
                            />
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="campaign"
                      rules="required"
                    >
                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-input-group-text
                              class="bg-primary text-white"
                              style="padding-right: 5em"
                            >
                              <span>CAMPAIGN</span>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <div class="w-75 d-flex">
                            <v-select
                              id="campaign"
                              class="w-100"
                              v-model="campaignId"
                              :options="campaigns"
                              :reduce="(option) => option.id"
                              label="value"
                              :value="campaign"
                              :state="errors[0] ? false : null"
                              :class="{ 'is-border-danger': errors[0] }"
                            />
                            <feather-icon
                              @click="stateSelect ? openInsertModal() : null"
                              icon="PlusCircleIcon"
                              title="CREATE"
                              size="20"
                              class="mx-1 my-auto"
                              :class="{
                                'cursor-pointer': stateSelect,
                                'text-primary': stateSelect,
                              }"
                              :disabled="!stateSelect"
                            >
                              <span
                                v-if="!stateSelect"
                                v-b-tooltip.hover.bottom="'please first select a state'"
                              ></span>
                            </feather-icon>

                            <feather-icon
                              @click="campaignId ? deleteCampaign() : null"
                              icon="TrashIcon"
                              title="DELETE"
                              size="20"
                              class="my-auto"
                              :class="{
                                'cursor-pointer': campaignId,
                                'text-primary': campaignId,
                              }"
                            />
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="status"
                      rules="required"
                    >
                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-input-group-text
                              class="bg-primary text-white"
                              style="padding-right: 6.35em"
                            >
                              <span>STATUS</span>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <div class="w-75">
                            <v-select
                              id="status"
                              v-model="optionsStatus"
                              :options="['PREACTIVE', 'ACTIVE', 'INACTIVE']"
                              label="active"
                              @click="convert(item)"
                              :state="errors[0] ? false : null"
                              :class="{ 'is-border-danger': errors[0] }"
                            />
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="date"
                      rules="required"
                    >
                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-input-group-text class="bg-primary text-white">
                              <span>PUBLICATION DATE</span>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <div class="w-75">
                            <b-form-datepicker
                              id="date"
                              v-model="publication_date"
                               :min="minDate"
                              locale="en"
                              :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                              }"
                              :state="errors[0] ? false : null"
                              :class="{ 'is-border-danger': errors[0] }"
                            />
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-container>
      </div>
      <div>
        <div class="d-flex justify-content-center mb-1">
          <b-button variant="primary" @click="setAction()">
            <span>SAVE</span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import BankOfFlyersService from "@/views/commons/components/bank-of-flyers/bank-of-flyers.service";
import CreativeRequestService from "@/views/commons/components/creative-requests/services/creative.request";

export default {
  components: {
    vSelect,
    DragAndDrop,
  },
  props: {
    item: {},
    operationType: null,
    programIdItem: null,
    designMaterials: "",
    fullVersionProp: "",
    previewProp: "",
    codeProp: null,
    extensionProp: null,
    multipleData: Array,
  },
  data() {
    return {
      ownControl: false,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      creatingCampaign: false,
      state_name: null,
      ownInsertControl: false,
      addCampaignOn: false,
      optionsStatus: "",
      newCampaign: "",
      showModalAddCampaign: false,
      campaigns: [],
      state: "",
      states: [],
      stateSelect: null,
      publication_date: null,
      programIdRequest: null,
      campaignId: 0,
      campaign: null,
      fullVersion: "",
      preview: "",
      code: null,
      extension: null,
    };
  },

  created() {
    if (this.programIdItem) {
      this.ownControl = true;
      this.fullVersion = this.fullVersionProp;
      this.preview = this.previewProp;
      this.code = this.codeProp;
      this.extension = this.extensionProp;
    }
    this.getStates();
    this.programIdRequest = this.programIdItem;
  },

  mounted() {},

  methods: {
    reloadCampaign() {
      this.getCampaigns();
    },
    setAction() {
      if (this.multipleData) {
        this.saveMultiple();
      } else {
        this.save();
      }
    },
    async getStates() {
      try {
        const { data } = await BankOfFlyersService.getStates();
        this.states = data;
      } catch (error) {
        console.log("Something went wrong on getCampaignFlyers", error);
      }
    },

    async save() {
      const validation = await this.$refs.form.validate();
      if (validation) {
        if (this.optionsStatus === "INACTIVE") {
          this.active = 0;
        } else if (this.optionsStatus === "ACTIVE") {
          this.active = 1;
        } else {
          this.active = 2;
        }
        const result = await this.showConfirmSwal();
        if(result.isConfirmed){
                  this.addPreloader();
        try {
            const params = {
            user_id: this.currentUser.user_id,
            stateSelect: this.stateSelect,
            campaignId: this.campaignId,
            optionsStatus: this.active,
            publicationDate: this.publication_date,
            pathFullVersion: this.fullVersion,
            routeThumb: this.preview,
            extension: this.extension,
            code: this.code,
          };
          const data = await CreativeRequestService.insertCreativeBankFlyers(
            params
          );
          if (data.status === 200) {
            this.showSuccessSwal();
            this.closeInsertModal();
            this.$emit("succesful")
            this.creatingCampaign = false;
          }
        } catch (e) {
          this.showErrorSwal(e);
          return [];
        } finally {
          this.removePreloader();
        }
        }
      }
    },
    async saveMultiple(){
     const validation = await this.$refs.form.validate();
      if (validation) {
        if (this.optionsStatus === "INACTIVE") {
          this.active = 0;
        } else if (this.optionsStatus === "ACTIVE") {
          this.active = 1;
        } else {
          this.active = 2;
        }
        const result = await this.showConfirmSwal();
        if(result.isConfirmed){
                  this.addPreloader();
        try {
            const params = {
            user_id: this.currentUser.user_id,
            stateSelect: 7,
            campaignId: this.campaignId,
            optionsStatus: this.active,
            publicationDate: this.publication_date,
            multipleData: this.multipleData,
          };
          const data = await CreativeRequestService.insertCreativeBankFlyers(
            params
          );
          if (data.status === 200) {
            this.showSuccessSwal();
            this.closeInsertModal();
            this.$emit("succesful")
            this.closeModal();
            this.creatingCampaign = false;
          }
        } catch (e) {
          this.showErrorSwal(e);
          return [];
        } finally {
          this.removePreloader();
        }
        }
      }
    },
    convert(item) {
      if (item.active === 0) {
        this.optionsStatus = "INACTIVE";
      } else if (item.active === 1) {
        this.optionsStatus = "ACTIVE";
      } else if (item.active === 2) {
        this.optionsStatus = "PREACTIVE";
      }
      return this.optionsStatus;
    },

    async createCampaign() {
      const validation = await this.$refs.formNewCampain.validate();
      if (validation) {
        if (this.programIdRequest == "" || this.stateSelect == "") {
          this.showErrorSwal("You must select a state and a program");
          return;
        }
        this.addPreloader();
        try {
          this.creatingCampaign = true;
          const params = {
            campaign: this.newCampaign,
            program: this.programIdRequest,
            state: this.stateSelect,
            user: this.currentUser.user_id,
          };
          const data = await BankOfFlyersService.insertCampaignFlyers(params);
          if (data.status === 200) {
            await this.getCampaigns();
            this.showSuccessSwal();
            this.closeInsertModal();
            this.creatingCampaign = false;
          }
        } catch (e) {
          this.showErrorSwal(e);
          return [];
        } finally {
          this.removePreloader();
        }
      }
    },

    async deleteCampaign() {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          const params = {
            id: this.campaignId,
          };
          const data = await BankOfFlyersService.deleteCampaignFlyers(params);
          if (data.status === 200) {
            this.campaignId = null;
            await this.getCampaigns();
            this.showSuccessSwal();
          }
        } catch (e) {
          this.showErrorSwal(e);
        }
      }
    },

    async getCampaigns() {
      try {
        const params = {
          program: this.programIdRequest,
          state: this.stateSelect,
        };
        const { data } = await BankOfFlyersService.getCampaignFlyers(params);
        this.campaigns = data;        
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    closeModal() {
      this.$emit("close");
    },

    openInsertModal() {
      this.ownInsertControl = true;
    },

    closeInsertModal() {
      this.ownInsertControl = false;
      this.newCampaign = "";
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
      minDate() {
      const currentDate = new Date();
      // Establecer la fecha mínima como la fecha actual
      return currentDate.toISOString().split('T')[0];
    },
  },
};
</script>
