<template>
  <div>
    <b-modal centered v-model="showModal" size="lg" title="DESIGN REQUEST TRACKING" @hide="closeModal" hide-footer>
      <div>
        <b-table
          slot="table"
          ref="trackingGrid"
          :fields="fields"
          show-empty
          sticky-header="70vh"
          :items="myProvider"
          table-class="text-nowrap"
          responsive="sm"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at | myGlobalWithHour }}
          </template>
          <template #cell(due_date)="data">
            {{ data.item.due_date | myGlobalWithHour }}
          </template>
          <template #cell(status)="data">
            <b-badge :variant="statusVariant(data.item.status)">
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(created_by)="data">
            {{ `${data.item.created_by_id === currentUserId ? 'You' : data.item.created_by}` }}
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import trackingFields from "@/views/commons/components/creative-requests/data/trackingRequest.fields.js";
import RequestService from "@/views/commons/components/creative-requests/services/creative.request.js";
import { mapGetters } from 'vuex';

export default {
  props: {
    requestId: {
      type: Number,
    },
  },
  data() {
    return {
      showModal: false,
      fields: trackingFields,
    };
  },
  computed: {
    ...mapGetters({
      skin: 'appConfig/skin',
      currentUser: 'auth/currentUser',
    }),
    currentUserId() {
      return this.currentUser.user_id;
    },
  },
  created() {
    this.showModal = true;
  },
  methods: {
    async myProvider() {
      try {
        this.addPreloader();
        const params = {
          requestId: this.requestId,
        };
        const { data } = await RequestService.getRequestTracking(params);
        return data || [];
      } catch (err) {
        console.error("Error getting request tracking", err);
      } finally {
        this.removePreloader();
      }
    },
    statusVariant(status) {
      switch (status) {
        case 'Pending':
          return 'warning';
        case 'In process':
          return 'primary';
        case 'Finished':
          return "secondary";
        case 'Approved':
          return "info";
        case 'Observed':
          return 'success';
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>