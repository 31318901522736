<template>
  <div>
    <b-modal
      ref="material-chat-modal"
      :title="modalTitle"
      size="xmd"
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      hide-footer
      @hidden="onCloseModal"
    >
      <div>
        <b-row>
          <!-- Detail -->
          <b-col
            lg="6"
            xl="5"
            class="border-right"
          >
            <div class="scroll-kanban max-height-content">
              <b-row
                class="py-1 px-1 mx-1 my-1"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="col-md-4">Request created By</span>
                <div class="col-md-8">
                  <strong> {{ requestCreatedByName }} </strong>
                </div>
              </b-row>
              <b-row
                class="py-1 px-1 mx-1 my-1"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="col-md-4">Uploaded By</span>
                <div class="col-md-8">
                  <strong> {{ nameDesigner }}</strong>
                </div>
              </b-row>

              <b-row
                class="py-1 px-1 mx-1 my-1"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="col-md-4">File</span>
                <div class="col-md-8 text-truncate">
                  <!-- @click="openFile(file)" -->
                  <b-badge
                    variant="light-primary"
                    style="margin: 0.5rem 0rem"
                  >
                    <a
                      :href="urlFile"
                      target="_blank"
                    >
                      <feather-icon
                        icon="FileIcon"
                        class="bg-orange-i"
                        style="font-size: 1.5rem"
                      />
                      <span class="ml-1 name-file">{{
                        formatLongFilename(fileName, 25)
                      }}</span>
                    </a>
                  </b-badge>
                </div>
              </b-row>

              <b-row
                class="py-1 px-1 mx-1 my-1"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="col-md-4">Status</span>
                <div class="col-md-8">
                  <b-badge
                    :variant="statusVariant(statusId)"
                    class="text-capitalize"
                  >
                    {{ statusName }}
                  </b-badge>
                </div>
              </b-row>

            </div>
          </b-col>
          <!-- Chat -->
          <b-col
            ref="chat"
            lg="6"
            xl="7"
          >
            <div class="py-1 px-2 scroll-kanban max-height-content">
              <div
                ref="scrollList"
                class="my-1 rounded card-comments"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
                :style="
                  comments > 0
                    ? 'max-height: 70vh;min-height: 70vh;'
                    : 'max-height: 50vh;min-height: 50vh;'
                "
              >
                <template v-if="comments.length > 0">
                  <template v-for="(comment, index) in comments">
                    <div
                      :key="index"
                      :class="{
                        'cursor-pointer': true,
                        me: comment.user_id == currentUser.user_id,
                        other: comment.user_id != currentUser.user_id,
                        center: comment.user_id == 0,
                      }"
                      class="p-1 mb-1 content-comment rounded"
                      :style="backgroundComment('comment.sender')"
                    >
                      <span
                        :style="
                          comment.user_id == currentUser.user_id
                            ? 'color: #BB1EF5'
                            : 'color: #2BC8CA'
                        "
                        class="font-weight-bold"
                      >
                        {{ comment.user_name }}
                      </span>
                      <br>

                      <!-- Text -->
                      <span> <change-sms :sms-data="comment.message" /></span>

                      <div
                        class="ml-4 text-right"
                        style="color: #6e6b7b"
                      >
                        {{ comment.created_at | myGlobalDay }}
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="text-center">
                    <span class="no-message">No messages yet</span>
                  </div>
                </template>
              </div>

              <!-- Buttons  -->
              <b-row>
                <b-col
                  cols="11"
                  class="pr-0"
                >
                  <!-- Message input -->
                  <b-form-textarea
                    id="textarea"
                    v-model="send.message"
                    style="
                        height: 3em !important;
                        line-height: 1.5 !important;
                        padding: 0.5rem !important;
                      "
                    placeholder="Enter something..."
                    rows="1"
                    max-rows="1"
                    no-resize
                    @keydown.enter.prevent="sendMessage"
                  />
                </b-col>
                <b-col
                  cols="1"
                  class="text-center d-flex align-items-center"
                >
                  <!-- Send button -->
                  <div class="cursor-pointer">
                    <feather-icon
                      icon="SendIcon"
                      class="bg-primary-i"
                      :class="{ 'text-success': send.message.length }"
                      title="Send"
                      size="25"
                      @click="sendMessage"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ChangeSms from '@/views/commons/components/clients/components/clientsSms/ChangeSms.vue';
import CreativeDesignService from '@/views/creative/views/creative-requests/service/CreativeDesign.service';
import CreativeRequestService from '@/views/commons/components/creative-requests/services/creative.request';

export default {
  name: 'ModalAttendClaim',
  components: {
    ChangeSms,
  },
  props: {
    materialData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nameDesigner: '',
      fileName: '',
      requestCreatedByName: '',
      statusName: '',
      statusId: 4,
      urlFile: null,

      comments: [],
      send: {
        files: [],
        message: '',
      },
      modalTitle: '',
    };
  },
  async mounted() {
    this.modalTitle = `BINNACLE FOR MATERIAL: ${this.materialData.code}`;
    this.toggleModal('material-chat-modal');
  },
  async created() {
    this.activeChatSocket();
    await this.getDataChatByMaterialId();


  },
  async beforeDestroy() {
    try {
      window.socket.unbind('message-chat-material-notifications');
      await this.openMaterialChat();
    } catch (error) {
      console.log('message-chat-material-notifications', error);
    }
  },
  methods: {
    async getDataChatByMaterialId() {
      try {
        this.addPreloader();
        const params = {
          materialId: this.materialData.design_material_id,
        };
        let { data } = await CreativeDesignService.getDataChatByMaterialId(params);

        data = data.data;

        this.nameDesigner = data[0].name_designer;
        this.requestCreatedByName = data[0].name_created_by;
        this.fileName = data[0].code;
        this.statusName = data[0].status_name;
        this.statusId = data[0].status_id;
        this.urlFile = data[0].full_version;

        this.comments = JSON.parse(data[0].messages) || [];
        this.scrollCommentsBottom();
      } catch (error) {
        console.log(error);
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    async sendMessage() {
      if (event.shiftKey && event.keyCode === 13) return; // Shift + Enter pressed, do nothing or perform a different action

      if (!this.send.message && this.send.files.length === 0) {
        return this.showToast(
          'warning',
          'top-right',
          'Warning',
          'AlertTriangleIcon',
          'Please enter a message',
        );
      }

      try {
        this.addPreloader();
        const form = new FormData();
        form.append('designMaterialId', this.materialData.design_material_id);
        form.append('userId', this.currentUser.user_id);
        form.append('message', this.send.message);
        form.append('moduleId', this.moduleId);
        form.append('parentRequestId', this.designRequestChosen.parent_request_id);

        const { data } = await CreativeDesignService.sendMessageChatMaterial(form);

        if (data.type === 'success') {
          this.send.message = '';
          this.comments = JSON.parse(data.chats);
          this.send.files = [];
          this.scrollCommentsBottom();
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    formatLongFilename(filename, maxLength) {
      let formattedTitle = filename.substring(0, maxLength);

      if (filename.length > maxLength) {
        formattedTitle += '..........';
      }

      const regex = /\((.*?)\)$/;
      const match = regex.exec(filename);

      if (match && match.length >= 2) {
        const content = match[1];
        formattedTitle += ` (${content})`;
      }

      return formattedTitle;
    },

    async activeChatSocket() {
      try {

        console.log("Obteniendo el active chat socket");

        window.socket.unsubscribe('chat-material-notifications');
        window.socket.subscribe('chat-material-notifications');
        window.socket.bind(
          'message-chat-material-notifications',
          async response => {
            const { message } = response;
            if (
              this.materialData.design_material_id === message.design_material_id
            ) {
              const exist = this.comments.find(comm => comm.id == message.id);
              if (!exist) this.comments.push(message);
              this.scrollCommentsBottom();
            }
          },
        );
      } catch (error) {
        console.log('new-message-in-claim-chat', error);
      }
    },

    getIcon(id) {
      if (id === 1) {
        return 'CircleCheckIcon';
      }
      if (id === 2) {
        return 'CircleMinusIcon';
      }
    },

    getColor(id) {
      if (id === 1) {
        return 'text-success';
      }
      if (id === 2) {
        return 'text-danger';
      }
    },

    async onCloseModal() {
      this.$emit('close');
      await this.openMaterialChat()
      // this.updateCounter();
    },

    backgroundComment(type) {
      const boxShadow = '0 1px 3px rgb(0 0 0 / 20%)';
      let style = '';
      style = `background: ${
        this.isDarkSkin ? 'rgb(32 32 36)' : 'rgb(255 255 255)'
      }!important;`;
      return `${style} box-shadow: ${
        !this.isDarkSkin ? boxShadow : ''
      }!important;`;
    },

    scrollCommentsBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight;
        }, 1000);
      });
    },
    statusVariant(statusId) {
      switch (statusId) {
        case 1:
          return 'warning';
        case 2:
          return 'primary';
        case 3:
          return 'secondary';
        case 4:
          return 'info';
        case 5:
          return 'success';
        case 6:
          return 'danger';
        case 7:
          return 'dark';
        default:
          return 'warning';
      }
    },
    async openMaterialChat(item) {
      const params = {
        moduleId: this.moduleId,
        designMaterialId: this.materialData.design_material_id,
      };
      await CreativeRequestService.seeMessagesChatMaterial(params);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      designRequestChosen: "CreativeDesign/G_DESIGN_REQUEST_CHOSEN",
    }),
    moduleId(){
            return this.$route.matched[0].meta.module
        },
  },
};
</script>
  <style scoped>
  .floating-right-badge {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
  }

  .card-comments {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    font-size: 1rem;
    overflow-y: auto;
    box-shadow: 0 0 1.2px 1px rgb(0 0 0 / 0.1);
  }

  .content-comment {
    max-width: 80%;
    border-radius: 20px;
    padding: 5px 10px;
  }

  .me {
    margin-left: auto;
  }

  .other {
    margin-right: auto;
  }

  .center {
    margin: 0 auto;
  }

  .bg-rgba {
    background: rgb(0 0 0 / 18%) !important;
  }

  .bg-rgba-light {
    background: rgb(0 0 0 / 4%) !important;
  }

  .bg-orange-i {
    color: #ff6045;
    font-size: 1.5rem;
  }

  .style-chooser.vs__dropdown-menu {
    background: #dfe5fb;
    border: none;
    color: #394066;
    text-transform: lowercase;
    font-variant: small-caps;
  }

  .text-truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .mr-1dot3 {
    margin-right: 1.3rem !important;
  }

  .no-message {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 800;
    font-weight: bolder;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .name-file{
    color: #0090e7 !important;
  }
  </style>
