<template>
  <div>
    <b-modal
      v-model="showModal"
      title-class="h3 text-white"
      header-bg-variant="primary"
      centered
      scrollable
      size="md"
      no-close-on-backdrop
      :title="`UPLOAD DESIGN MATERIALS`"
      modal-class="modal-primary"
      @hidden="closeModal"
      :hide-footer="!showUploader || files.length == 0"
    >        
        <drag-and-drop
        v-if="showUploader"
        v-model="files"
        ref="dragAndDrop"
        :filesAcepted="'image/png, image/jpeg, image/gif, video/*'"
        :files-array="files"
        ></drag-and-drop>

        <!-- <drop-zone
        :requestId="requestId"
        ></drop-zone> -->
        <div v-else>
            <b-table striped hover :items="fileList" :fields="fields">
                <template #cell(namedoc)="data">
                    <div :class="data.item.showUploadSpinner ? 'text-primary' : 'text-success'">
                        {{ data.item.namedoc }}
                    </div>
                </template>
                <template #cell(status)="data">
                    <div v-if="data.item.showUploadSpinner">
                        <b-spinner
                        small
                        class="text-info"
                        />
                        Uploading...
                    </div>
                    <div v-else class="text-success">
                        <feather-icon
                        icon="CheckIcon"
                        />
                        Upload completed
                    </div>
                </template>

            </b-table>
        </div>
        <template #modal-footer>
          <b-row class="d-flex justify-content-end">
            <b-button              
              variant="outline-success"
              @click="savee()"
            >
              SAVE
              <feather-icon icon="SaveIcon" />
            </b-button>
          </b-row>
        </template>
    </b-modal>
  </div>
</template>
<script>
import CreativeRequestService from '@/views/commons/components/creative-requests/services/creative.request';
import CoolLightBox from "vue-cool-lightbox";
import { createPopper } from '@popperjs/core';
import { mapGetters } from 'vuex';
import { amgApi } from "@/service/axios";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue"
import DropZone from "@/views/commons/components/creative-requests/modals/DropZone.vue"
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'HandleCreativeRequestModal',
  components: {
    CoolLightBox,
    DragAndDrop,
    DropZone
  },
  props: {
    requestId: {
      type: Number,
      required: true,
    },
    contentTypeId: {
      type: Number,
      required: true,
    },
    detailId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      files: [],
      fileToUpload: null,
      request: null,
      pieceData: {
        amazon_path_full_version: null,
        local_file_path: '',
        design_material_id: null,
        design_material_code: null,
        loading_status: 'not_uploaded',
        designer_id: null,
        creative_design_request_id: null,
        program_name: '',
      },
      requestPiecesList: [],
      showImage: null,
      requestData: null,
      fileToUpload: [],
      form: {
        image: "",
        namedoc: "",
        account: "",
        size: "",
        user_id: "",
        type: "",
        datexp: "",
        folder_name: "",
        folder_id: "",
      },
      showUploader: true,
      fileList: [],
      fields: [
        {
            key: "namedoc",
            label: "File name",
            class: "text-center"
        },
        {
            key: "status",
            label: "Status upload",
            class: "text-center"
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
        currentUser: "auth/currentUser"
    })
  },
  async created() {
    await this.getRequestData();
    this.showModal = true;
  },
  methods: {
    async onUploadFile(form) {
      try {
          this.form.showUploadSpinner = form.showUploadSpinner
          this.form.file = form.image;
          this.form.namedoc = form.namedoc;
          this.form.size = form.size;
          this.form.program_name = this.requestData.program_name;
          this.form.creative_design_request_id = this.requestId;
          this.form.design_material_id = null;
          this.form.design_material_code = null;
          this.form.user_id = this.currentUser.user_id;
          this.form.content_type_id = this.contentTypeId;
          this.form.detail_id = this.detailId;
          const response = await amgApi.post(
            'creative/creative-request/upload-design-files-for-creative-request', this.form
          )
          if (response.status === 200) {
            this.fileList[form.index].showUploadSpinner = false;
            let refresh = this.fileList.some(file => file.showUploadSpinner)
            if(!refresh){
              this.$emit("refreshTable")
              this.$emit("closeModal")
              setTimeout(() => {
                this.showModal = false;
              }, 1000);
            }
          }

      } catch (error) {
        this.showErrorSwal(error)
      }
    },
    async savee() {
      this.showUploader = false;
      this.files.forEach(async (file, index) => {
        const modifiedFile = { image: file.fileapproved, namedoc: file.name, size: file.size, index: index, showUploadSpinner: true };        
        this.fileList.push(modifiedFile);
        if (file.type.includes('video')) {
          await this.uploadVideoByChunks(file, index);
        }
        else {
          await this.onUploadFile(modifiedFile);
        }
      });

    },
    closeModal() {
        this.$emit("closeModal")
    },
    async getRequestData() {
      const params = {
        requestId: this.requestId
      }
      const { data } = await CreativeRequestService.getRequestData(params);
      this.requestData = data[0];
    },
    async uploadVideoByChunks(fileVideo, formIndex) {
      try {
        let fileChunks = [];
        let file = fileVideo;
        let size = fileVideo.size;
        let chunkSize = 10000000;
        let offset = 0;
        while (offset < size) {
            let chunk = file.slice(offset, offset + chunkSize);
            fileChunks.push(chunk);
            offset+=chunkSize;
        }

        let fileName = file.name;
        let fileParts = fileName.split(".");
        let fileExtension = fileParts[fileParts.length-1];

        fileName = `${uuidv4()}.${fileExtension}`;

        let url = null;
        let statusResponse = 200;
        for (let i = 0; i < fileChunks.length; i += 1) {
          let isStart = i === 0;
          let isEnd = i === fileChunks.length - 1;
          let formData = new FormData();
          formData.append('file', fileChunks[i]);
          formData.append('extension', fileExtension);
          formData.append('name', fileName);
          formData.append('end', JSON.stringify(isEnd));
          formData.append('start', JSON.stringify(isStart));
          formData.append('creative_design_request_id', this.requestId);
          formData.append('design_material_code', null);
          formData.append('design_material_id', null);
          formData.append('user_id', this.currentUser.user_id);
          formData.append('content_type_id', this.contentTypeId);
          formData.append('detail_id', this.detailId);
          formData.append('program_name', this.requestData.program_name);
          console.log(this.detailId);
          let data = await CreativeRequestService.uploadVideoByChunks(formData);
          url = data.url ?? null;
        }
        this.fileList[formIndex].showUploadSpinner = false;
        let refresh = this.fileList.some(file => file.showUploadSpinner)
        if(!refresh){
          this.$emit("refreshTable")
          this.$emit("closeModal")
          setTimeout(() => {
            this.showModal = false;
          }, 1000);
        }
        return { url, fileName, statusResponse };
      } catch (error) {
      throw error;
      }
    },
  },
};
</script>