var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.visibleFilters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"onChangeCurrentPage":_vm.myProvider,"reload":_vm.myProvider},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table-simple',{staticClass:"table_request",attrs:{"hover":"","small":"","responsive":"","sticky-header":"60vh","table-class":"text-nowrap","busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.activeFields),function(value,index){return _c('b-th',{key:'contn' + index,staticClass:"text-center"},[_c('div',{staticClass:"text-center",class:value.tdClass},[_vm._v(" "+_vm._s(value.label)+" ")])])}),1)],1),_c('b-tbody',[(_vm.isBusy)?_c('b-tr',[_c('b-td',{staticClass:"text-center py-2",attrs:{"colspan":"11"}},[_c('div',{staticClass:"text-center text-primary"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)])],1):_vm._e(),(_vm.requestsList.length < 1 && _vm.isBusy === false)?_c('b-tr',[_c('b-td',{staticClass:"text-center py-2",attrs:{"colspan":"11"}},[_vm._v(" There are no records to show ")])],1):_vm._e(),_vm._l((_vm.requestsList),function(req){return (!_vm.isBusy)?[_c('b-tr',{key:req.design_request_id},[_c('b-td',[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"padding-top":"12px","font-size":"14px","font-weight":"bold"}},[_c('div',{staticClass:"d-flex"},[(req.has_contents)?_c('feather-icon',{staticClass:"cursor-pointer",class:req.show_contents ? 'text-primary' : 'text-success',staticStyle:{"margin-top":"auto","margin-bottom":"auto","transform":"scale(1.2)"},attrs:{"size":"20","icon":req.show_contents
                          ? 'ChevronDownIcon'
                          : 'ChevronRightIcon'},on:{"click":function($event){req.show_contents = !req.show_contents}}}):_vm._e(),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(req.program)+" ")])],1),(req.is_draft === 1)?_c('div',[_c('tabler-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Draft'),expression:"'Draft'",modifiers:{"hover":true,"top":true}}],staticClass:"text-info",attrs:{"size":"20","icon":"ClipboardListIcon"}})],1):_vm._e()])]),_c('b-td',[_c('div',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(req.document_name)+" ")])]),_c('b-td',[_c('div',{staticClass:"text-center",staticStyle:{"padding-top":"12px"}},[_c('b-badge',{staticStyle:{"padding":"5px 10px 5px 10px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.creativeFileModal(req, 'request')}}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(req.pieces)+" ")])])],1)]),(_vm.isParagonModule)?_c('b-td'):_vm._e(),_c('b-td',[_c('div',{staticClass:"text-center",staticStyle:{"padding-top":"5px"},attrs:{"id":"flat_picker_due_date_for_task"}},[_c('flat-pickr',{ref:("ref_flat_picker_due_date_for_task_" + (req.design_request_id)),refInFor:true,staticClass:"form-control cursor-pointer text-center",staticStyle:{"width":"15px","border":"none","outline":"none"},attrs:{"placeholder":"Deadline to be defined","disabled":_vm.isAnyRoleAllowed,"config":{
                      allowInvalidPreload: true,
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:S',
                      altFormat: 'm/d/Y h:i K',
                      altInput: true,
                      time_24hr: true,
                    }},on:{"on-close":function($event){return _vm.onStatusChangeOrchangeStageDueDate(req, 'due_date')}},model:{value:(req.due_date),callback:function ($$v) {_vm.$set(req, "due_date", $$v)},expression:"req.due_date"}})],1)]),_c('b-td',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center",class:!req.edit_status ? '' : 'd-none',staticStyle:{"padding-top":"2px","width":"180px"}},[_c('label',{staticClass:"cursor-pointer",staticStyle:{"padding-left":"15px"},attrs:{"for":'req-' + req.design_request_id + 'creative'},on:{"click":function($event){return _vm.setStatusEdition(req)}}},[_c('b-badge',{staticClass:"cursor-pointer unselectable-text text-center",class:_vm.isTeamLeaderDesign && _vm.isPendingTab ? 'my-animated-button' : '',staticStyle:{"padding":"7px 11px 7px 11px"},attrs:{"variant":_vm.statusVariant(req.status_id)}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(req.status)+" ")])])],1)]),(req.edit_status)?_c('v-select',{staticClass:"text-center cursor-pointer select-size-sm",staticStyle:{"width":"150px"},attrs:{"input-id":'req-' + req.design_request_id + 'creative',"options":_vm.filteredStatuses,"reduce":function (option) { return option.status_id; },"label":"status","disabled":false,"clearable":false,"append-to-body":true},on:{"input":function($event){return _vm.onStatusChangeOrchangeStageDueDate(req, 'status')},"close":function($event){return _vm.closeSelectStatus(req)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                    var status_id = ref.status_id;
return [_c('div',{staticClass:"text-center"},[_c('b-badge',{attrs:{"variant":_vm.statusVariant(status_id)}},[_vm._v(" "+_vm._s(_vm.statusName(status_id))+" ")])],1)]}}],null,true),model:{value:(req.status_id),callback:function ($$v) {_vm.$set(req, "status_id", $$v)},expression:"req.status_id"}}):_vm._e()],1)]),_c('b-td',[_c('div',{staticClass:"text-center",staticStyle:{"padding-top":"4px"}},[_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(("" + (req.created_by_user_id === _vm.currentUserId ? "You" : req.created_by)))+" ")]),_c('br'),_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(req.created_at))+" ")])])]),_c('b-td',[_c('div',{staticClass:"text-center",staticStyle:{"padding-top":"0px"}},[_c('div',{staticClass:"d-flex justify-content-center align-content-center"},[_c('div',{staticStyle:{"padding-top":"0px"}},[(
                          (_vm.isPendingTab && _vm.currentUser.modul_id == 27 && req.is_draft == 1)
                        )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-success",modifiers:{"hover":true,"v-success":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1",attrs:{"variant":"success","size":"sm","title":"Send to Paragon"},on:{"click":function($event){return _vm.sendToParagon(req.design_request_id)}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"CheckCircleIcon","size":"16"}})],1):_vm._e(),(
                          (_vm.isPendingTab && _vm.currentUser.modul_id == 27)
                        )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-warning",modifiers:{"hover":true,"v-warning":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1",attrs:{"variant":"warning","size":"sm","title":"Update Request"},on:{"click":function($event){return _vm.openModalNewRequest(req.design_request_id)}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"EditIcon","size":"16"}})],1):_vm._e(),(
                          ((_vm.isProcessTab ||
                            _vm.isFinishedTab ||
                            _vm.isObservedTab ||
                            _vm.isApprovedTab) &&
                            _vm.isParagonModule ||
                            _vm.isCreativeModule) ||                            
                            (_vm.isPendingTab && _vm.isParagonModule)
                        )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1",staticStyle:{"position":"relative"},attrs:{"variant":"primary","size":"sm","title":"View Request"},on:{"click":function($event){return _vm.openModalShowView(req)}}},[(req.has_pending_tasks && (_vm.isPendingTab || _vm.isParagonDesigner))?_c('div',{staticStyle:{"width":"12px","height":"12px","border-radius":"50%","z-index":"30","background-color":"red","position":"absolute","right":"-7px","top":"-5px"}}):_vm._e(),_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"16"}})],1):_vm._e(),(((_vm.isProcessTab && !_vm.isCreativeModule) || _vm.isFinishedTab || _vm.isObservedTab || _vm.isApprovedTab) && !_vm.isParagonDesigner)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1",staticStyle:{"position":"relative"},attrs:{"variant":"primary","size":"sm","title":"New Handle Design Files"},on:{"click":function($event){return _vm.creativeFileModal(req, 'request')}}},[(req.has_pending_tasks || req.has_pending_notifications > 0)?_c('div',{staticStyle:{"width":"12px","height":"12px","border-radius":"50%","z-index":"30","background-color":"red","position":"absolute","right":"-7px","top":"-5px"}}):_vm._e(),_c('feather-icon',{attrs:{"size":"16","icon":"FileIcon"}})],1):_vm._e(),(
                          (_vm.isPendingTab && _vm.isCreativeModule)
                        )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1",staticStyle:{"position":"relative"},attrs:{"variant":"danger","size":"sm","title":"Delete Request"},on:{"click":function($event){return _vm.deleteRequest(req)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"16"}})],1):_vm._e(),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-info",modifiers:{"hover":true,"v-info":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1",attrs:{"variant":"info","size":"sm","title":"Request Tracking"},on:{"click":function($event){return _vm.trackingModal(req.design_request_id)}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"16","icon":"ListIcon"}})],1)],1)])])])],1),_vm._l((req.contents),function(content,contentIndex){return (req.show_contents)?[_c('b-tr',{key:content.design_request_id,class:_vm.borderOnContentWithoutTasks(contentIndex, req.contents.length, content.has_tasks)},[_c('b-td'),_c('b-td',[_c('div',{staticStyle:{"padding-top":"12px"}},[(!_vm.isCreativeModule && content.has_tasks)?_c('feather-icon',{staticClass:"cursor-pointer",class:content.show_tasks ? 'text-primary' : 'text-success',staticStyle:{"margin-top":"auto","margin-bottom":"auto","transform":"scale(1.2)"},attrs:{"size":"20","icon":content.show_tasks
                            ? 'ChevronDownIcon'
                            : 'ChevronRightIcon'},on:{"click":function($event){content.show_tasks = !content.show_tasks}}}):_vm._e(),_c('div',{staticStyle:{"padding-top":"12px","display":"inline-block"},style:(content.has_tasks ? 'margin-left: 20px;' : 'margin-left: 43px;')},[_vm._v(" "+_vm._s(content.document_name)+" ")])],1)]),_c('b-td',[_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"padding":"5px 10px 5px 10px"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.creativeFileModal(content, 'content')}}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(content.pieces)+" ")])])],1)]),(_vm.isParagonModule)?_c('b-td',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center",class:!content.edit_designer ? '' : 'd-none',staticStyle:{"padding-top":"5px","width":"180px"}},[_c('label',{staticClass:"cursor-pointer",class:content.designer_name ? '' : 'text-danger',staticStyle:{"padding-left":"15px"},attrs:{"for":'set-' + content.design_request_id + 'designer'},on:{"click":function($event){return _vm.setDesignerEdition(content)}}},[_vm._v(" "+_vm._s(_vm.requestDesignerName(content.designer_name, content.has_tasks))+" ")])]),(content.edit_designer)?_c('v-select',{staticStyle:{"width":"200px"},attrs:{"input-id":'set-' + content.design_request_id + 'designer',"reduce":function (user) { return user.user_id; },"options":_vm.optionsAssignee,"clearable":false,"append-to-body":true,"placeholder":"None","label":"user_name"},on:{"input":function($event){return _vm.changeUserAssigned(content)},"close":function($event){return _vm.closeSelectDesigner(content)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                            var user_name = ref.user_name;
return [_c('div',{staticClass:"custom-option text-center"},[_vm._v(" "+_vm._s(user_name)+" ")])]}},{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Please select an option")])]},proxy:true},{key:"selected-option",fn:function(ref){
                            var user_name = ref.user_name;
return [_c('div',{staticClass:"text-center"},[_c('div',{staticStyle:{"height":"26.88px !important"}},[_vm._v(" "+_vm._s(user_name)+" ")])])]}}],null,true),model:{value:(content.designer_id),callback:function ($$v) {_vm.$set(content, "designer_id", $$v)},expression:"content.designer_id"}}):_vm._e()],1)]):_vm._e(),_c('b-td',[_c('div',{staticClass:"text-center",attrs:{"id":"flat_picker_due_date_for_content"}},[(!_vm.isCreativeModule)?_c('flat-pickr',{ref:("ref_flat_picker_due_date_for_content_" + (content.design_request_id)),refInFor:true,staticClass:"form-control cursor-pointer text-center",staticStyle:{"width":"15px","border":"none","outline":"none"},attrs:{"placeholder":"Deadline to be defined","disabled":_vm.isAnyRoleAllowed,"config":{
                        allowInvalidPreload: true,
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:S',
                        altFormat: 'm/d/Y h:i K',
                        altInput: true,
                        time_24hr: true,
                      }},on:{"on-close":function($event){return _vm.onStatusChangeOrchangeStageDueDate(content, 'due_date')}},model:{value:(content.due_date),callback:function ($$v) {_vm.$set(content, "due_date", $$v)},expression:"content.due_date"}}):_vm._e()],1)]),_c('b-td',[_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.isCreativeModule && !_vm.isPendingTab)?_c('div',{staticClass:"text-center",class:!content.edit_status ? '' : 'd-none',staticStyle:{"padding-top":"2px","width":"180px"}},[_c('label',{staticClass:"cursor-pointer",staticStyle:{"padding-left":"15px"},attrs:{"for":'content-' + content.design_request_id + 'creative'},on:{"click":function($event){return _vm.setStatusEdition(content)}}},[_c('b-badge',{staticClass:"cursor-pointer unselectable-text text-center",class:_vm.isTeamLeaderDesign && _vm.isPendingTab ? 'my-animated-button' : '',staticStyle:{"padding":"7px 11px 7px 11px"},attrs:{"variant":_vm.statusVariant(content.status_id)}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(content.status)+" ")])])],1)]):_vm._e(),(content.edit_status)?_c('v-select',{staticClass:"text-center cursor-pointer select-size-sm",staticStyle:{"width":"150px"},attrs:{"input-id":'content-' + content.design_request_id + 'creative',"options":_vm.filteredStatuses,"reduce":function (option) { return option.status_id; },"label":"status","disabled":false,"clearable":false,"append-to-body":true},on:{"input":function($event){return _vm.onStatusChangeOrchangeStageDueDate(content, 'status')},"close":function($event){return _vm.closeSelectStatus(content)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                      var status_id = ref.status_id;
return [_c('div',{staticClass:"text-center"},[_c('b-badge',{attrs:{"variant":_vm.statusVariant(status_id)}},[_vm._v(" "+_vm._s(_vm.statusName(status_id))+" ")])],1)]}}],null,true),model:{value:(content.status_id),callback:function ($$v) {_vm.$set(content, "status_id", $$v)},expression:"content.status_id"}}):_vm._e()],1)]),_c('b-td'),_c('b-td',[_c('div',{staticClass:"text-center",staticStyle:{"padding-top":"6px"}},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"padding-top":"0px"}},[((_vm.isProcessTab && !_vm.isCreativeModule) || _vm.isFinishedTab || _vm.isObservedTab)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1",staticStyle:{"position":"relative"},attrs:{"variant":"primary","size":"sm","title":"New Handle Design Files"},on:{"click":function($event){return _vm.creativeFileModal(content, 'content')}}},[(content.content_has_unseen_messages || content.content_has_unseen_notifications > 0)?_c('div',{staticStyle:{"width":"12px","height":"12px","border-radius":"50%","z-index":"30","background-color":"red","position":"absolute","right":"-7px","top":"-5px"}}):_vm._e(),_c('feather-icon',{attrs:{"size":"16","icon":"FileIcon"}})],1):_vm._e(),(
                          !_vm.isPendingTab &&
                          _vm.isParagonModule &&
                          _vm.isTeamLeaderDesign
                        )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1",attrs:{"variant":_vm.isDarkSkin ? 'dark' : 'light',"size":"sm","title":"Create task"},on:{"click":function($event){return _vm.openTaskSidebar(
                            content.design_request_id,
                            content.document_name
                          )}}},[_c('feather-icon',{attrs:{"icon":"LayersIcon","size":"16"}})],1):_vm._e()],1)])])],1),_vm._l((content.tasks),function(task,taskIndex){return (content.show_tasks && !_vm.isCreativeModule)?[_c('b-tr',{key:task.design_request_id,class:_vm.borderOnLastRow(taskIndex, content.tasks.length, contentIndex, req.contents.length)},[_c('b-td'),_c('b-td',[_c('div',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(task.document_name)+" ")])]),_c('b-td'),(_vm.isParagonModule)?_c('b-td',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center",class:!task.edit_designer ? '' : 'd-none',staticStyle:{"padding-top":"4px","width":"180px"}},[_c('label',{staticClass:"cursor-pointer",class:task.designer_name ? '' : 'text-danger',staticStyle:{"padding-left":"15px"},attrs:{"for":'set-' + task.design_request_id + 'designer'},on:{"click":function($event){return _vm.setDesignerEdition(task)}}},[_vm._v(" "+_vm._s(_vm.requestDesignerName(task.designer_name, task.has_contents))+" ")])]),(task.edit_designer)?_c('v-select',{staticStyle:{"width":"200px"},attrs:{"input-id":'set-' + task.design_request_id + 'designer',"reduce":function (user) { return user.user_id; },"options":_vm.optionsAssignee,"clearable":false,"placeholder":"To be defined","label":"user_name","append-to-body":true},on:{"input":function($event){return _vm.changeUserAssigned(task)},"close":function($event){return _vm.closeSelectDesigner(task)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                          var user_name = ref.user_name;
return [_c('div',{staticClass:"custom-option text-center"},[_vm._v(" "+_vm._s(user_name)+" ")])]}},{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Please select an option")])]},proxy:true},{key:"selected-option",fn:function(ref){
                          var user_name = ref.user_name;
return [_c('div',{staticStyle:{"height":"26.88px !important"}},[_vm._v(" "+_vm._s(user_name)+" ")])]}}],null,true),model:{value:(task.designer_id),callback:function ($$v) {_vm.$set(task, "designer_id", $$v)},expression:"task.designer_id"}}):_vm._e()],1)]):_vm._e(),_c('b-td',[_c('div',{staticClass:"text-center",attrs:{"id":"flat_picker_due_date_for_content"}},[_c('flat-pickr',{ref:("ref_flat_picker_due_date_for_content_" + (task.design_request_id)),refInFor:true,staticClass:"form-control cursor-pointer text-center",staticStyle:{"width":"15px","border":"none","outline":"none"},attrs:{"placeholder":"Deadline to be defined","disabled":_vm.isAnyRoleAllowed,"config":{
                          allowInvalidPreload: true,
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:S',
                          altFormat: 'm/d/Y h:i K',
                          altInput: true,
                          time_24hr: true,
                        }},on:{"on-close":function($event){return _vm.onStatusChangeOrchangeStageDueDate(task, 'due_date')}},model:{value:(task.due_date),callback:function ($$v) {_vm.$set(task, "due_date", $$v)},expression:"task.due_date"}})],1)]),_c('b-td',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center",class:!task.edit_status ? '' : 'd-none',staticStyle:{"padding-top":"2px","width":"180px"}},[_c('label',{staticClass:"cursor-pointer",staticStyle:{"padding-left":"15px"},attrs:{"for":'task-' + task.design_request_id + 'creative'},on:{"click":function($event){return _vm.setStatusEdition(task)}}},[_c('b-badge',{staticClass:"cursor-pointer unselectable-text text-center",class:_vm.isTeamLeaderDesign && _vm.isPendingTab ? 'my-animated-button' : '',staticStyle:{"padding":"7px 11px 7px 11px"},attrs:{"variant":_vm.statusVariant(task.status_id)}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(task.status)+" ")])])],1)]),(task.edit_status)?_c('v-select',{staticClass:"text-center cursor-pointer select-size-sm",staticStyle:{"width":"150px"},attrs:{"input-id":'task-' + task.design_request_id + 'creative',"options":_vm.filteredStatuses,"reduce":function (option) { return option.status_id; },"label":"status","disabled":false,"clearable":false,"append-to-body":true},on:{"input":function($event){return _vm.onStatusChangeOrchangeStageDueDate(task, 'status')},"close":function($event){return _vm.closeSelectStatus(task)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var status_id = ref.status_id;
return [_c('div',{staticClass:"text-center"},[_c('b-badge',{attrs:{"variant":_vm.statusVariant(status_id)}},[_vm._v(" "+_vm._s(_vm.statusName(status_id))+" ")])],1)]}}],null,true),model:{value:(task.status_id),callback:function ($$v) {_vm.$set(task, "status_id", $$v)},expression:"task.status_id"}}):_vm._e()],1)]),_c('b-td'),_c('b-td',[_c('div',{staticClass:"text-center",staticStyle:{"padding-top":"6px"}},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"padding-top":"0px"}},[(_vm.isParagonModule && _vm.isTeamLeaderDesign)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-warning",modifiers:{"hover":true,"v-warning":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1",attrs:{"variant":"warning","size":"sm","title":"Edit task"},on:{"click":function($event){return _vm.openEditRequestTask(
                              task.design_request_id,
                              task.document_name,
                              task
                            )}}},[_c('feather-icon',{attrs:{"icon":"EditIcon","size":"16"}})],1):_vm._e(),(!_vm.isParagonDesigner)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-info",modifiers:{"hover":true,"v-info":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1",attrs:{"variant":"info","size":"sm","title":"Request Tracking"},on:{"click":function($event){return _vm.trackingModal(task.design_request_id)}}},[_c('feather-icon',{attrs:{"size":"16","icon":"ListIcon"}})],1):_vm._e()],1)])])],1)]:_vm._e()})]:_vm._e()})]:_vm._e()})],2)],1),(_vm.responseQuestion)?_c('b-modal',{attrs:{"modal-class":"modal-primary","title-class":"h3 text-white","title":"TIME LINE TICKET","size":"modal-timeline","hide-footer":"","centered":"","hide-header-close":"","hide-header":""},on:{"hidden":function($event){return _vm.close()}},model:{value:(_vm.responseQuestion),callback:function ($$v) {_vm.responseQuestion=$$v},expression:"responseQuestion"}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center w-100 mt-1 mb-2"}),_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-1"},[_c('div',{staticClass:"icon-badge mr-1 pending"},[_c('feather-icon',{staticClass:"text-warning",attrs:{"icon":"AlertCircleIcon","size":"30"}})],1)]),_c('div',{staticClass:"text-center mt-1"},[_c('span',{staticClass:"text-warning",staticStyle:{"font-size":"25px"}},[_vm._v(" Are You Sure? ")])]),_c('div',{staticClass:"text-center"},[_c('span',{staticStyle:{"font-size":"15px"}},[_vm._v(" You won't be able to revert This! ")])]),_c('div',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(" SELECT A NEW DEADLINE? ")])]),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-inline-block"},[_c('flat-pickr',{staticClass:"form-control cursor-pointer text-center w-100",staticStyle:{"border":"none","outline":"none"},attrs:{"placeholder":"SELECT A NEW DEADLINE?","config":{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:S',
                altFormat: 'm/d/Y h:i K',
                altInput: true,
                time_24hr: true,
              }},model:{value:(_vm.newDueDate),callback:function ($$v) {_vm.newDueDate=$$v},expression:"newDueDate"}})],1)]),_c('br'),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","target":"_blank"},on:{"click":function($event){return _vm.saveAlertDueDate()}}},[_vm._v(" Yes ")]),_c('b-button',{attrs:{"variant":"outline-danger","target":"_blank"},on:{"click":function($event){return _vm.cancelAlertDueDate()}}},[_vm._v(" Cancel ")])],1),_c('br')])]):_vm._e()]},proxy:true}])}),(_vm.showTrackingModal)?_c('tracking-modal',{attrs:{"request-id":_vm.requestId},on:{"closeModal":function($event){_vm.showTrackingModal = false}}}):_vm._e(),(_vm.showSiderbarTask)?_c('creative-sidebar-task',{attrs:{"creative-request-id":_vm.currentCreativeRequestId,"creative-request-name":_vm.currentCreativeRequestName,"task-edition-mode":_vm.taskEditionMode,"request-task-data":_vm.currentRequestTask},on:{"taskCreated":function($event){_vm.myProvider();
      _vm.closeTaskSidebar();},"close":function($event){return _vm.closeTaskSidebar()}}}):_vm._e(),(_vm.revisionModalIsOpened)?_c('creative-revision-modal',{attrs:{"request-id":_vm.currentRequestId,"data-request":_vm.selectDataReques,"prgramIdItem":_vm.prgramIdItem},on:{"close":_vm.closeRevisionModal,"reloadTable":function($event){return _vm.myProvider()}}}):_vm._e(),(_vm.creativeFileModalOpen)?_c('CreativeFileModal',{attrs:{"creativeFileModalDesignRequestId":_vm.creativeFileModalDesignRequestId,"creativeFileModalTypeRow":_vm.creativeFileModalTypeRow,"creativeFileModalDesignRequestStatus":_vm.creativeFileModalDesignRequestStatus},on:{"reloadTable":function($event){return _vm.myProvider()},"close":_vm.closeCreativeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }