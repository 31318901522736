<template>
  <div>
    <b-modal
      v-model="control"
      title-class="h3 text-white"
      header-bg-variant="primary"
      centered
      size="xlg"
      no-close-on-backdrop
      scrollable
      :title="`REQUEST REVISION (` + dataRequest.material + `)`"
      modal-class="modal-primary"
      @hidden="closeModal"
    >

        <div class="d-flex justify-content-end my-2">
          <b-button
          v-if="moduleId === 12 && !isFinishedTab"
          variant="primary"
          @click="showUploadFilesModal = true"
          >
            <feather-icon
            icon="FileIcon"
            />
            Upload file
          </b-button>
        </div>
        <b-table-simple
          small
          caption-top
          responsive
          cellspacing="0"
          cellpadding="0"
          style="overflow-y: auto; max-height: 50vh;"
        >
          <b-thead>
            <b-tr>
              <b-th class="text-center">Preview</b-th>
              <b-th class="text-center">Code</b-th>
              <b-th class="text-center">Uploaded by</b-th>
              <b-th class="text-center">Status</b-th>
              <!-- <b-th class="text-center">Note</b-th> -->
              <b-th class="text-center">Action</b-th>
            </b-tr>
          </b-thead>
          
          <b-tbody>
            <b-tr
            v-if="isBusy"
            >
              <b-td
                colspan="6" 
                class="text-center py-2"
              >
                <div class="text-center text-primary">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </b-td>
            </b-tr>
            <b-tr
              v-if=" designMaterials.length < 1 && isBusy === false"
            >
              <b-td
                colspan="11"
                class="text-center py-2"
              >
                There are no records to show
              </b-td>
            </b-tr>


            <b-tr
              v-for="(material, index) in designMaterials"
              :key="index"
            >
              <b-td>
                <div
                  class="d-flex align-items-center justify-content-center"
                  style="height: 60px; width: 100%; margin-right: auto; margin-left: auto;"
                >
                    <div
                      class="video_box"
                      v-if="material.extension === 'mp4'"
                      style="margin-top: auto; background-color: white; height: 60px; width: 60px; margin-right: auto; margin-left: auto;"
                    >
                      <div
                        class="video_overlays"
                        @click="editMarkerImg(material)"
                      >
                        <img
                          src="/assets/images/media/play.png"
                          style="width: 100%; height: 100%;"
                        />
                      </div>
                      <video
                        class="video-player cursor-pointer"
                        :id="`plyr${material.id}`"
                        height="60"
                        width="60"
                      >
                        <source
                          :src="`${material.amazon_path_full_version}`"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div
                      v-else
                      style="margin-top: auto; background-color: white; height: 60px; width: 60px; margin-right: auto; margin-left: auto;"
                    >
                      <b-img
                        class="cursor-pointer"
                        style="width: 100%; height: 100%;"
                        fluid
                        @click="editMarkerImg(material)"
                        :src="`${material.amazon_path_preview_version}`"
                    />
                    </div>
                </div>
              </b-td>
              <b-td>
                <div
                  class="d-flex align-items-center justify-content-center h-100 mt-2"
                >
                  <span>{{ material.code }}</span>
                </div>
              </b-td>
              <b-td>
                <div class="text-center mt-2">
                  <div>{{ material.designer_name }}</div>
                  <div>{{ material.updated_at | myGlobalWithHour }}</div>
                </div>
              </b-td>
              <b-td>
                <div class="d-flex justify-content-center mt-2">
                    <div
                      style="padding-top: 2px; width: 180px;"
                      :class="!material.edit_status ? '' : 'd-none'"
                      class="text-center"
                    >
                      <label
                        :for="'mat-'+ material.id + 'creative'"
                        class="cursor-pointer"
                        style="padding-left: 15px"
                        @click="startEditingStatus(material)"
                      >
                        <b-badge
                          :variant="statusVariant(material.status_id)"
                          class="px-1 cursor-pointer text-center"
                          style="padding: 7px 11px 7px 11px;"
                          >
                            <span style="font-size: 12px;">
                              {{ statusName(material.status_id) }}
                            </span>
                        </b-badge>
                      </label>
                    </div>
                    <v-select
                      v-if="material.edit_status"
                      v-model="material.status_id"
                      :input-id="'mat-'+ material.id + 'creative'"
                      label="name"
                      :options="designMaterialStatuses"
                      :reduce="(option) => option.id"
                      class="text-center cursor-pointer select-size-sm"
                      style="width: 220px;"
                      :clearable="false"
                      :append-to-body="true"
                      :calculate-position="positionDropdown"
                      @input="changeStatus(material)"
                      @close="stopEditingStatus(material)"
                    >
                      <template v-slot:option="option">
                        <div class="text-center">
                          <b-badge
                            :variant="statusVariant(option.id)"
                            class="px-1 cursor-pointer  text-center"
                            style="padding: 7px 11px 7px 11px;"
                          >
                            <span style="font-size: 12px;">
                              {{ statusName(option.id) }}
                            </span>
                          </b-badge>
                        </div>
                      </template>
                    </v-select>
                  </div>
              </b-td>
              <!-- <b-td>
                <div>
                  <div
                    class="d-flex align-items-center justify-content-center mt-2"
                  >
                    <tabler-icon
                      class="cursor-pointer text-primary"
                      icon="MessageIcon"
                      size="20"
                      @click="notesModal(material)"
                    />
                  </div>
                </div>
              </b-td> -->
              <b-td>
                <div
                  class="d-flex align-items-center justify-content-center mt-2"
                >
                  <b-button
                    @click="downloadMaterial(material)"
                    variant="info"
                    size="sm"
                    class="mr-1"
                    v-b-tooltip.hover.v-info
                    v-b-tooltip.hover.bottom
                    title="Download Material"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                    />
                  </b-button>
                  <b-button
                    @click="openMaterialChat(material)"
                    variant="warning"
                    size="sm"
                    class="mr-1"
                    v-b-tooltip.hover.v-warning
                    v-b-tooltip.hover.bottom
                    title="Material Binnacle"
                  >
                    <tabler-icon
                      icon="MessageCircleIcon"
                      size="16"
                      :badgeClasses="['bg-danger', 'badge-style']"
                      :badge="material.module_id == moduleId && material.status_view_messages == 0"
                      :class="material.module_id == moduleId && material.status_view_messages == 0? 'pulsating-icon' : ''"
                    />
                  </b-button>
                  <b-button
                    v-if="isParagonModule"
                    @click="clickEvent(material)"
                    variant="danger"
                    size="sm"
                    class="mr-1"
                    v-b-tooltip.hover.v-danger
                    v-b-tooltip.hover.bottom
                    title="Reupload content"
                    :disabled="material.status_id == 7"
                  >
                    <feather-icon
                      icon="UploadCloudIcon"
                    />
                  </b-button>
                  <b-button
                    @click="trackingModal(material)"
                    variant="primary"
                    size="sm"
                    class="mr-1"
                    v-b-tooltip.hover.v-primary
                    v-b-tooltip.hover.bottom
                    title="Material Tracking"
                  >
                    <feather-icon
                      icon="ListIcon"
                    />
                  </b-button>
                  <div v-show="false">
                    <input 
                    type="file"
                    name="archivosubido"
                    ref="inputFile" 
                    id="inputFile" 
                    accept="image/png, image/jpeg, image/gif, video/*"
                    @change="uploadFile($event)" 
                    >
                  </div>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      <template #modal-footer>
        <!-- for creative -->
        <b-button
          v-if="moduleId === 27 && isFinishedTab && dataRequest.status_id != 4"
          variant="success"
          :disabled="allStatusAsigned"
          @click="sendRequestResponse"
        >
          <feather-icon icon="SaveIcon" />
          SAVE REVISSION
        </b-button>
        <!-- for Paragon -->
        <b-button
          v-if="moduleId === 12 && $route.meta.status != 'finished' && designMaterials.length > 0"
          variant="primary"
          @click="setAsFinished"
          :disabled="allStatusAsigned || (!isTeamLeaderDesign && !isSupervisor && !isCeo)"
        >
          SET AS FINISHED
        </b-button>
      </template>
    </b-modal>
    <creative-desing-flyer-modal
      v-if="statusRequest"
      :program-id-item="programIdItem"
      :preview-prop="fullVersion"
      :full-version-prop="preview"
      :code-prop="codeRequest"
      :extension-prop="extensionRequest"
      @succesful="saveStatus()"
      @close="closeCreativeDesingModal"
    />
    <tracking-material-modal
      v-if="showTracking"
      :materialId="materialId"
      @closeModal="showTracking = false"
    />
    <marker-image-request
    v-if="showMarkerImage"
    :sendIdRequest="idRequest"
    :sendIdMaterial="idMaterial"
    :sendIdVersion="idVersion"
    @close="showMarkerImage=false"
    />
    <material-chat
      v-if="materialChatOpen"
      :material-data="materialData"
      @close="closeMaterialChat"
    />
    <notes-modal
      v-if="showNotesModal"
      :materialId="materialSelected"
      @closeModal="showNotesModal = false"
    />
    <upload-files-modal
      v-if="showUploadFilesModal"
      :requestId="requestId"
      @refreshTable="refreshTable()"
      @closeModal="showUploadFilesModal = false"
    />
  </div>
</template>
<script>
import CreativeRequestService from '@/views/commons/components/creative-requests/services/creative.request';
import TrackingMaterialModal from '@/views/commons/components/creative-requests/modals/TrackingMaterialModal.vue';
import MarkerImageRequest from '@/views/commons/components/creative-requests/modals/MarkerImageRequest.vue'
import MaterialChat from '@/views/commons/components/creative-requests/components/MaterialChat.vue';
import NotesModal from "@/views/commons/components/creative-requests/modals/NotesModal.vue";
import CreativeDesingFlyerModal from "@/views/commons/components/creative-requests/components/creativeDesingFlyerModal.vue";
import UploadFilesModal from "@/views/commons/components/creative-requests/modals/UploadFilesModal.vue";
import { mapGetters } from 'vuex';

export default {
  name: 'CreativeRevisionModal',
  components: {
    TrackingMaterialModal,
    MarkerImageRequest,
    CreativeDesingFlyerModal,
    MaterialChat,
    NotesModal,
    UploadFilesModal,
  },
  props: {
    dataRequest: {
      type: Object,
      default: null,
      required: false,
    },
    requestId: {
      type: Number,
      required: true,
    },
    prgramIdItem: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      designMaterials: [],
      designMaterialStatuses: [],
      showUploadFilesModal: false,
      control: false,
      showTracking: false,
      showMarkerImage:false,
      idRequest:null,
      idMaterial:null,
      statusRequest: false,
      materialChatOpen: false,
      materialData: {},
      showNotesModal: false,
      materialSelected: null,
      designers: [],
      players: null,
      fullVersion: null,
      amazonPathFullVersion: null,
      preview: null,
      programIdItem:null,
      form: {},
      materialApproved: null,
      isEditingStatus: false,
      isBusy: false,
      allStatusAsigned: false,
      statusList: [],
      registerFlyerSuccessfully: false,
      itemSelected: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    moduleId() {
      return this.$route.matched[0].meta.module
    },
    isCreativeModule() {
      return this.moduleId === 27;
    },
    isParagonModule() {
      return this.moduleId === 12;
    },
    isFinishedTab() {
      return this.$route.meta.status == 'finished';
    },
  },
  async created() {

    this.control = true;
    await Promise.all([
      this.getDesignMaterialStatuses(),
      this.getDesignMaterialsByRequest(this.requestId),
      this.getParagonDesigners(),
    ]);
    this.setPlayers();
  },
  methods: {
    startEditingStatus(material) {
      if(this.isOnlyBadgeToThisStatus(material)){
        this.$set(material, 'edit_status', true);
      }
    },
    isOnlyBadgeToThisStatus(material){
      let tabStatus = this.$route.meta.status;
      if(this.currentUser.modul_id == 12 && (tabStatus == 'finished' ||  (tabStatus == 'observed' &&
        material.status_id == 7) || (tabStatus == 'in process' && material.status_id == 4) )){
        return false;
      }else if(this.currentUser.modul_id == 27 && (tabStatus == 'in process' || tabStatus == 'observed' ||
        (tabStatus == 'finished' && material.status_id == 7))){
        return false;
      }
      return true;
    },
    stopEditingStatus(material) {
      this.$set(material, "edit_status", false);
    },
    setStatusEdition(req) {
      req = true;
    },
   statusVariant(statusId) {
      switch (statusId) {
        case 1:
          return 'warning';
        case 2:
          return 'primary';
        case 3:
          return "secondary";
        case 4:
          return "info";
        case 5:
          return 'success';
        case 6:
          return 'danger';
        case 7:
          return 'dark';
      }
    },
    statusName(statusId) {
      if(statusId == null){
        return this.currentUser.modul_id == 12 ? "Pending" : "Approved by Paragon";
      }else{
        return this.statusList.find(status => status.id === statusId).name;
      }
    },
    clickEvent(material){
      this.materialSelected = material.id;
      document.getElementById("inputFile").click();
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    async uploadFile(event){
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        const file = event.target.files[0];
        const filebase64 = await this.readFileAsync(file)
 
        let material = this.designMaterials.filter(material => material.id == this.materialSelected)[0];
        this.form.file = filebase64;
        this.form.design_material_id = material.id;
        this.form.program_name = material.program_name;
        this.form.creative_design_request_id = material.creative_design_request_id;
        this.form.design_material_code = material.code;
        this.form.user_id = this.currentUser.user_id;

        try {
          this.addPreloader();
          const { data } = await CreativeRequestService.reuploadFile(this.form);
          if(data.success){
            this.getDesignMaterialsByRequest(this.requestId)
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
          }else{
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            );
          }
        } catch (error) {
          this.showErrorSwal(error)
        } finally {
          this.removePreloader();
        }
      }
    },
    async getDesignMaterialsByRequest(requestId) {
      this.addPreloader();
      try {
        const params = {
          request_id: requestId,
        };
        const { data } = await CreativeRequestService.getDesignMaterialsByRequest(params);
        data.forEach(item => {
          if((this.currentUser.modul_id == 12 && item.status_id == 1 && this.$route.meta.status == 'in process') ||
            (this.currentUser.modul_id == 27 && item.status_id == 4 && this.$route.meta.status == 'finished')){
            item.status_id = null;
          }
        });
        data.forEach((item) => {
          item.edit_status = false;
        });
        this.designMaterials = data.map(item => { return { ...item, old_status_id: item.status_id }});
        await this.validateAllStatusAsigned();
        setTimeout(() => { /* Give some time to the re-render dom after updating main request */
          this.setPlayers();
        }, 100);
      } catch (err) {
        console.error('Error in getDesignMaterialsByRequest', err);
      } finally {
        this.removePreloader();
      }
    },
    delay(ms) { //delay para esperar a que se cierre el v-select
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async getDesignMaterialStatuses() {
      try {
        const { data } = await CreativeRequestService.getDesignMaterialStatuses();
        this.statusList = data;
        if(this.currentUser.modul_id == 27){          
          this.designMaterialStatuses = data.filter(item => [5,6,7].includes(item.id));
        }else if(this.currentUser.modul_id == 12){
          if((this.$route.meta.status == 'in process' || this.$route.meta.status == 'observed') && 
            this.currentUser.role_id != 26){
            this.designMaterialStatuses = data.filter(item => [2].includes(item.id));
          }else{
            this.designMaterialStatuses = data.filter(item => [2,3,4].includes(item.id));
          }
        }else{
          this.designMaterialStatuses = data
        }
      } catch (err) {
        console.error('Error in getDesignMaterialStatuses', err);
      }
    },
    notesModal(material){
      this.materialSelected = material.id;
      this.showNotesModal = true;
    },
    closeModal() {
      this.control = false;
      // look for materials with messages not seen
      const counterMaterialWithMessagesNotSeen = this.designMaterials.filter(material => (material.status_view_messages === 0 && this.moduleId === material.module_id)).length;
      // emit event to parent component (gridCreative.vue)
      this.$emit('close', counterMaterialWithMessagesNotSeen);
    },
    validateAllStatusAsigned(){
      let tabStatus = this.$route.meta.status;
      if(tabStatus == "in process"){
        this.allStatusAsigned =  this.designMaterials.some(design => design.status_id != 4 || design.status_id == null);
      }else if(tabStatus == "observed"){
        this.allStatusAsigned =  this.designMaterials.some(design => design.status_id != 4  && design.status_id != 7);
      }else if(tabStatus == "finished"){
        this.allStatusAsigned = this.designMaterials.some(design => design.status_id == null);
      }
    },
    async sendRequestResponse() {
      const { isConfirmed } = await this.showConfirmSwal();
      if(isConfirmed) {
        try {
          this.addPreloader();
          let isObserved = this.designMaterials.some(design => design.status_id != 7);
          const params = {
            "requestId": this.requestId,
            "statusId": isObserved ? 5 : 4,
            "userId": this.currentUser.user_id
          }
          const { data } = await CreativeRequestService.saveRequestRevission(params);
          if(data.success){
            this.$emit("reloadTable");
            this.$emit("close")
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
          }else{
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            );
          }          
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    }, 
    trackingModal(item){
      this.materialId = item.id;
      this.showTracking = true;
    },
    async changeStatus(item){
      this.itemSelected = item;
      await this.delay(100);
      this.newStatusId = item.status_id;
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        if(item.status_id == 7){
          this.statusCpy = item.status_id;
          this.programIdItem =  this.prgramIdItem;   
          this.amazonPathFullVersion = item.amazon_path_full_version;
          this.fullVersion = item.full_version;
          this.preview = item.preview;
          this.materialApproved = item;
          this.extensionRequest = item.extension;
          this.codeRequest = item.code;
          this.statusRequest = true;
        }else{
          item.status_id = item.old_status_id;
          await this.saveStatus();
        }
      }else{
        item.status_id = item.old_status_id;
        return;
      } 
      item.isEditingStatus = null;
    },
    async saveStatus(){
      this.statusRequest = false;
      const params = {
        "statusId": this.newStatusId, // this.itemSelected.status_id
        "materialId": this.itemSelected.id,
        userId: this.currentUser.user_id,
      }
      try {
        const { data } = await CreativeRequestService.changeMaterialStatus(params);
        if(data.success){
          let obj = this.designMaterials.find(design => design.id == this.itemSelected.id);
          obj.status_id = this.newStatusId          
          this.validateAllStatusAsigned();
          if(this.newStatusId == 7){
            await this.downloadMaterial(this.materialApproved)
          }   
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );         
        }else{
          this.showToast(
            "danger",
            "top-right",
            "Failed!",
            "XIcon",
            "Failed operation"
          );
        }
        
      } catch (error) {
          this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    editMarkerImg(item){
      this.idRequest = item.creative_design_request_id;
      this.idMaterial = item.id;
      this.idVersion = item.design_material_version;
      this.showMarkerImage = true;
    },
    async getParagonDesigners() {
      try {
        this.isBusy = true;
        const { data } = await CreativeRequestService.getParagonDesigners();
        this.designers = data.data;
        this.isBusy = false;
      } catch (error) {
        this.showErrorSwal(error);
        this.isBusy = false;
      }
    },
    async openMaterialChat(item) {
      const params = {
        moduleId: this.moduleId,
        designMaterialId: item.id,
      };
      //Contadores
      const data = await CreativeRequestService.seeMessagesChatMaterial(params);
      if (data.status === 200) {
        item.status_view_messages = 1;
      }
      this.materialChatOpen = true;
      this.materialData = item;
    },
    refreshTable(){
      this.getDesignMaterialsByRequest(this.requestId)
    },
    closeCreativeDesingModal(){
      let index = this.designMaterials.findIndex(item => item.id == this.itemSelected.id);
      this.designMaterials[index].status_id = null;
      this.statusRequest = false;     
    },
    closeMaterialChat() {      
      this.materialChatOpen = false;
    },
    styleBadge(status) {
      switch (status) {
        case 1: // Pending
          return '#82868b !important';
        case 2: // Completed by Designer
          return '#0090e7 !important';
        case 3: // Remarked by Paragon
          return '#ff9f43 !important';
        case 4: // Approved by Paragon
          return '#6dff83 !important';
        case 5: // Remarked by Creative
          return '#ff9f43 !important';
        case 6: // Reformulate
          return '#fc424a !important';
        case 7: // Approved by Creative
          return '#00d25b !important';
        default:
          return '#ff9f43 !important';
      }
    },
    positionDropdown(dropdownList, component, { width, top, left }) {
      dropdownList.style.zIndex = 9999;
      dropdownList.style.maxHeight = "20rem";
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    async downloadMaterial(material) {
      try {
        this.addPreloader();
        if(material.amazon_path_full_version && material.extension == 'mp4'){
          window.open(material.amazon_path_full_version, '_blank');
        } else {
          const params = {
            materialId: material.id,
          };
          const data = await CreativeRequestService.downloadMaterial(params);
          this.forceFileDownload(data.data, material.file_name);
        }

        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    setPlayers() {
      /* Get all the video players */
      this.players = document.querySelectorAll('.video-player');
      /* These piece of code detect when a video exit the fullscreen mode and pause the video and remove the controls */
      this.players.forEach(player => {
        player.addEventListener('fullscreenchange', () => {
          const isFullscreen = (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement);
          if (!isFullscreen) {
            player.pause();
            player.load();
            player.controls = false;
          }
        });
      });

      /* Go directly to fullscreen mode and play the video mode when is clicked */
      this.players.forEach(player => {
        player.addEventListener('click', () => {
          player.requestFullscreen().then(() => {
            player.play();
            player.controls = true;
          });
        });
      });
    },
    simulateClickOnVideo(materialId) {
      const player = document.getElementById(`plyr${materialId}`);
      player.click();
    },
    async setAsFinished() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;

      this.addPreloader();
      const params = {
        status: 3, // finished
        requestId: this.requestId,
        userId: this.currentUser.user_id,
        moduleId: this.moduleId,
      };
      try {
        const { data } = await CreativeRequestService.changeRequestStatusDuedate(params);
        if (data.success) {
          this.showSuccessSwal();
          this.$emit("reloadTable");
          this.$emit('close');
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped>
  .badge-style{
    right: -7px;
    top: -2px;
    min-width: 11.42px;
    min-height: 11.42px;
  }
  .pulsating-icon {
    animation: pulse 0.4s infinite alternate;
  }
  @keyframes pulse {
  from {
    color: #ff9f43;
  }
  to {
    color: rgb(233, 223, 111);
  }
}
</style>
<style>
.video_box{
    position:relative;
}
.video_overlays {
    cursor: pointer;
    position: absolute;
    width: 100%;
    z-index:300000;
    top: 5px;
    left: 1px;
}
</style>
