<template>
  <div>
    <b-modal
      v-model="showModal"
      size="xlg"
      title="MATERIAL TRACKING"
      centered
      hide-footer
      @hidden="closeModal"
    >
      <div>
        <b-table
          slot="table"
          ref="trackingGrid"
          :fields="fields"
          show-empty
          sticky-header="70vh"
          :items="myProvider"
          table-class="text-nowrap"
          responsive="md"
        >
          <template #cell(preview)="data">
            <div
              class="d-flex align-items-center justify-content-center"
              style="height: 60px; width: 100%; margin-right: auto; margin-left: auto;"
            >
              <div
                class="video_box"
                v-if="data.item.extension === 'mp4'"
                style="margin-top: auto; background-color: white; height: 60px; width: 60px; margin-right: auto; margin-left: auto;"
              >
                <div
                  class="video_overlays"
                  @click="showOriginalImage(data.item)"
                >
                  <img
                    src="/assets/images/media/play.png"
                    style="width: 100%; height: 100%;"
                  />
                </div>
                <video
                  :id="`plyr${data.item.id}`"
                  class="video-tracking-player cursor-pointer"
                  height="60"
                  width="60"
                >
                  <source
                    :src="`${data.item.amazon_path_full_version}`"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div
                v-else
                style="margin-top: auto; background-color: white; height: 60px; width: 60px; margin-right: auto; margin-left: auto;"
              >
                <b-img
                  class="cursor-pointer"
                  style="width: 100%; height: 100%;"
                  fluid
                  @click="showOriginalImage(data.item)"
                  :src="`${data.item.amazon_path_preview_version}`"
                />
              </div>
            </div>
            <!-- <b-img
              class="cursor-pointer"
              width="80"
              height="160"
              thumbnail
              fluid
              :src="`${data.item.amazon_path_preview_version}`"
              @click="showOriginalImage(data.item)"
            /> -->
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at | myGlobalWithHour }}
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                class="px-1 cursor-pointer unselectable-text text-center"
                style="padding: 7px 11px 7px 11px;"
                :variant="statusVariant(data.item.status_id)"
              >
                <span style="font-size: 12px;">
                  {{ data.item.status }}
                </span>
              </b-badge>
            </div>
          </template>
          <template #cell(created_by)="data">
            <feather-icon
              icon="UserIcon"
              class="text-warning"
            />
            {{ data.item.created_by }}
            <br>
            {{ data.item.created_at | myGlobalWithHour }}
          </template>
        </b-table>
      </div>
    </b-modal>
    <original-image-modal
      v-if="showImage"
      :send-id-material="idMaterial"
      :send-id-material-tracking="idMaterialTracking"
      @closeModal="showImage = false"
    />
  </div>
</template>
<script>
import trackingFields from '@/views/commons/components/creative-requests/data/trackingMaterial.fields.js';
import RequestService from '@/views/commons/components/creative-requests/services/creative.request.js';
import OriginalImageModal from '@/views/commons/components/creative-requests/modals/OriginalImageModal.vue';

export default {
  components: {
    OriginalImageModal,
  },
  props: {
    materialId: {
      type: Number,
    },
  },
  data() {
    return {
      showModal: false,
      fields: trackingFields,
      showImage: false,
      idRequest: null,
      idMaterial: null,
      idMaterialTracking:null,
      idVersion: null,
      players: null,
    };
  },
  created() {
    this.showModal = true;
  },
  mounted() {
    setTimeout(() => {
      this.setPlayers();
    }, 100);
  },
  methods: {
    async myProvider() {
      const params = {
        materialId: this.materialId,
      };
      const { data } = await RequestService.getMaterialTracking(params);
      return data;
    },
    closeModal() {
      this.$emit('closeModal');
    },
    showOriginalImage(item) {
      this.showImage = true;
      this.idMaterial = item.design_material_id;
      this.idMaterialTracking = item.id;
    },
    statusClass(status) {
      switch (status) {
        case 'Approved': return 'text-success'; break;
        case 'Changes': return 'text-warning'; break;
        case 'Reformulate': return 'text-danger'; break;
        default: return ''; break;
      }
    },
    styleBadge(status) {
      switch (status) {
        case 1: // Pending
          return '#82868b !important';
        case 2: // Completed by Designer
          return '#0090e7 !important';
        case 3: // Remarked by Paragon
          return '#ff9f43 !important';
        case 4: // Approved by Paragon
          return '#6dff83 !important';
        case 5: // Remarked by Creative
          return '#ff9f43 !important';
        case 6: // Reformulate
          return '#fc424a !important';
        case 7: // Approved by Creative
          return '#00d25b !important';
        default:
          return '#ff9f43 !important';
      }
    },
    statusVariant(statusId) {
      switch (statusId) {
        case 1:
          return 'warning';
        case 2:
          return 'primary';
        case 3:
          return 'secondary';
        case 4:
          return 'info';
        case 5:
          return 'success';
        case 6:
          return 'danger';
        case 7:
          return 'dark';
      }
    },
    setPlayers() {
      /* Get all the video players */
      this.players = document.querySelectorAll('.video-tracking-player');
      /* These piece of code detect when a video exit the fullscreen mode and pause the video and remove the controls */
      this.players.forEach(player => {
        player.addEventListener('fullscreenchange', () => {
          const isFullscreen = (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement);
          if (!isFullscreen) {
            player.pause();
            player.load();
            player.controls = false;
          }
        });
      });

      /* Go directly to fullscreen mode and play the video mode when is clicked */
      this.players.forEach(player => {
        player.addEventListener('click', () => {
          player.requestFullscreen().then(() => {
            player.play();
            player.controls = true;
          });
        });
      });
    },
    simulateClickOnVideo(materialTrackingId) {
      const player = document.getElementById(`plyr${materialTrackingId}`);
      player.click();
    },
  },
};
</script>
