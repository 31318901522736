<template>
  <div>
    <SourceTable
      @showContactMethodModal="showContactMethodModal"
      @showSourceModal="showSourceModal"
      ref="source-table"
    />
    <ContactMethodModal
      v-if="contactMethodModal"
      :modalData="modalData"
      @hideModal="hideContactMethodModal"
    />
    <SourceModal
      v-if="sourceModal"
      :modalData="modalData"
      ref="modalSource"
      @createContactMethod="showContactMethodModal"
      @hideModal="hideSourceModal"
    />
  </div>
</template>

<script>
import SourceTable from "@/views/social-network/views/settings/components/tab-source/SourceTable.vue";
import ContactMethodModal from "@/views/social-network/views/settings/components/tab-source/modals/ContactMethodModal.vue";
import SourceModal from "@/views/social-network/views/settings/components/tab-source/modals/SourceModal.vue";

export default {
  components: {
    SourceTable,
    ContactMethodModal,
    SourceModal,
  },
  data() {
    return {
      contactMethodModal: false,
      sourceModal: false,
      modalData: {},
    };
  },
  computed: {},
  methods: {
    showContactMethodModal(item) {
      this.modalData = item;
      this.contactMethodModal = true;
    },
    hideContactMethodModal(action = false) {
      if (action == true) {
        this.$refs["source-table"].myProvider();
      }
      if (this.sourceModal) {
        this.$refs.modalSource.getContactMethod();
      }
      this.contactMethodModal = false;
    },
    showSourceModal(item) {
      this.modalData = item;
      this.sourceModal = true;
    },
    hideSourceModal(action = false) {
      if (action == true) {
        this.$refs["source-table"].myProvider();
      }
      this.sourceModal = false;
    },
  },
};
</script>
