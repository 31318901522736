export default [
    {
        key: "preview",
        label: "preview",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "code",
        label: "code",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "file",
        label: "uploaded by",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "status",
        label: "status",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "actions",
        label: "Actions",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "detail_id",
        label: "Content",        
        visible: true,
    },
]