export default [
    {
        key: "name",
        label: "Name",
        visible: true
    },
    {
        key: "initial",
        label: "Initial",
        visible: true
    },
    {
        key: "icon_url",
        label: "Icon",
        visible: true
    },
    {
        key: "source_detail",
        label: "Contact Methods",
        visible: true
    },
    {
        key: "description",
        label: "Description",
        visible: true
    },
    {
        key: "status",
        label: "Status",
        visible: true
    },
    {
        key: "created_by",
        label: "Created By",
        visible: true
    },
    {
        key: "updated_by",
        label: "Updated By",
        visible: true
    },
    {
        key: "id",
        label: "Actions",
        visible: true
    },
]