<template>
    <div>
        <div class="my-1">
            <div v-if="typeFile !='video'">
                <div class="container-action">
                    <button class="btn-action" @click="toggleFullScreen"
                    v-b-tooltip.hover.top="'Toogle full page'">
                        <svg fill="#000000" width="20" height="16" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 489.001 489.001" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M2.2,168.151l-2.1-151.3c-0.1-7.7,6.2-14,13.9-13.9l151.3,2.2c12.1,0.2,18.1,14.8,9.5,23.4l-42.1,42.1l70,70l-65,65 l-70-70l-42.1,42.1C17.1,186.251,2.4,180.251,2.2,168.151z"></path> <path d="M421.3,136.551l42.1,42.1c8.6,8.6,23.2,2.6,23.4-9.5l2.2-151.3c0.1-7.7-6.2-14-13.9-13.9l-151.3,2.2 c-12.1,0.2-18.1,14.8-9.5,23.4l42,41.9l-70,70l65,65L421.3,136.551z"></path> <path d="M314.2,460.451c-8.6,8.6-2.6,23.2,9.5,23.4l151.3,2.2c7.7,0.1,14-6.2,13.9-13.9l-2.2-151.3c-0.2-12.1-14.8-18.1-23.4-9.5 l-42.1,42.1l-70-70l-65,65l70,70L314.2,460.451z"></path> <path d="M14,485.051l151.3-2.2c12.1-0.2,18.1-14.8,9.5-23.4l-42.1-42l70-70l-65-65l-70,70l-42.1-42.1c-8.6-8.6-23.2-2.6-23.4,9.5 L0,471.151C0,478.851,6.3,485.151,14,485.051z"></path> </g> </g> </g></svg>
                    </button>
                    <button class="btn-action" @click="zoomIn"
                    v-b-tooltip.hover.top="'Zoom in'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" style="fill: rgb(0, 0, 0);transform: ;msFilter:;"><path d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8zm4 9h-3v3H9v-3H6V9h3V6h2v3h3v2z"></path></svg>
                    </button>
                    <button class="btn-action" @click="zoomOut"
                    v-b-tooltip.hover.top="'Zoom out'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" style="fill: rgb(0, 0, 0);transform: ;msFilter:;"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zM6 9h8v2H6V9z"></path></svg>
                    </button>
                    <button class="btn-action" @click="resetZoom"
                    v-b-tooltip.hover.top="'Go Home'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: rgb(0, 0, 0);transform: ;msFilter:;"><path d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"></path></svg>
                    </button>
                    <button class="btn-action" @click="rotateLeft"
                    v-b-tooltip.hover.top="'Rotate left'">
                        <svg fill="#000000" width="20" height="20"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="rotate-left" class="icon glyph" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M13,3A9,9,0,0,0,4.91,8.08l-1-2.45a1,1,0,0,0-1.86.74l2,5a1,1,0,0,0,.86.62H5l.11,0a1.32,1.32,0,0,0,.26-.05l.15-.06,4.85-1.94a1,1,0,0,0-.74-1.86L6.54,9.31a7,7,0,1,1,1.21,7.32,1,1,0,0,0-1.41-.09A1,1,0,0,0,6.25,18,9,9,0,1,0,13,3Z"></path></g></svg>
                    </button>
                    <button class="btn-action" @click="rotateRight"
                    v-b-tooltip.hover.top="'Rotate right'">
                        <svg fill="#000000" width="20" height="20"       viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="1.44"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.48,3.43A9.09,9.09,0,0,1,18.3,5.55V2.35h1.64v6.5h-6.5V7.21H17.7a7.46,7.46,0,1,0,1.47,8.65l1.46.73A9.11,9.11,0,1,1,12.48,3.43Z"></path> </g></svg>
                    </button>
                </div>

                <div class="osd-container"
                :class="isDarkSkin? '' : 'border-white'">
                    <b-overlay :show="!hasHandledOpenEvent" :variant="skin">
                        <div :id="`openseadragon-${materialAnotation.id}`" class="openseadragon"
                        :class="isDarkSkin? '' : 'openseadragon-white'">
                        </div>
                    </b-overlay>
                </div>
            </div> 
            
            <div v-else class="w-100 d-flex justify-content-center">
                <div v-if="extensionFile !='gif'">
                    <video
                    class="video-player cursor-pointer"
                    height="450px"
                    width="750px"
                    controls
                    >
                    <source
                    :src="`${materialAnotation.amazon_path_full_version}`"
                    type="video/mp4"
                        />
                    </video>
                </div>

                <div v-else class="container-img">
                    <!-- <b-overlay :show="loading" :variant="skin" style="margin: auto;" class="d-flex align-items-center justify-content-center">
                        <div style="width: 100%; height: 100%;">
                        </div>
                    </b-overlay> -->
                    <b-img
                        class="cursor-pointer"
                        style="width: 100%; height: 100%;"
                        fluid
                        :src="materialAnotation.amazon_path_full_version"

                    />
                </div>

            </div>
        </div>
    </div>
</template>
<script>

import { mapGetters } from "vuex";
import OpenSeadragon from 'openseadragon';
import * as Annotorious from '@recogito/annotorious-openseadragon';
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';

export default {
    props: {
        sendMarkerImage:{
            type:Object, 
            required:true
        },
    },
    data(){
        return {
            idMaterial:null,
            idVersion:null,
            materialAnotation:{},
            anotationsImg:[],
            osdViewer: null, 
            isFullScreen: false,
            hasHandledOpenEvent:false,
            typeFile:null,
            extensionFile:null,
            anno:null,
        }
    },

    mounted() {
        if (this.typeFile != 'video') {
                this.initializeOpendragonAnotorious();
            }

            //bottom
            const collection = document.getElementsByClassName("carousel-indicators");
            collection[0].style.marginBottom = '0px';
       
    },

    computed:{
            ...mapGetters({
            skin: "appConfig/skin",
            }),
        },

   async created() {
        this.materialAnotation = this.sendMarkerImage;
        this.typeFile = this.materialAnotation.typeFile;
        this.extensionFile = this.materialAnotation.extension;

        this.getMaterialAnotationImg();
    },
    methods:{
        initializeOpendragonAnotorious(){
            const isAnotations = this.anotationsImg.length === 0 ;
            this.initializeOpenSeadragon();

            if (!isAnotations) {
                const annotationsJSON = JSON.stringify(this.anotationsImg);
                this.anno.loadAnnotations(`data:application/json,${encodeURIComponent(annotationsJSON)}`);
            }        
        },

        initializeOpenSeadragon() {
            var viewer = OpenSeadragon({
                id: `openseadragon-${this.materialAnotation.id}`,
                prefixUrl: '/assets//images/openseadragon/images/',
                showZoomControl: false,   
                showHomeControl: false,   
                showFullPageControl: false,
                showRotationControl: false,  
                showSequenceControl: false,  
                showNavigator: true, 
                tileSources: {
                    type: "image",
                    url: this.materialAnotation.amazon_path_full_version
                    }
                });

                this.osdViewer = viewer;

                var options = {
                    disableEditor: false,
                    readOnly:true

                };
                // Asigna 'anno' a la instancia de Annotorious
                this.anno = Annotorious(viewer, options);

                this.anno.on('mouseEnterAnnotation', function(annotation, event) {
                    event.classList.remove("hover");
                });

                this.hasHandledOpenEvent = false;

                const openHandler = () => {
                    if (!this.hasHandledOpenEvent) {
                        this.hasHandledOpenEvent = true;
                    }
                };

                viewer.addHandler('open', openHandler);
        },

        closeModal() {
            this.$emit("closeModal");
        },
 
        zoomIn() {
                if (this.osdViewer && this.osdViewer.viewport) {
                    const currentZoom = this.osdViewer.viewport.getZoom();
                    const newZoom = currentZoom * 1.2; // Puedes ajustar el factor de zoom según tus necesidades
                    this.osdViewer.viewport.zoomTo(newZoom, null, true);
                }
        },

        zoomOut() {
            if (this.osdViewer && this.osdViewer.viewport) {
                const currentZoom = this.osdViewer.viewport.getZoom();
                const newZoom = currentZoom / 1.2; // Puedes ajustar el factor de zoom según tus necesidades
                this.osdViewer.viewport.zoomTo(newZoom, null, true);
            }
        },

        resetZoom() {
            if (this.osdViewer && this.osdViewer.viewport) {
                // Llama a la función goHome() para hacer zoom para que toda la imagen sea visible
                this.osdViewer.viewport.goHome();
            }
        },

        toggleFullScreen() {
            if (this.osdViewer) {
                this.osdViewer.setFullScreen(!this.isFullScreen);
            }
        },

        rotateRight() {
            if (this.osdViewer && this.osdViewer.viewport) {
                const currentRotation = this.osdViewer.viewport.getRotation();
                const newRotation = currentRotation + 90; // Rotar 90 grados en sentido horario
                this.osdViewer.viewport.setRotation(newRotation);
            }
        },
        
        rotateLeft() {
            if (this.osdViewer && this.osdViewer.viewport) {
                const currentRotation = this.osdViewer.viewport.getRotation();
                const newRotation = currentRotation - 90; // Rotar 90 grados en sentido antihorario
                this.osdViewer.viewport.setRotation(newRotation);
            }
        },

        getMaterialAnotationImg(){
            const isAnotations = this.materialAnotation.arr_anotations[0].annotations;

            if (isAnotations === null) {
                this.anotationsImg = [];
            }else{
                this.anotationsImg = [];
                    this.materialAnotation.arr_anotations.forEach(element => {
                        this.anotationsImg.push(
                            element.annotations
                        )
                    });
            }

        },



    }
}
</script>


<style scoped>

/* .flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
} */

.osd-container{
    border-radius:12px;
    background-color:#202024;
}

.openseadragon {
    width: 780px;
    height: 450px;
    padding: 6px;
    border-radius:12px;
    border:5px solid #555050;
}

.a9s-annotationlayer .a9s-formatter-el,
.a9s-annotationlayer .a9s-formatter-el foreignObject {
  overflow:visible;
  pointer-events:none;
}

.a9s-annotationlayer .a9s-formatter-el foreignObject .a9s-shape-label-wrapper {
  position:relative;
  transform:translateY(-100%);
  padding-bottom:4px;
}

.a9s-annotationlayer .a9s-formatter-el foreignObject .a9s-shape-label-wrapper .a9s-shape-label {
  display:table;
  padding:3px 5px;
  white-space:nowrap;
  background-color:rgba(255, 255, 255, 0.85);
  border-radius:3px;
  font-size:14px;
}

.container-action{
        display:flex;
        justify-content:center;
        padding:8px 6px;
        display:flex;
}

.btn-action{
    background-color:rgb(235, 231, 231);
    padding:4.3px;
    border-radius:50%;
    border:none !important;
    margin-right:12px;
    display:flex;
    align-items:center;
    justify-content:center;

}

.openseadragon-white{
        border:5px solid #b9b9bde3;
    }

    .border-white{
        /* border:1px solid red; */
        background-color:#eeeef0e3;
    }

.btn-action:hover{
    background-color:rgba(131, 206, 207, 0.573);
     
}


</style>


<style lang="scss" scoped>
// Estilos SASS aquí

    .container-img{
            // background-color:red;
        padding:20px;
        width:750px;
        height:450px;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .btn-action:hover{
        background-color:rgba(71, 64, 175, 0.859);
          
        svg{
            fill:white !important;
            color:white !important;
            stroke:white !important;
        }
    }
</style>