var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-detail-task","title":_vm.sideBarTitle,"header-class":"px-2 text-bold","width":"40%","bg-variant":"white","no-close-on-backdrop":"","shadow":"","backdrop":"","right":""},on:{"hidden":_vm.close},model:{value:(_vm.ownControl),callback:function ($$v) {_vm.ownControl=$$v},expression:"ownControl"}},[(_vm.isLoadingTask)?_c('div',{staticClass:"px-2"},[_c('div',{staticClass:"d-flex"},[_c('b-skeleton',{staticClass:"mr-1",attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"3%"}}),_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"50%"}})],1)]):_vm._e(),_c('div',{staticClass:"mb-2 px-2"},[_c('b-card',{staticClass:"px-0 h-100",attrs:{"no-body":""}},[_c('div',{staticClass:"mt-2  container-task"},[_c('div',[_c('validation-observer',{ref:"formTaskCreative"},[_c('b-row',[_c('b-col',{staticClass:"mb-2 pl-0",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.isLoadingTask)?_c('div',{staticClass:"pl-1"},[_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"100%","height":"17px"}})],1):_c('div',[_c('validation-provider',{attrs:{"name":"v-title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:errors[0] ? 'due_date_containerx213' : ''},[_c('b-form-input',{attrs:{"autofocus":!_vm.isVisible,"state":errors[0] ? false : null,"placeholder":"Untitled"},model:{value:(_vm.taskForm.title),callback:function ($$v) {_vm.$set(_vm.taskForm, "title", $$v)},expression:"taskForm.title"}})],1)]}}])})],1)])],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[(_vm.isLoadingTask)?_c('div',{staticClass:"d-flex"},[_c('b-skeleton',{staticClass:"mr-1",attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"12%","height":"17px"}}),_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"60%","height":"17px"}})],1):_c('div',{staticClass:"h-100 d-flex align-items-center"},[_c('div',{staticClass:"label-input"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"UserIcon","size":"16"}})],1),_c('h3',{staticClass:"title-label"},[_vm._v(" Assignee ")])])])]),_c('b-col',{attrs:{"cols":"8"}},[(_vm.isLoadingTask)?_c('div',[_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"100%","height":"17px"}})],1):_c('div',[_c('validation-provider',{attrs:{"name":"v-assigne","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:errors[0] ? 'due_date_containerx213' : '',attrs:{"reduce":function (user) { return user.user_id; },"options":_vm.optionsAssignee,"placeholder":"None","label":"user_name","state":errors[0] ? 'due_date_containerx213' : ''},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var user_name = ref.user_name;
var image_thumb = ref.image_thumb;
return [_c('div',{staticClass:"custom-option"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"src":image_thumb}}),_vm._v(" "+_vm._s(user_name)+" ")],1)]}},{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Please select an option")])]},proxy:true},{key:"selected-option",fn:function(ref){
var user_name = ref.user_name;
var image_thumb = ref.image_thumb;
return [_c('div',{staticStyle:{"height":"26.88px !important"}},[_c('b-avatar',{staticClass:"mr-1",attrs:{"src":image_thumb,"size":"23"}}),_vm._v(" "+_vm._s(user_name)+" ")],1)]}}],null,true),model:{value:(_vm.taskForm.assignee),callback:function ($$v) {_vm.$set(_vm.taskForm, "assignee", $$v)},expression:"taskForm.assignee"}})]}}])})],1)])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[(_vm.isLoadingTask)?_c('div',{staticClass:"d-flex"},[_c('b-skeleton',{staticClass:"mr-1",attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"12%","height":"17px"}}),_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"60%","height":"17px"}})],1):_c('div',{staticClass:"label-input"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CommandIcon","size":"16"}})],1),_c('h3',{staticClass:"title-label"},[_vm._v(" Pieces ")])])]),_c('b-col',{attrs:{"cols":"8"}},[(_vm.isLoadingTask)?_c('div',[_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"100%","height":"17px"}})],1):_c('div',[(!_vm.isVisible)?_c('div',[_c('validation-provider',{attrs:{"name":"v-pieces-quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"title"}},[_c('b-input-group',{staticClass:"flex-nowrap"},[_c('b-form-input',{attrs:{"placeholder":"Pieces quantity","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.taskForm.pieces_quantity),callback:function ($$v) {_vm.$set(_vm.taskForm, "pieces_quantity", $$v)},expression:"taskForm.pieces_quantity"}})],1)],1)]}}],null,false,3389317392)})],1):_vm._e()])])],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[(_vm.isLoadingTask)?_c('div',{staticClass:"d-flex"},[_c('b-skeleton',{staticClass:"mr-1",attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"12%","height":"17px"}}),_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"60%","height":"17px"}})],1):_c('div',{staticClass:"label-input"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CalendarIcon","size":"16"}})],1),_c('h3',{staticClass:"title-label"},[_vm._v(" Due date ")])])]),_c('b-col',{attrs:{"cols":"8"}},[(_vm.isLoadingTask)?_c('div',[_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"100%","height":"17px"}})],1):_c('div',[_c('validation-provider',{attrs:{"name":"v-due_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:errors[0] ? 'due_date_containergf21' : ''},[_c('flat-pickr',{ref:"refDueDate",staticClass:"form-control cursor-pointer text-left",staticStyle:{"width":"100px","border":"1px solid red !important"},attrs:{"id":"flat_picker_due_date43232","placeholder":"Due date to be defined","config":_vm.configDate},model:{value:(_vm.taskForm.due_date),callback:function ($$v) {_vm.$set(_vm.taskForm, "due_date", $$v)},expression:"taskForm.due_date"}})],1)]}}])})],1)])],1)],1),_c('b-col',{staticClass:"mb-1 mt-1",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[(_vm.isLoadingTask)?_c('div',[_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark' : 'light',"width":"80%","height":"17px"}})],1):_c('div',{staticClass:"label-input"},[_c('h3',{staticClass:"m-0 p-0 subtitle"},[_vm._v(" Description ")])])]),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[(_vm.isLoadingTask)?_c('div',[_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark' : 'light',"width":"100%","height":"27px"}})],1):_c('div',[_c('validation-provider',{attrs:{"name":"v-observation","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Enter task...","rows":"7","max-length":"255","max-rows":"10","state":errors[0] ? false : null},model:{value:(_vm.taskForm.details),callback:function ($$v) {_vm.$set(_vm.taskForm, "details", $$v)},expression:"taskForm.details"}})]}}])})],1)])],1)],1),_c('b-col',{staticStyle:{"height":"auto"},attrs:{"cols":"12"}},[_c('b-card',{attrs:{"no-body":""}},[(_vm.isLoadingTask)?_c('div',[_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"25%","height":"17px"}}),_c('b-skeleton',{attrs:{"animation":"fade","variant":_vm.isDarkSkin ? 'dark':'light',"width":"5%","height":"17px"}})],1)]):_vm._e()])],1)],1)],1)],1)]),_c('div',{staticClass:"b-siderbar-footer"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',[_c('button-cancel',{on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCEL ")])],1),_c('div',{staticClass:"ml-2"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.createTaskInCreativeRequest()}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.textButton))],1)],1)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }