<template>
  <div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['teamRankTable'].refresh()"
    >
      <template #table>
        <b-table
          slot="table"
          ref="teamRankTable"
          :fields="fields"
          show-empty
          sticky-header="70vh"
          primary-key="motive_id"
          :items="myProvider"
          table-class="text-nowrap"
          responsive="sm"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(created_at)="data">
            <span> {{ data.item.created_at | myGlobalDay }}</span>
          </template>
          <template #cell(a_statuss)="data">
            <b-badge
              class="w-100"
              pill
              :variant="variantBadgeStatus(data.item.a_statuss)"
            >
              {{ data.item.a_statuss | myFontCapitalize }}
            </b-badge>
          </template>
          <template #cell(a_status_session)="data">
            <b-badge
              class="w-100"
              pill
              :variant="variantBadgeSession(data.item.a_status_session)"
            >
              {{ data.item.a_status_session | myFontCapitalize }}
            </b-badge>
          </template>

          <template #cell(tracking)="data">
            <feather-icon
              v-b-tooltip.hover.top="'TRACKING USER'"
              icon="ListIcon"
              size="20"
              class="cursor-pointer"
              @click="openModalTrackingUser(data.item)"
            />
          </template>
          <template #cell(actions)="data">
            <feather-icon
              v-if="data.item.status == '1'"
              v-b-tooltip.hover.top="'DESACTIVATE USER'"
              icon="ThumbsDownIcon"
              size="20"
              class="cursor-pointer text-danger mr-1"
              @click="enableOrDisableUser(data.item)"
            />
            <feather-icon
              v-if="data.item.status == '0'"
              v-b-tooltip.hover.top="'ACTIVATE USER'"
              icon="ThumbsUpIcon"
              size="18"
              class="cursor-pointer text-success mr-1"
              @click="enableOrDisableUser(data.item)"
            />
            <feather-icon
              class="mr-1 cursor-pointer text-warning"
              v-b-tooltip.hover.top="'UPDATE ROLE'"
              icon="EditIcon"
              size="17"
              @click="ModalupdateRoleUser(data.item)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <UpdateRoleUserTeamRankModal
      v-if="statusUpdateRoleUserTeamRankModal"
      @hidden="statusUpdateRoleUserTeamRankModal = false"
      @closeModalupdateRoleUser="closeModalupdateRoleUser"
      :dataUpdateUser="dataUpdateUser"
    />

    <TrackingUserRoleTeamRank
      v-if="statusTrackingUserRoleTeamRank"
      @hidden="statusTrackingUserRoleTeamRank = false"
      :dataUser="dataUser"
      @closeModalTrackingUser="closeModalTrackingUser"
    />
  </div>
</template>

<script>
import SettingsService from "@/views/social-network/views/settings/settings.service";
import { mapGetters } from "vuex";
import teamRankFilter from "@/views/social-network/views/settings/team.rank.filter.js";
import UpdateRoleUserTeamRankModal from "@/views/social-network/views/settings/modals/UpdateRoleUserTeamRankModal.vue";
import TrackingUserRoleTeamRank from "@/views/social-network/views/settings/modals/TrackingUserRoleTeamRank.vue";

export default {
  components: { UpdateRoleUserTeamRankModal, TrackingUserRoleTeamRank },
  data() {
    return {
      statusUser: "",
      dataUser: {
        user_id: "",
        user_name: "",
      },
      dataUpdateUser: {
        id_rol: "",
        id_user: "",
        rol: "",
        full_name: "",
      },
      statusUpdateRoleUserTeamRankModal: false,
      statusTrackingUserRoleTeamRank: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: teamRankFilter,
      fields: [
        {
          key: "full_name",
          label: "USER",
        },
        {
          key: "email",
          label: "EMAIL",
        },
        {
          key: "a_statuss",
          label: "STATUS",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "rol",
          label: "ROLE",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "a_status_session",
          label: "STATUS SESSION",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "phone",
          label: "PHONE NUMBER",
          tdClass: "text-center",
          thClass: "text-center",
        },

        {
          key: "created_at",
          label: "Created at",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true,
        },
        {
          key: "tracking",
          label: "TRACKING",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: {
            width: "150px",
          },
        },
      ],
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: null,
      toPage: null,
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      searchInput: "",
      motiveToSend: {},
      mood: 0,
      motiveLossTypes: [],
      isFromReply: false,
    };
  },
  async created() {
    await this.getAllRole();
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    // updateColumn(orderId) {

    //   const order = this.items.find(
    //     (item) => item.id_user == orderId
    //   );
    // this.$set(order, "date_file_reception", 1);
    // },
    async getAllRole() {
      try {
        const { data } = await SettingsService.getAllRoles();
        this.filter[3].options = data;
      } catch (e) {
        console.log(error);
      }
    },
    openModalTrackingUser(params) {
      this.dataUser.user_id = params.id_user;
      this.dataUser.user_name = params.full_name;
      this.statusTrackingUserRoleTeamRank = true;
    },
    async enableOrDisableUser(params) {
      try {
        if (params.status == "1") {
          this.statusUser = "0";
        } else {
          this.statusUser = "1";
        }

        this.idUser = params.id_user;

        const dataUser = {
          statusUser: this.statusUser,
          idUser: this.idUser,
        };

        const resultState = await this.showConfirmSwal();
        this.addPreloader();
        if (resultState.isConfirmed) {
          const request = await SettingsService.enableOrDisableUser(dataUser);
          if (request.status == 200) {
            this.showSuccessSwal();
            this.$refs["teamRankTable"].refresh();
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    ModalupdateRoleUser(params) {
      this.statusUpdateRoleUserTeamRankModal = true;
      this.dataUpdateUser.full_name = params.full_name;
      this.dataUpdateUser.id_rol = params.id_rol;
      this.dataUpdateUser.id_user = params.id_user;
      this.dataUpdateUser.rol = params.rol;
    },
    closeModalupdateRoleUser() {
      this.statusUpdateRoleUserTeamRankModal = false;
      this.$refs["teamRankTable"].refresh();
    },
    closeModalTrackingUser() {
      this.statusTrackingUserRoleTeamRank = false;
    },

    async myProvider(ctx) {
      try {
        let params = {
          id_user_sesion: this.currentUser.user_id,
          search_text: this.filterPrincipal.model,
          a_date_from: this.filter[0].model,
          a_date_to: this.filter[1].model,
          a_status: this.filter[2].model,
          a_role: this.filter[3].model,
          orderby: "created_at",
          order: "asc",
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
        };
        const { data, status } = await SettingsService.getAllUserTeamRank(
          params
        );
        if (status == 200) {
          this.startPage = data.from;
          this.toPage = data.to;
          this.totalRows = data.total;
          this.paginate.currentPage = data.current_page;
          this.paginate.perPage = data.per_page;
          return data.data || [];
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    variantBadgeStatus(type) {
      // If the type is "Deposit", return "success"
      if (type == "Active") {
        return "success";
      }
      // Otherwise, return "secondary"
      else {
        return "secondary";
      }
    },
    variantBadgeSession(type) {
      // If the type is "Deposit", return "success"
      if (type == "Active") {
        return "primary";
      }
      // Otherwise, return "secondary"
      else {
        return "secondary";
      }
    },
  },
};
</script>

