<template>
  <b-sidebar
    id="sidebar-detail-task"
    v-model="ownControl"
    :title="sideBarTitle"
    header-class="px-2 text-bold"
    width="40%"
    bg-variant="white"
    no-close-on-backdrop
    shadow
    backdrop
    right
    @hidden="close"
  >
    <div
      v-if="isLoadingTask"
      class="px-2"
    >
      <div class="d-flex">
        <b-skeleton
          animation="fade"
          :variant="isDarkSkin ? 'dark':'light'"
          width="3%"
          class="mr-1"
        />
        <b-skeleton
          animation="fade"
          :variant="isDarkSkin ? 'dark':'light'"
          width="50%"
        />
      </div>
    </div>
    <div
      class="mb-2 px-2"
    >
      <b-card
        no-body
        class="px-0 h-100"
      >
        <div class="mt-2  container-task">
          <div>
            <validation-observer ref="formTaskCreative">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2 pl-0"
                >
                  <b-row>
                    <b-col cols="12">
                      <div
                        v-if="isLoadingTask"
                        class="pl-1"
                      >
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="100%"
                          height="17px"
                        />
                      </div>
                      <div v-else>
                        <validation-provider
                          v-slot="{ errors }"
                          name="v-title"
                          rules="required"
                        >
                          <div :class="errors[0] ? 'due_date_containerx213' : ''">
                            <b-form-input
                              v-model="taskForm.title"
                              :autofocus="!isVisible"
                              :state="errors[0] ? false : null"
                              placeholder="Untitled"
                            />
                          </div>
                        </validation-provider>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <b-row>
                    <b-col cols="4">
                      <div
                        v-if="isLoadingTask"
                        class="d-flex"
                      >
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="12%"
                          class="mr-1"
                          height="17px"
                        />
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="60%"
                          height="17px"
                        />
                      </div>
                      <div
                        v-else
                        class="h-100 d-flex align-items-center"
                      >
                        <div class="label-input">
                          <div class="d-flex align-items-center">
                            <feather-icon
                              icon="UserIcon"
                              size="16"
                              class="mr-1"
                            />
                          </div>
                          <h3 class="title-label">

                            Assignee
                          </h3>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="8">
                      <div v-if="isLoadingTask">
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="100%"
                          height="17px"
                        />
                      </div>
                      <div v-else>
                        <validation-provider
                          v-slot="{ errors }"
                          name="v-assigne"
                          rules="required"
                        >
                          <v-select
                            v-model="taskForm.assignee"
                            :reduce="(user) => user.user_id"
                            :options="optionsAssignee"
                            class="w-100"
                            placeholder="None"
                            label="user_name"
                            :class="errors[0] ? 'due_date_containerx213' : ''"
                            :state="errors[0] ? 'due_date_containerx213' : ''"
                          >
                            <template #option="{ user_name, image_thumb }">
                              <div class="custom-option">
                                <b-avatar
                                  :src="image_thumb"
                                  class="mr-1"
                                />
                                {{ user_name }}
                              </div>
                            </template>

                            <template #first>
                              <b-form-select-option
                                :value="null"
                                disabled
                              >Please select an option</b-form-select-option>
                            </template>

                            <template #selected-option="{ user_name, image_thumb }">
                              <div style="height:26.88px !important">
                                <b-avatar
                                  :src="image_thumb"
                                  class="mr-1"
                                  size="23"
                                />
                                {{ user_name }}
                              </div>
                            </template>
                          </v-select>
                        </validation-provider>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-row>
                    <b-col cols="4">
                      <div
                        v-if="isLoadingTask"
                        class="d-flex"
                      >
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="12%"
                          class="mr-1"
                          height="17px"
                        />
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="60%"
                          height="17px"
                        />
                      </div>
                      <div
                        v-else
                        class="label-input"
                      >
                        <div class="d-flex align-items-center">
                          <feather-icon
                            icon="CommandIcon"
                            size="16"
                            class="mr-1"
                          />
                        </div>
                        <h3 class="title-label">
                          Pieces
                        </h3>
                      </div>
                    </b-col>
                    <b-col cols="8">
                      <div v-if="isLoadingTask">
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="100%"
                          height="17px"
                        />
                      </div>
                      <div v-else>
                        <div v-if="!isVisible">
                          <validation-provider
                            v-slot="{ errors }"
                            name="v-pieces-quantity"
                            rules="required"
                          >
                            <b-form-group
                              label-for="title"
                            >
                              <b-input-group class="flex-nowrap">
                                <b-form-input
                                  v-model="taskForm.pieces_quantity"
                                  placeholder="Pieces quantity"
                                  type="number"
                                  :state="errors.length > 0 ? false : null "
                                />
                              </b-input-group>
                            </b-form-group>
                          </validation-provider>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <b-row>
                    <b-col cols="4">
                      <div
                        v-if="isLoadingTask"
                        class="d-flex"
                      >
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="12%"
                          class="mr-1"
                          height="17px"
                        />
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="60%"
                          height="17px"
                        />
                      </div>
                      <div
                        v-else
                        class="label-input"
                      >
                        <div class="d-flex align-items-center">
                          <feather-icon
                            icon="CalendarIcon"
                            size="16"
                            class="mr-1"
                          />
                        </div>
                        <h3 class="title-label">
                          Due date
                        </h3>
                      </div>
                    </b-col>
                    <b-col cols="8">
                      <div v-if="isLoadingTask">
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="100%"
                          height="17px"
                        />
                      </div>
                      <div v-else>
                        <validation-provider
                          v-slot="{ errors }"
                          name="v-due_date"
                          rules="required"
                        >
                          <div :class="errors[0] ? 'due_date_containergf21' : ''">
                            <flat-pickr
                              id="flat_picker_due_date43232"
                              ref="refDueDate"
                              v-model="taskForm.due_date"
                              class="form-control cursor-pointer text-left"
                              placeholder="Due date to be defined"
                              style="width: 100px; border: 1px solid red !important;"
                              :config="configDate"
                            />
                          </div>
                        </validation-provider>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-1 mt-1"
                >
                  <b-row>
                    <b-col cols="4">
                      <div v-if="isLoadingTask">
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark' : 'light'"
                          width="80%"
                          height="17px"
                        />
                      </div>
                      <div
                        v-else
                        class="label-input"
                      >
                        <h3 class="m-0 p-0 subtitle">
                          Description
                        </h3>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      class="mt-1"
                    >
                      <div v-if="isLoadingTask">
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark' : 'light'"
                          width="100%"
                          height="27px"
                        />
                      </div>

                      <div v-else>
                        <validation-provider
                          v-slot="{errors}"
                          name="v-observation"
                          rules="required|max:255"
                        >
                          <b-form-textarea
                            id="textarea"
                            v-model="taskForm.details"
                            placeholder="Enter task..."
                            rows="7"
                            max-length="255"
                            max-rows="10"
                            :state="errors[0] ? false : null"
                          />
                        </validation-provider>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  style="height:auto;"
                >
                  <b-card
                    no-body
                  >
                    <div v-if="isLoadingTask">
                      <div class="d-flex justify-content-between w-100">
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="25%"
                          height="17px"
                        />
                        <b-skeleton
                          animation="fade"
                          :variant="isDarkSkin ? 'dark':'light'"
                          width="5%"
                          height="17px"
                        />
                      </div>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </validation-observer>
          </div>
        </div>
        <div class="b-siderbar-footer">
          <div class="d-flex justify-content-end">
            <div>
              <button-cancel @click="close()">
                CANCEL
              </button-cancel>
            </div>
            <div class="ml-2">
              <b-button
                variant="primary"
                @click="createTaskInCreativeRequest()"
              ><feather-icon icon="SaveIcon" />
                {{ textButton }}</b-button>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
import CreativeRequestService from '@/views/commons/components/creative-requests/services/creative.request';
import Ripple from 'vue-ripple-directive';
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import ButtonCancel from '@/views/commons/utilities/ButtonCancel.vue';

export default {
  components: {
    ButtonCancel,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    creativeRequestId: {
      required: true,
      type: Number,
    },
    creativeRequestName: {
      required: true,
      type: String,
    },
    taskEditionMode: {
      type: Boolean,
      default: false,
    },
    requestTaskData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ownControl: false,
      isVisible: false,
      isLoadingTask: false,
      taskForm: {
        title: '',
        assignee: null,
        pieces_quantity: null,
        variants_quantity: null,
        status_id: 1,
        due_date: null,
        details: null,
        request_task_id: null,
        document_name: '',
      },
      subtaskTitle: '',
      showSubTask: false,
      disabledSave: true,
      totalSubTask: null,
      taskId: null,
      projectId: null,
      editStatusTasks: false,
      showInputTask: false,
      optionsStatus: [],
      optionsAssignee: [],
      arrSubTask: [],
      isBusy: false,
      textButton: 'Save',
      mainProps: {
        blankColor: '#777',
        width: 150,
        height: 120,
        class: '',
      },
      assetsImg: process.env.VUE_APP_BASE_URL_FRONT,
      statuses: [
        { status_id: 1, status_name: 'PENDING' },
        { status_id: 2, status_name: 'IN PROCESS' },
        { status_id: 3, status_name: 'FINISHED' },
      ],
      configDate: {
        allowInvalidPreload: true,
        enableTime: true,
        altFormat: 'm/d/Y h:i K',
        altInput: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true,
        minDate: 'today',
      }
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    currentUserId() {
      return this.currentUser.user_id;
    },
    sideBarTitle() {
      if (this.taskEditionMode) { /*  Show task title */
        return `Edit task : ${this.taskForm.document_name.toUpperCase()}`;
      } /* Show parent request title */
      return `Create task in : ${this.creativeRequestName}`;
    },
  },

  watch: {},

  async mounted() {
    if (this.taskEditionMode) {
      this.taskForm = { ...this.requestTaskData };
    }
    this.isLoadingTask = true;
    this.ownControl = true;
    await Promise.all([
      this.getParagonDesignerUsers(),
    ]);
    this.isLoadingTask = false;
  },
  methods: {
    async createTaskInCreativeRequest() {
      try {
        const validation = await this.$refs.formTaskCreative.validate();
        if (!validation) return;
        const confirm = await this.showConfirmSwal();
        if (!confirm.isConfirmed) return;
        const params = {
          observation: this.taskForm.details,
          task_title: this.taskForm.title,
          pieces_quantity: this.taskForm.pieces_quantity,
          status_id: 1,
          due_date: this.taskForm.due_date,
          creative_parent_request_id: this.creativeRequestId,
          designer_id: this.taskForm.assignee,
          user_id: this.currentUserId,
          request_task_id: this.taskForm.request_task_id,
        };
        this.addPreloader();
        const { success } = await CreativeRequestService.createTaskInCreativeRequest(params);
        if (success) {
          this.showToast(
            'success',
            'top-right',
            'Task created successfully!',
            'CheckIcon',
            `A task for ${this.creativeRequestName} has been created`,
          );
          this.taskForm = {
            title: '',
            assignee: null,
            pieces_quantity: null,
            variants_quantity: null,
            status_id: 1,
            due_date: null,
            details: null,
          };
          this.ownControl = false;
          this.$emit('taskCreated');
        }
      } catch (err) {
        console.error('Error in createTaskInCreativeRequest', err);
      } finally {
        this.removePreloader();
      }
    },
    close() {
      this.$emit('close');
    },
    statusNameTasks(id) {
      switch (id) {
        case 1:
          return 'PENDING';
        case 2:
          return 'IN PROCESS';
        case 3:
          return 'FINISHED';
      }
    },
    editStatus() {
      this.editStatusTasks = true;
    },
    statusVariant(status) {
      switch (status) {
        case 'PENDING':
          return 'light-warning';
        case 'IN PROCESS':
          return 'light-primary';
        case 'FINISHED':
          return 'light-success';
      }
    },
    async getParagonDesignerUsers() {
      try {
        const { data } = await CreativeRequestService.getParagonDesignerUsers();
        this.optionsAssignee = data;
      } catch (err) {
        console.error('Error in getParagonDesignerUsers', err);
      }
    },
    disabledDates(date) {
      return (this.isDateLessThanToday(date));
    },
    isDateLessThanToday(dateInput) {
      const inputDate = moment(dateInput, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
      const today = moment().startOf('day');
      return inputDate.isBefore(today);
    },
  },
};
</script>

<style scoped>

    .header-sidebark{
        /* background-color:rgb(191, 183, 183); */
        height:34px;

    }

    /* Style for Nav */

    .nav-sidebar{
        height:34px;
        display: flex;
        align-items: center;
    }

    .nav-sidebar ul {
        list-style-type: none;
        padding: 0;
        margin:0;
        height:100%;
        display: flex;
        align-items: center;

    }
    .nav-sidebar ul li {
        margin: 0;
        padding: 0;
        height:100%;
        display: flex;
        align-items: center;
    }

    .nav-sidebar ul li a {
        text-decoration: none;
        transition: background-color 0.3s;
    }

    .link-subtasks::before {
        content : "/";
        display:inline;
        margin-right: 15px;
    }

    .nav-sidebar ul li a:hover {
        text-decoration: underline;
     }

     .title-nav{
        font-size:12px;
        font-weight:bold;
        height:100%;
        display:flex;
        margin-top:2px;
        align-items:center;

    }

    .btn-dark span{
        color:#f4efef !important;
    }

    .vs__selected-options input{
        display:none !important;
    }

    .link-title{
        display:flex;
        color:rgb(206, 203, 203);
    }

    .link-title:hover{
        text-decoration:underline;
    }

    .label-input{
      width:100%;
      height:100%;
      display:flex;
      align-items:center;
    }

    .b-siderbar-footer{
      margin-top:80px;
    }

    .h-input{
        height:37.6px !important;
    }
    .h-input-title{
        height:40px !important;
        border:transparent;
        font-size:20px;
        color:#172B4D;
        font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;

    }

    .h-input:focus {
      border: 1px solid #40ACED; /* Cambia el color y el grosor del borde cuando el input estÃ¡ enfocado */
      outline: none; /* Elimina el resaltado predeterminado del input en algunos navegadores */
      background-color:transparent;
    }

    .container-file {
      widht:auto;
      height:auto;
      position:relative;
    }
    .btn-task{
      background-color: #f4efef !important;
      color:#3c3c3c!important;
      border-color: #efefef !important;
      padding: 8px 14px;
    }
    .btn-dark{
        background-color:#131518 !important;
        border:none;
    }

    .btn-dark:hover {
        background-color:#131518 !important;
        border:none;
    }

    .btn-light:hover{
      background-color:#efefef !important;
    }

    .btn-task span{
      font-size:12px;
      font-weight:bold;
    }

    .closeUploadFile{
      position:absolute;
      top:0;
      right:0;
      z-index:999999;
      cursor:pointer;
    }

    /* Style for SubTasks */

    .header-container{
        display:flex;
        justify-content:space-between;

    }

    /* Style for SubTasks */

    .table_subtasks-actions button{
        padding: 1px 5px;
        margin:0;
        background-color:rgb(12, 49, 124);
        border:none;
        align-items:center;
        justify-content:center;
    }

    .link-active:hover{
      text-decoration:underline;
      color:rgb(12, 49, 124);
    }

    .link-active-dark:hover{
      text-decoration:underline;
      color:rgb(237, 237, 237);
      font-weight:bold;
    }

</style>

<style lang="scss" scoped>

.due_date_containergf21 input {
  border: 1px solid #fc424a !important;
}

.due_date_containerx213 .vs__dropdown-toggle {
  border: 1px solid #fc424a !important;
}

@mixin pm-style{
  margin:0;
  padding:0;
}

@mixin border-style-white{
  border:1px solid #d8d6de;
  padding-left:8px;
  border-radius:0.357rem;
}

@mixin border-style-black{
  border:1px solid rgba(255, 255, 255, 0.4);;
  padding-left:8px;
  border-radius:0.357rem;
}

@mixin subtitle-style{
  font-size:14px;
  // color:#c8c4bd;
  font-weight: 600;
}

.border_table-white tr {
  border-radius:3px;
  border:1.2px solid #EBECF0;
}

.border_table-dark tr {
  border-radius:3px;
  border:1.2px solid #A6C5E229;
  background-color:#161619;
}

.input-style-white{
  @include border-style-white;
  height:36.88px;
  color:#6e6b7b;
}

.input-style-black{
  @include border-style-black;
  height:36.88px;
  color:#D8D8D8
}

.user-avatar {
  pointer-events: auto !important;
}

.float-buttons {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  display: none;
  text-align: center;
  justify-content: end;
  align-items: start;
}
.user-avatar{
  position:relative;
}

.table td{
  border-top: none;
}

.user-avatar:hover .float-buttons {
  display: flex !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.title{
  @include subtitle-style;
}

.subtitle{
  @include subtitle-style;
}

.title-label{
  @include pm-style;
  font-size:14px;
}

</style>
