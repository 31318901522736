<template>
  <div>
    <b-modal
      v-model="showModal"
      title="Note"
      size="md"
      no-close-on-backdrop
      @hide="closeModal"
    >
      <validation-observer ref="form">
        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="required"
        >
          <b-form-group
            label="Text"
            class="mb-1"
          >
            <b-form-textarea
              v-model="form.text"
              rows="4"
              :state="errors[0] ? false : valid ? true : null"
            />
          </b-form-group>
        </ValidationProvider>

        <b-form-group
          label="Image"
          class="mb-1"
        >
          <b-form-textarea
            v-model="form.image"
            rows="4"
          />
        </b-form-group>

        <b-form-group
          label="Icons"
          class="mb-1"
        >
          <b-form-textarea
            v-model="form.icon"
            rows="4"
          />
        </b-form-group>
      </validation-observer>

      <template #modal-footer>
        <b-button
          variant="info"
          class="rounded"
          @click="saveUpdateNote()"
        >
          {{ titleButton }}
        </b-button>
      </template>

    </b-modal>
  </div>
</template>
<script>
import CreativeDesignService from '@/views/commons/components/creative-requests/services/creative.request.js';
import { mapGetters } from 'vuex';

export default {
  props: {
    materialId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      form: {},
      isCreate: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    titleButton() {
      return this.isCreate ? 'SAVE' : 'UPDATE';
    },
  },
  async created() {
    await this.getNote();
    this.showModal = true;
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async getNote() {
      const params = {
        materialId: this.materialId,
      };
      const { data } = await CreativeDesignService.getNote(params);
      if (data.length > 0) {
        this.form = data[0];
        this.isCreate = false;
      }
    },
    async saveUpdateNote() {
      const resultForm = await this.$refs.form.validate();
      if (resultForm) {
        const confirm = await this.showConfirmSwal();
        if (confirm.value) {
          const typeService = this.isCreate ? 'saveNote' : 'updateNote';
          const params = {
            materialId: this.materialId,
            text: this.form.text,
            image: this.form.image,
            icon: this.form.icon,
            userId: this.currentUser.user_id,
            noteId: this.form.id,
          };
          try {
            this.addPreloader();
            const { data } = await CreativeDesignService[typeService](params);
            if (data.success) {
              this.showToast(
                'success',
                'top-right',
                'Success!',
                'CheckIcon',
                'Successful operation',
              );
              this.$emit('closeModal');
            } else {
              this.showToast(
                'danger',
                'top-right',
                'Failed!',
                'XIcon',
                'Failed operation',
              );
            }
          } catch (error) {
            this.showErrorSwal(error);
          } finally {
            this.removePreloader();
          }
        }
      }
    },
  },
};
</script>
