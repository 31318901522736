<template>
  <div>
    <b-table-simple  sticky-header>
      <b-thead>
        <b-tr>
         <b-th v-if="moduleId == 27 && $route.meta.status !== 'observed'">
          <b-form-checkbox         
             :id="'checkbox_' + localIndexTable"
            :checked="getAllStatusAreApprovedByCreative ? 1 : 0"
            :disabled="getAllStatusAreApprovedByCreative"
            value="1"
            @change="onChangeCheck()"
          ></b-form-checkbox>
          </b-th>
          <b-th
            v-for="(value, index) in listFields"
            :key="'contn' + index"
            class="text-center"
            style="width: 20%"
          >
            <div
              class="text-center"
              :class="value.tdClass"
            >
              {{ value.label }}
            </div>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-show="isBusy === true">
          <b-td colspan="11" class="text-center py-2">
            <div class="text-center text-primary">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </b-td>
        </b-tr>
        <template>
          <template
            v-for="(req) in prueba"
            style="border-bottom:  solid 1px"
          >
            <template v-if="req.materials_array.length !== 0">
              <b-tr
                v-show="isBusy === false"
                v-for="(material, materialIndex) in req.materials_array"
                :key="'material_' + material.design_material_id"
                :class="borderOnLastRow(materialIndex, req.materials_array.length)"
              >
              <b-td v-if="moduleId == 27 && $route.meta.status !== 'observed'">
                  <b-form-checkbox
                  v-if="statusCheckbox"
                  :value="1"
                  :unchecked-value="0"
                  :disabled="material.status_id === 7"
                  v-model="material.status_id === 7 ? 1 : material.selected"
                  @input="changeCheckboxIndividual"
                />
              </b-td>
                <b-td
                  class="p-0"
                >
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="height: 60px; width: 100%; margin-right: auto; margin-left: auto; margin-bottom: 15px; margin-top: 15px;"
                  >
                    <div
                      v-if="material.extension === 'mp4'"
                      class="video_box"
                      style="margin-top: auto; background-color: white; height: 60px; width: 60px; margin-right: auto; margin-left: auto;"
                    >
                      <div
                        v-if="material.has_unseen_messages === 1"
                        style="
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        z-index: 30;
                        position: absolute;
                        right: -8px;
                        top: -8px;
                        "
                      >
                        <tabler-icon
                          icon="MessageCircleIcon"
                          size="16"
                          style="transform: scale(2.0); animation: scaleAnimation 2s infinite alternate;"
                          :badge-classes="['bg-danger', 'badge-style']"
                          class="text-primary"
                        />
                      </div>
                      <div
                        class="video_overlays"
                        @click="editMarkerImg(material.design_material_id,req)"
                      >
                        <img
                          src="/assets/images/media/play.png"
                          style="width: 100%; height: 100%;"
                        >
                      </div>
                      <video
                        :id="`plyr${material.design_material_id}`"
                        class="video-player cursor-pointer"
                        height="60"
                        width="60"
                      >
                        <source
                          :src="`${material.amazon_full_version}`"
                          type="video/mp4"
                        >
                      </video>
                    </div>
                    <div
                      v-else
                      style="margin-top: auto; background-color: white; height: 60px; width: 60px; margin-right: auto; margin-left: auto; position: relative"
                    >
                      <div
                        v-if="material.has_unseen_messages === 1"
                        style="
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        z-index: 30;
                        position: absolute;
                        right: -8px;
                        top: -8px;
                        "
                      >
                        <tabler-icon
                          icon="MessageCircleIcon"
                          size="16"
                          style="transform: scale(1.5); animation: scaleAnimation 2s infinite alternate;"
                          :badge-classes="['bg-danger', 'badge-style']"
                          class="text-primary"
                        />
                      </div>
                      <b-img
                        class="cursor-pointer"
                        style="width: 100%; height: 100%;"
                        fluid
                        @click="editMarkerImg(material.design_material_id, req)"
                        :src="`${material.amazon_preview}`"
                      />
                    </div>
                  </div>
                </b-td>
                <b-td>
                  <div class="text-center" style="margin-bottom: 15px; margin-top: 15px;">{{ material.code }}</div>
                </b-td>
                <b-td>
                  <div class="text-center" style="margin-bottom: 15px;">
                    <div>{{ material.user_name }}</div>
                    <div>{{ material.updated_at | myGlobalWithHour }}</div>
                  </div>
                </b-td>
                <b-td>
                  <div class="d-flex justify-content-center" style="margin-bottom: 15px; margin-top: 15px;">
                    <div
                      style="padding-top: 2px; width: 180px"
                      :class="!material.edit_status ? '' : 'd-none'"
                      class="text-center"
                    >
                      <label
                        :for="'mat-' + material.id + 'creative'"
                        class="cursor-pointer"
                        style="padding-left: 15px"
                        @click="startEditingStatus(material)"
                      >
                        <b-badge
                          :variant="statusVariant(material.status_id)"
                          class="px-1 cursor-pointer text-center"
                          style="padding: 7px 11px 7px 11px"
                        >
                          <span style="font-size: 12px">
                            {{ statusName(material.status_id) }}
                          </span>
                        </b-badge>
                      </label>
                    </div>
                    <v-select
                      v-if="material.edit_status"
                      v-model="material.status_id"
                      :input-id="'mat-' + material.id + 'creative'"
                      label="name"
                      :options="designMaterialStatuses"
                      :reduce="(option) => option.id"
                      class="text-center cursor-pointer select-size-sm"
                      style="width: 220px"
                      :clearable="false"
                      :append-to-body="true"
                      :calculate-position="positionDropdown"
                      @input="changeStatus(material)"
                      @close="stopEditingStatus(material)"
                    >
                      <template v-slot:option="option">
                        <div class="text-center">
                          <b-badge
                            :variant="statusVariant(option.id)"
                            class="px-1 cursor-pointer text-center"
                            style="padding: 7px 11px 7px 11px"
                          >
                            <span style="font-size: 12px">
                              {{ statusName(option.id) }}
                            </span>
                          </b-badge>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </b-td>
                <b-td>
                  <div
                    class="text-center"
                    style="padding-top: 6px; margin-bottom: 15px; margin-top: 15px;"
                  >
                    <div
                      class="d-flex justify-content-center"
                      style="padding-top: 0px"
                    >
                    <b-button
                      v-b-tooltip.hover.v-info
                      v-b-tooltip.hover.bottom
                      variant="info"
                      size="sm"
                      class="mr-1"
                      title="Download Material"
                      @click="downloadMaterial(material)"
                      v-if="!videoExtensions.includes(material.extension)"
                    >
                      <feather-icon icon="DownloadIcon" />
                    </b-button>
                      <!-- <b-button
                        v-b-tooltip.hover.v-warning
                        v-b-tooltip.hover.bottom
                        variant="warning"
                        size="sm"
                        class="mr-1"
                        title="Material Binnacle"
                        @click="openMaterialChat(material)"
                      >
                        <tabler-icon
                          icon="MessageCircleIcon"
                          size="16"
                          :badge-classes="['bg-danger', 'badge-style']"
                          :badge="
                            material.module_id == moduleId &&
                              material.status_view_messages == 0
                          "
                          :class="
                            material.module_id == moduleId &&
                              material.status_view_messages == 0
                              ? 'pulsating-icon'
                              : ''
                          "
                        />
                      </b-button> -->
                      <b-button
                        v-if="isParagonModule"
                        v-b-tooltip.hover.v-danger
                        v-b-tooltip.hover.bottom
                        variant="danger"
                        size="sm"
                        class="mr-1"
                        title="Reupload content"
                        :disabled="material.status_id == 7"
                        @click="clickEvent(material)"
                      >
                        <feather-icon icon="UploadCloudIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.v-primary
                        v-b-tooltip.hover.bottom
                        variant="primary"
                        size="sm"
                        class="mr-1"
                        title="Material Tracking"
                        @click="trackingModal(material)"
                      >
                        <feather-icon icon="ListIcon" />
                      </b-button>
                      <div v-show="false">
                        <input
                          id="inputFile"
                          ref="inputFile"
                          type="file"
                          name="archivosubido"
                          accept="image/png, image/jpeg, image/gif, video/*"
                          @change="uploadFile($event)"
                        >
                      </div>
                    </div>
                  </div>
                </b-td>
                <b-td
                  v-if="materialIndex === renderInNRow(req.materials_array.length)"
                >
                  <div class="text-center font-weight-bolder" style="margin-bottom: 0.4rem">{{ req.name? req.name.toUpperCase() : '' }}</div>
                  <div
                    class="d-flex align-content-center"
                    :style="classForRenderInRow(req.materials_array.length)"
                    style="margin-bottom: 15px"
                  >
                    <b-button
                      variant="info"
                      @click="openModalInstructions(req)"
                      class="d-flex align-content-center"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        size="16"
                      />
                      <div style="padding-left: 4px;">
                        Guide
                      </div>
                    </b-button>
                    <b-button
                      v-if="moduleId === 12 && !isFinishedTab && req.materials_array.length > 0"
                      variant="primary"
                      style="margin-left: 2px;"
                      class="d-flex align-content-center"
                      @click="openFilesUploadModal(req)"
                    >
                      <tabler-icon
                        icon="FileUploadIcon"
                        size="16"
                      />
                      <div style="padding-left: 2px;">
                        Upload
                      </div>
                    </b-button>
                  </div>
                </b-td>
                <b-td style="width: 1px; height: 1px; margin: 0; padding: 0" v-else />
              </b-tr>
            </template>
            <b-tr
              v-else
              v-show="isBusy === false"
              :class="borderOnNoMaterials()"
            >
              <b-td :colspan="moduleId == 27? 6 : 5">
                <b-card
                  class="shadow-none"
                  :class="isCreativeModule || isFinishedTab? '': 'cursor-pointer'"
                  :style="
                    !isDarkSkin
                      ? 'background-color: #d6eaf8'
                      : 'background-color: #3E3E3F'
                  "
                  @click="openFilesUploadModal(req)"
                >
                  <b-container
                    class="d-flex justify-content-center align-items-center"
                    style="height: 150px"
                  >
                    <div
                      class="text-center"
                      style="color: #2980b9"
                    >
                      <tabler-icon
                        icon="FileUploadIcon"
                        size="40"
                        class="mr-1 mb-2"
                      />
                      <br>
                      <span
                        style="font-size: 25px"
                      > {{ isParagonModule && !isFinishedTab ? 'Please upload files' : 'No files uploaded' }}</span>
                      <span v-if="isParagonModule && !isFinishedTab" class="d-block">You must upload at least one</span>
                    </div>
                  </b-container>
                </b-card>
              </b-td>
              <b-td>
                <div class="buttons-container">
                  <div class="text-center font-weight-bolder" style="margin-bottom: 0.4rem">{{ req.name? req.name.toUpperCase() : '' }}</div>
                  <b-button
                      variant="info"
                      @click="openModalInstructions(req)"
                      class="d-flex align-content-center"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        size="16"
                      />
                      <div style="padding-left: 4px;">
                        Guide
                      </div>
                    </b-button>
                    <b-button
                      v-if="moduleId === 12 && !isFinishedTab && req.materials_array.length > 0"
                      variant="primary"
                      style="margin-left: 2px;"
                      class="d-flex align-content-center"
                      @click="openFilesUploadModal(req)"
                    >
                      <tabler-icon
                        icon="FileUploadIcon"
                        size="16"
                      />
                      <div style="padding-left: 2px;">
                        Upload
                      </div>
                    </b-button>
                </div>
              </b-td>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
    <upload-files-modal
      v-if="showUploadFilesModal"
      :request-id="childRequestId"
      :content-type-id="contentTypeId"
      :detail-id="detailId"
      @refreshTable="refreshTable()"
      @closeModal="showUploadFilesModal = false"
    />

    <request-modal
      v-if="statusModalRequestModal"
      :type-modal="typeModal"
      :data-request="dataRequestSelected"
      @closeModal="closeModalInstructions"
    />

    <marker-image-request
    v-if="showMarkerImage"
    :sendIdMaterial="idMaterial"
    :sendDataRequest="arrMaterialAnotation"
    :data-request="dataMaterialSelected"
    @close="closeMarkerImageRequest"
    />
    <material-chat
      v-if="materialChatOpen"
      :material-data="materialData"
      @close="closeMaterialChat"
    />
    <tracking-material-modal
      v-if="showTracking"
      :material-id="materialId"
      @closeModal="showTracking = false"
    />
    <creative-desing-flyer-modal
      v-if="statusRequest"
      :program-id-item="programIdItem"
      :preview-prop="amazonPathFullVersion"
      :full-version-prop="preview"
      :code-prop="codeRequest"
      :extension-prop="extensionRequest"
      @succesful="saveStatus()"
      @close="closeCreativeDesingModal"
    />
  </div>
</template>
<script>
import CreativeRequestService from "@/views/commons/components/creative-requests/services/creative.request";
import TrackingMaterialModal from "@/views/commons/components/creative-requests/modals/TrackingMaterialModal.vue";
import MarkerImageRequest from "@/views/commons/components/creative-requests/modals/MarkerImageRequest.vue";
import MaterialChat from "@/views/commons/components/creative-requests/components/MaterialChat.vue";
import NotesModal from "@/views/commons/components/creative-requests/modals/NotesModal.vue";
import CreativeDesingFlyerModal from "@/views/commons/components/creative-requests/components/creativeDesingFlyerModal.vue";
import UploadFilesModal from "@/views/commons/components/creative-requests/modals/UploadFilesModal.vue";
import creativeGridFields from "@/views/commons/components/creative-requests/data/creativeFileModalGrid";
import RequestModal from '@/views/creative/views/creative-requests/components/modals/RequestModal.vue';
import { v4 as uuidv4 } from 'uuid'
 
import { mapGetters, mapMutations,mapActions } from "vuex";
 
export default {
  components: {
    TrackingMaterialModal,
    MarkerImageRequest,
    CreativeDesingFlyerModal,
    MaterialChat,
    NotesModal,
    UploadFilesModal,
    creativeGridFields,
    UploadFilesModal,
    RequestModal,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    indexTable: {
      type: Number,
      default: 0,
      required: false,
    },
    dataRequest: {
      type: Array,
      default: () => [],
    },
    propValue:{
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
      designRequestChosen: "CreativeDesign/G_DESIGN_REQUEST_CHOSEN",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isCreativeModule() {
      return this.moduleId === 27;
    },
    isParagonModule() {
      return this.moduleId === 12;
    },
    isFinishedTab() {
      return this.$route.meta.status == "finished";
    },
  getAllStatusAreApprovedByCreative() {
    return this.prueba.flatMap(item => item.materials_array).every(material => material.status_id === 7);
  },
  },
  async created() {
    setTimeout(() => {
      this.isBusy = false;
    }, 1000);
    // this.dataList.forEach(content => {
    //   content.materials_array.forEach(material => {
    //     material.old_status_id = material.status_id;
    //     material.selected = 0;
    //   });
    // });
    await Promise.all([
      this.getDesignMaterialStatuses(),
      // this.getDesignMaterialsByRequest(this.designRequestChosen.parent_request_id),
      this.getParagonDesigners(),
    ]);
    this.setPlayers();


  },
  mounted() {
   this.dataList.forEach(content => {
      content.materials_array.forEach(material => {
        material.old_status_id = material.status_id;
        material.selected = 0;
      });
    });
    this.prueba = this.dataList;
  },
  data() {
    return {
      designMaterials: [],
      designRequestId: null,
      prgramIdItem: null,
      currentRequestTask: {
        title: "",
        assignee: null,
        pieces_quantity: null,
        variants_quantity: null,
        status_id: 1,
        due_date: null,
        details: null,
        request_task_id: null,
        document_name: null,
      },
      form: {},
      localIndexTable: this.indexTable,
      prueba: [],
      statusChange: null,
      statusRequest: false,
      materialChatOpen: false,
      amazonPathFullVersion: null,
      preview: null,
      showTracking: false,
      showMarkerImage:false,
      programIdItem:null,
      listFields: creativeGridFields,
      revisionModalIsOpened: false,
      selectDataReques: null,
      requestsList: [],
      statusList: [],
      showUploadFilesModal: false,
      childRequestId: null,
      detailId: null,
      contentTypeId: null,
      statusModalRequestModal: false,
      typeModal: 'VIEW',
      dataRequestSelected: null,
      dataMaterialSelected: null,
      itemSelected: {},
      materialApproved: null,
      materialSelected: {},
      arrMaterialAnotation:[],
      isBusy: true,
      designMaterialStatuses: [],
      statusAvailable: false,
      videoExtensions: ['mp4', 'avi', 'mov', 'mkv', 'wmv', 'flv', 'webm', 'avchd','f4v','swf','html5','mpeg-2'],
      wasSaved: false,
      statusCheckbox: true,
    };
  },
  methods: {
    ...mapActions({
        UPDATE_MATERIAL_DATA: 'CreativeDesign/A_REFRESH_MATERIAL_DATA'
    }),
    getMaterialAnotacionRequest(){
      this.arrMaterialAnotation = [];

      this.dataRequest.forEach(element => {
        element.dataTable.forEach(item => {
          item.materials_array.forEach(material => {
            this.arrMaterialAnotation.push({
            "id": material.design_material_id,
            "amazon_full_version": material.amazon_full_version,
            "extension": material.extension,
            "version": material.version,
            "status_id": material.status_id,
            "arr_anotations": material.annotations ? material.annotations : [],
            "requestId": material.requestId,
            "content_type_id":item.content_type_id,
            "detail_id":item.detail_id,
            "has_unseen_messages":material.has_unseen_messages
          });
          });
        });
      });

      
      this.UPDATE_MATERIAL_DATA(this.arrMaterialAnotation);
      
    },
    onChangeCheck() {
      const updatedPrueba = this.prueba.map(item => {
        const updatedMaterials = item.materials_array.map(material => {
          const newSelected = material.selected === 1 ? 0 : 1;
          return { ...material, selected: newSelected };
        });
        return { ...item, materials_array: updatedMaterials };
      });
      this.prueba = updatedPrueba;
    },
    changeCheckboxIndividual(){
        this.statusCheckbox = false;
       setTimeout(() => {
         this.statusCheckbox = true;
        }, 100);
    },

    async getDesignMaterialStatuses() {
      try {
        const { data } =
          await CreativeRequestService.getDesignMaterialStatuses();
        this.statusList = data;
        if (this.currentUser.modul_id == 27) {
          this.designMaterialStatuses = data.filter((item) =>
            [5, 6, 7].includes(item.id)
          );
        } else if (this.currentUser.modul_id == 12) {
          if (
            (this.$route.meta.status == "in process" ||
              this.$route.meta.status == "observed") &&
            this.currentUser.role_id != 26
          ) {
            this.designMaterialStatuses = data.filter((item) =>
              [2].includes(item.id)
            );
          } else {
            this.designMaterialStatuses = data.filter((item) =>
              [2, 3, 4].includes(item.id)
            );
          }
        } else {
          this.designMaterialStatuses = data;
        }
        this.statusAvailable = true;
      } catch (err) {
        console.error("Error in getDesignMaterialStatuses", err);
      }
    },
    startEditingStatus(material) {
      if (this.isOnlyBadgeToThisStatus(material)) {
        this.$set(material, "edit_status", true);
      }
    },
    borderOnLastRow(nRow, numberOfRows) {
      if (nRow === numberOfRows - 1) {
        return this.isDarkSkin ? 'border-limiter-for-dark' : 'border-limiter-for-light';
      }
      return "";
    },
    isOnlyBadgeToThisStatus(material) {
      let tabStatus = this.$route.meta.status;
      if (
        this.currentUser.modul_id == 12 &&
        (tabStatus == "finished" || tabStatus == "approved" ||
          (tabStatus == "observed" && material.status_id == 7) ||
          (tabStatus == "in process" && material.status_id == 4))
      ) {
        return false;
      } else if (
        this.currentUser.modul_id == 27 &&
        (tabStatus == "in process" ||
          tabStatus == "observed" ||
          tabStatus == "approved" ||
          (tabStatus == "finished" && material.status_id == 7))
      ) {
        return false;
      }
      return true;
    },
    stopEditingStatus(material) {
      this.$set(material, "edit_status", false);
    },
    setStatusEdition(req) {
      req = true;
    },
    statusVariant(statusId) {
      switch (statusId) {
        case 1:
          return "warning";
        case 2:
          return "primary";
        case 3:
          return "secondary";
        case 4:
          return "info";
        case 5:
          return "dark";
        case 6:
          return "danger";
        case 7:
          return "success";
      }
    },
    statusName(statusId) {
      if (this.statusAvailable) {
        if (statusId == null) {
          return this.currentUser.modul_id == 12
            ? "Pending"
            : "Approved by Paragon";
        }
        return this.statusList.find((status) => status.id === statusId).name;
      }
    },
    clickEvent(material) {
      this.materialSelected = material;
      document.getElementById("inputFile").click();
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    async uploadFile(event) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        const file = event.target.files[0];
        const filebase64 = await this.readFileAsync(file);
        this.form.file = filebase64;
        this.form.design_material_id = this.materialSelected.design_material_id;
        this.form.program_name = this.designRequestChosen.program;
        this.form.creative_design_request_id = this.materialSelected.requestId;
        this.form.design_material_code = this.materialSelected.code;
        this.form.user_id = this.currentUser.user_id;
 
        try {
          this.addPreloader();
          if (file.type.includes('video')) {
            await this.uploadVideoByChunks(file);
          } else {
            const { data } = await CreativeRequestService.reuploadFile(this.form);
            if (data.success) {
              this.$emit('reloadTable')
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else {
              this.showToast(
                "danger",
                "top-right",
                "Failed!",
                "XIcon",
                "Failed operation"
              );
            }
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    openFilesUploadModal(detailData) {
      if (this.isCreativeModule || this.isFinishedTab) return;
      this.showUploadFilesModal = true;
      this.childRequestId = detailData.requestId;
      this.detailId = detailData.detail_id;
      this.contentTypeId = detailData.content_type_id;
    },
    closeFilesUploadModal() {
      this.showUploadFilesModal = false;
      this.childRequestId = null;
      this.detailId = null;
      this.contentTypeId = null;
    },
 
    closeModal() {
      this.control = false;
      this.$emit("close");
    },
    trackingModal(item) {
      this.materialId = item.design_material_id;
      this.showTracking = true;
    },
    // delay(ms) { // delay para esperar a que se cierre el v-select
    //   return new Promise(resolve => setTimeout(resolve, ms));
    // },
    async changeStatus(item) {
      this.statusChange = item.status_id;
      this.itemSelected = item;
      await this.delay(100);
      this.newStatusId = item.status_id;
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        if (item.status_id == 7) {
          this.statusCpy = item.status_id;
          this.programIdItem = this.designRequestChosen.program_id;
          this.amazonPathFullVersion = item.full_version;
          // this.fullVersion = item.full_version;
          this.preview = item.preview;
          this.materialApproved = item;
          this.extensionRequest = item.extension;
          this.codeRequest = item.code;
          this.statusRequest = true;
        } else {
          this.statusChange = item.old_status_id;
          await this.saveStatus();
        }
      } else {
        item.status_id = item.old_status_id;
        return;
      }
      item.isEditingStatus = null;
    },
    delay(ms) { //delay para esperar a que se cierre el v-select
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async saveStatus() {
      this.statusRequest = false;
      const params = {
        statusId: this.newStatusId, // this.itemSelected.status_id
        materialId: this.itemSelected.design_material_id,
        userId: this.currentUser.user_id,
        requestId: this.designRequestChosen.parent_request_id,
      };
      try {
        const { data } = await CreativeRequestService.changeMaterialStatus(
          params
        );
        if (data.success) {
          if (this.newStatusId == 7) {
            await this.downloadMaterial(this.materialApproved);
          }
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Failed!",
            "XIcon",
            "Failed operation"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    editMarkerImg(materialId,req) {
      this.idMaterial = materialId;
      this.dataMaterialSelected = {
        "content_type_id": req.content_type_id,
        "detail_id":req.detail_id
      };

      this.getMaterialAnotacionRequest();

      this.showMarkerImage = true;
 
 
    },
    async getParagonDesigners() {
      try {
        const { data } = await CreativeRequestService.getParagonDesigners();
        this.designers = data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async openMaterialChat(item) {
      const params = {
        moduleId: this.moduleId,
        designMaterialId: item.id,
      };
      const data = await CreativeRequestService.seeMessagesChatMaterial(params);
      if (data.status === 200) {
        item.status_view_messages = 1;
      }
      this.materialChatOpen = true;
      this.materialData = item;
    },
    refreshTable() {
      this.$emit("reloadTable");
    },
    closeCreativeDesingModal() {
      let material_id = this.itemSelected.design_material_id;
      this.prueba.forEach(obj => {
        obj.materials_array.forEach(material => {
          if (material.design_material_id === material_id) {
            material.status_id = null;
          }
        });
      });
      this.statusRequest = false;
    },
    closeMaterialChat() {
      this.materialChatOpen = false;
    },
    styleBadge(status) {
      switch (status) {
        case 1: // Pending
          return "#82868b !important";
        case 2: // Completed by Designer
          return "#0090e7 !important";
        case 3: // Remarked by Paragon
          return "#ff9f43 !important";
        case 4: // Approved by Paragon
          return "#6dff83 !important";
        case 5: // Remarked by Creative
          return "#ff9f43 !important";
        case 6: // Reformulate
          return "#fc424a !important";
        case 7: // Approved by Creative
          return "#00d25b !important";
        default:
          return "#ff9f43 !important";
      }
    },
    positionDropdown(dropdownList, component, { width, top, left }) {
      dropdownList.style.zIndex = 9999;
      dropdownList.style.maxHeight = "20rem";
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    async downloadMaterial(material) {
      try {
        this.addPreloader();
        if (material.full_version && material.extension == "mp4") {
          window.open(material.full_version, "_blank");
        } else {
          const params = {
            materialId: material.design_material_id,
          };
          const data = await CreativeRequestService.downloadMaterial(params);
          this.forceFileDownload(data.data, material.file_name);
        }
 
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    setPlayers() {
      /* Get all the video players */
      this.players = document.querySelectorAll(".video-player");
      /* These piece of code detect when a video exit the fullscreen mode and pause the video and remove the controls */
      this.players.forEach((player) => {
        player.addEventListener("fullscreenchange", () => {
          const isFullscreen =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement;
          if (!isFullscreen) {
            player.pause();
            player.load();
            player.controls = false;
          }
        });
      });
 
      /* Go directly to fullscreen mode and play the video mode when is clicked */
      this.players.forEach((player) => {
        player.addEventListener("click", () => {
          player.requestFullscreen().then(() => {
            player.play();
            player.controls = true;
          });
        });
      });
    },
    simulateClickOnVideo(materialId) {
      const player = document.getElementById(`plyr${materialId}`);
      player.click();
    },
    async setAsFinished() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
 
      this.addPreloader();
      const params = {
        status: 3, // finished
        requestId: this.requestId,
        userId: this.currentUser.user_id,
         "columnChanged": 'STATUS',
      };
      try {
        const { data } =
          await CreativeRequestService.changeRequestStatusDuedate(params);
        if (data.success) {
          this.showSuccessSwal();
          this.$emit("reloadTable");
          this.$emit("close");
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openModalInstructions(data){
      this.statusModalRequestModal = true;
      this.dataRequestSelected = data;
    },
    closeModalInstructions(){
      this.statusModalRequestModal = false;
    },
    allStatusAsigned() {
      const allMaterialsArray = this.prueba.flatMap(item => item.materials_array);
      const tabStatus = this.$route.meta.status;
      let disableButton = false;
      if (tabStatus == 'in process') {
        disableButton = allMaterialsArray.some(
          design => design.status_id != 4 || design.status_id == null,
        );
      } else if (tabStatus == 'observed') {
        disableButton = allMaterialsArray.some(
          design => design.status_id != 4 && design.status_id != 7,
        );
      } else if (tabStatus == 'finished') {
        disableButton = allMaterialsArray.some(
          design => design.status_id == null,
        );
      }
      return disableButton;
    },
    atLeastOneByPiece() {
      const oneEmpty = this.prueba.some(item => item.materials_array.length === 0);
      return oneEmpty;
    },
    allMaterialsArrayFn() {
      return this.prueba.flatMap(item => item.materials_array);
    },
    renderInNRow(arrayLength) {
      if (arrayLength % 2 !== 0) {
        return ((arrayLength + 1) / 2) - 1;
      }
      return (arrayLength / 2) - 1;
    },
    classForRenderInRow(arrayLength) {
      if (arrayLength % 2 !== 0) {
        return '';
      }
      return ''; //'margin-top: 70px;';
    },
    borderOnNoMaterials() {
      return this.isDarkSkin ? 'border-limiter-for-dark' : 'border-limiter-for-light';
    },
    closeMarkerImageRequest() {
      this.showMarkerImage = false;
      this.$emit('reloadTable');
    },
    allStatusAreApprovedByCreative() {
      return this.prueba.flatMap(item => item.materials_array).every(material => material.status_id === 7);
    },
    async uploadVideoByChunks(fileVideo) {
      try {
        let fileChunks = [];
        let file = fileVideo;
        let size = fileVideo.size;
        let chunkSize = 10000000;
        let offset = 0;
        while (offset < size) {
            let chunk = file.slice(offset, offset + chunkSize);
            fileChunks.push(chunk);
            offset+=chunkSize;
        }

        let fileName = file.name;
        let fileParts = fileName.split(".");
        let fileExtension = fileParts[fileParts.length-1];

        fileName = `${uuidv4()}.${fileExtension}`;

        let url = null;
        let statusResponse = 200;
        for (let i = 0; i < fileChunks.length; i += 1) {
          let isStart = i === 0;
          let isEnd = i === fileChunks.length - 1;
          let formData = new FormData();
          formData.append('file', fileChunks[i]);          
          formData.append('design_material_id', this.materialSelected.design_material_id);
          formData.append('program_name', this.designRequestChosen.program);
          formData.append('creative_design_request_id', this.materialSelected.requestId);
          formData.append('design_material_code', this.materialSelected.code);
          formData.append('user_id', this.currentUser.user_id);
          formData.append('name', fileName);
          formData.append('end', JSON.stringify(isEnd));
          formData.append('start', JSON.stringify(isStart));
          formData.append('extension', fileExtension);
          let data = await CreativeRequestService.uploadVideoByChunks(formData);
          url = data.url ?? null;
        }
        this.$emit('reloadTable');
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
        return { url, fileName, statusResponse };

      } catch (error) {
      throw error;
      }
    },
  },
  watch: {
    dataList(){
        this.dataList.forEach(content => {
        content.materials_array.forEach(material => {
          material.old_status_id = material.status_id;
          this.$set(material, 'selected', 0);
        });
      });

      this.prueba = this.dataList;     
      const checkboxId = 'checkbox_' + this.localIndexTable;
      const checkbox = document.getElementById(checkboxId); 

      if (checkbox) {
        checkbox.checked = false;
      }
   },
  }
};
</script>
<style scoped>
td {
  border: none !important;
}
.unselectable-text {
  user-select: none;
}
 
.nested-enter-active,
.nested-leave-active {
  transition: all 1.3s ease-in-out;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.25s;
}
 
.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
 
/* we can also transition nested elements using nested selectors */
.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}
/* delay enter of nested element */
.nested-enter-active .inner {
  transition-delay: 1.25s;
}
 
.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
.table_request tbody tr td div {
  padding-top: 10px;
}
</style>
<style scoped>
.border-limiter-for-dark {
  border-bottom: 1px solid #313133 !important;
}
 
.border-limiter-for-light {
  border-bottom: 1px solid rgb(221, 221, 223) !important;
}
 
#flat_picker_due_date_for_task input {
  border: 0px;
  padding-right: 0px;
}
 
#flat_picker_due_date_for_task .flatpickr-calendar {
  position: absolute !important;
  display: block !important;
  left: 810px !important;
}
 
#flat_picker_due_date_for_parent input {
  border: 0px;
  padding-right: 0px;
}
 
#flat_picker_due_date_for_parent .flatpickr-calendar {
  position: absolute !important;
  display: block !important;
  left: 810px !important;
}
</style>
<style scoped>
#flat_picker_due_date_parent input {
  border: 0px;
  padding-right: 0px;
}
 
#flat_picker_start_date_parent input {
  border: 0px;
  padding-right: 0px;
}
 
#flat_picker_start_date_parent .flatpickr-calendar {
  position: absolute !important;
  display: block !important;
  left: 810px !important;
}
 
#flat_picker_due_date_parent .flatpickr-calendar {
  position: absolute !important;
  display: block !important;
  left: 810px !important;
}
 
.pulsating-icon {
  animation: pulse 0.4s infinite alternate;
}
.pulsating-iconblue {
  animation: pulseblue 0.4s infinite alternate;
}
@keyframes pulse {
  from {
    color: #3c96f1;
  }
  to {
    color: rgb(109, 206, 231);
  }
}
@keyframes pulseblue {
  from {
    color: #3c96f1;
  }
  to {
    color: rgb(109, 206, 231);
  }
}
 
@keyframes scaleBadgeAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleAnimation {
  from {
    transform: scale(1.5);
  }
  to {
    transform: scale(2.0);
  }
}

.scaling-div {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  z-index: 30;
  position: absolute;
  right: -8px;
  top: -8px;
  transition: transform 0.5s ease; /* Add a transition for smooth scaling */
}
 
.my-animated-button {
  animation: scaleBadgeAnimation 1.5s infinite ease-in-out;
}
 
.my-animated-button:hover {
  animation-play-state: paused;
}
.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>