export default [
    {
      type: 'datepicker',
      margin: true,
      showLabel: true,
      label: 'FROM (CREATED)',
      placeholder: 'Date',
      class: 'font-small-3',
      model: null,
      locale: 'en',
      dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
      cols: 6,
      visible: true,
    },
    {
      type: 'datepicker',
      margin: true,
      showLabel: true,
      label: 'TO (CREATED)',
      placeholder: 'Date',
      class: 'font-small-3',
      model: null,
      locale: 'en',
      dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
      cols: 6,
      visible: true,
    },
    {
        type: 'datepicker',
        margin: true,
        showLabel: true,
        label: 'FROM (DUE DATE)',
        placeholder: 'Date',
        class: 'font-small-3',
        model: null,
        locale: 'en',
        dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
        cols: 6,
        visible: true,
      },
      {
        type: 'datepicker',
        margin: true,
        showLabel: true,
        label: 'TO (DUE DATE)',
        placeholder: 'Date',
        class: 'font-small-3',
        model: null,
        locale: 'en',
        dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
        cols: 6,
        visible: true,
      },
    // {
    //   type: 'select',
    //   margin: true,
    //   showLabel: true,
    //   label: 'STATUS',
    //   model: null,
    //   options: [
    //     { id: 0, description: 'Inactive'},
    //     { id: 1, description: 'Active'}
    //   ],
    //   reduce: 'id',
    //   selectText: 'description',
    //   cols: 12,
    // },
    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'PROGRAM',
        model: null,
        options: [],
        reduce: 'id',
        selectText: 'value',
        cols: 12,
        visible: true,
      },
    {
      type: 'select',
      margin: true,
      showLabel: true,
      label: 'DESIGNER',
      model: null,
      options: [],
      reduce: 'user_id',
      selectText: 'user_name',
      cols: 12,
      visible: true,
    },
  ]
  