<template>
  <div>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'sn-s-source-list' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
      >
        SOURCES
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'sn-s-source-contact-methods',
        }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
      >
        CONTACT METHODS
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-info border-3 border-table-radius px-0">
      <router-view ref="routerView" :key="this.$route.name" />
    </b-card>
  </div>
</template>

<script>
import SourceTable from "@/views/social-network/views/settings/components/tab-source/SourceTable.vue";

export default {
  components: {
    SourceTable,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    createSource() {
      const item = {
        created_at: null,
        created_by: null,
        icon_name: null,
        icon_url: null,
        id: null,
        initial: null,
        name: null,
        source_detail: null,
        status: null,
      };
      this.$refs.routerView.showSourceModal(item);
    },
    createContactMethod() {
      const item = {
        created_at: null,
        created_by: null,
        description: null,
        id: null,
        name: null,
        parent_id: null,
        slug: null,
        status: null,
        updated_at: null,
        updated_by: null,
      };

      this.$refs.routerView.showContactMethodModal(item);
    },
  },
};
</script>