<template>
  <div>
    <portal to="header-actions">
      <b-button variant="success" @click="openEditMotiveModalOn">
        <feather-icon icon="PlusIcon" style="margin-right: 0.5rem" /> CREATE
      </b-button>
    </portal>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['settingsTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="settingsTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="motive_id"
          responsive
          :items="getMotivesLossProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(desc_type_motive)="data">
            <b-badge
              class="text-center w-100"
              pill
              :variant="colorStatusNote(data.item.type_id)"
            >
              <span>{{ data.item.desc_type_motive }}</span>
            </b-badge>
          </template>
          <template #cell(created_at)="data">
            <span
              >{{ data.item.created_by }} <br />
              {{ data.item.created_at | myGlobalDay }}</span
            >
          </template>
          <template #cell(actions)="data">
            <feather-icon
              v-if="data.item.motive_id !== 3"
              class="mr-1 cursor-pointer text-warning"
              icon="EditIcon"
              size="17"
              @click="openEditMotiveModal(data.item)"
            />
            <feather-icon
              v-if="data.item.motive_id !== 3"
              class="mr-1 cursor-pointer text-danger"
              icon="TrashIcon"
              size="17"
              @click="deleteMotive(data.item.motive_id)"
            />
          </template>
        </b-table>
        <create-edit-motive-modal
          v-if="createEditMotiveModalOn"
          :mood="mood"
          :is-from-reply="isFromReply"
          :motive-data="motiveToSend"
          @close="closeEditMotiveModalOn"
          @reload="$refs['settingsTable'].refresh()"
        />
      </template>
    </filter-slot>
  </div>
</template>

<script>
import SettingsService from "@/views/social-network/views/settings/settings.service";
import CreateEditMotiveModal from "@/views/social-network/views/settings/modals/CreateEditMotiveModal.vue";
import SettingsFilter from "@/views/social-network/views/settings/settings.filter";
export default {
  components: {
    CreateEditMotiveModal,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: SettingsFilter,
      fields: [
        {
          key: "desc_motive",
          label: "Description",
        },
        {
          key: "desc_type_motive",
          label: "Type Reason",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "created_at",
          label: "Created By",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: {
            width: "150px",
          },
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      searchInput: "",
      items: [],
      createEditMotiveModalOn: false,
      motiveToSend: {},
      mood: 0,
      motiveLossTypes: [],
      isFromReply: false,
    };
  },
  async created() {
    await this.getAllMotiveLossTypes();
    this.filter[2].options = this.motiveLossTypes;
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    openEditMotiveModal(item) {
      this.createEditMotiveModalOn = true;
      this.isFromReply = false;
      this.motiveToSend = item;
      this.mood = 2;
    },
    async getAllMotiveLossTypes() {
      try {
        const data = await SettingsService.getMotiveLossTypesByModule({
          module_id: this.moduleId,
        });
        if (data.status === 200) {
          this.motiveLossTypes = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async deleteMotive(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          this.addPreloader();
          const data = await SettingsService.deleteMotiveLoss({
            motive_id: id,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            this.$refs.settingsTable.refresh();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    closeEditMotiveModalOn() {
      this.createEditMotiveModalOn = false;
    },
    openEditMotiveModalOn() {
      this.createEditMotiveModalOn = true;
      this.isFromReply = false;
      this.mood = 1;
      this.motiveToSend = {};
    },
    colorStatusNote(status) {
      switch (status) {
        case 1:
          return "light-success";
        case 2:
          return "light-info";
        case 3:
          return "light-warning";
        case 4:
          return "light-primary";
        case 6:
          return "light-danger";
        default:
          return "light-secondary";
      }
    },
    async getMotivesLossProvider(ctx) {
      try {
        const data = await SettingsService.getMotivesLossSn({
          per_page: ctx.perPage,
          orderby: 1,
          page: ctx.currentPage,
          order: ctx.sortDesc === true ? "desc" : "asc",
          campo: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          type_id: this.filter[2].model,
          module_id: this.moduleId,
        });
        this.items = data.data;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        return this.items || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style scoped>
</style>
