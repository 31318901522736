export default [
    {
        key: "material",
        label: "CODE",        
        visible: false,
    },
    {
        key: "program",
        label: "program",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "document_name",
        label: "DOCUMENT NAME",
        tdClass: null,
        thClass: null,
        visible: true,
    },
    {
        key: "content_type",
        label: "CONTENT TYPE",
        tdClass: "text-center",
        thClass: "text-center",
        visible: false,
    },
    {
        key: "pieces",
        label: "#PIECES",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "variants",
        label: "#VARIANTS",
        tdClass: "text-center",
        thClass: "text-center",
        visible: false,
    },
    {
      key: "designeer",
      label: "DESIGNER",
      tdClass: "text-center",
      thClass: "text-center",
      visible: true,
    },

    {
        key: "due_date",
        label: "DEADLINE",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "status",
        label: "status",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "created_by",
        label: "CREATED BY",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
    {
        key: "actions",
        label: "Actions",
        tdClass: "text-center",
        thClass: "text-center",
        visible: true,
    },
]