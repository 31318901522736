<template>
    <b-modal
      hide-footer
      size="xsm"
      @hidden="$emit('hidden')"
      ref="modal-tracking-user"
      :title="`${dataUser.user_name.toUpperCase()} TRACKING ` "
      body-class="p-0"
      scrollable
    >
      <b-table
        ref="trackingUserTable"
        :items="myProvider"
        :fields="fields"
        show-empty
        empty-text="There are no records to show"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(created_at)="data">
          <span>{{ data.item.created_at | myGlobalDay }}</span>
        </template>

      </b-table>
    </b-modal>
  </template>
  
  <script>
   import SettingsService from "@/views/social-network/views/settings/settings.service";
   import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
  
  export default {
    props: {
      dataUser: {
        type: Object,
      },
    },
    mounted() {
      this.toggleModal("modal-tracking-user");
    },
    components: {
      ChangeSms,
    },
    data() {
      return {
        fields: [
          {
            key: "name_rol",
            label: "role",
          },
          {
            key: "created_at",
            label: "updated at",
            tdClass: "text-center",
            thClass: "text-center",
          },
          {
            key: "full_name",
            label: "updated by",
            tdClass: "text-center",
            thClass: "text-center",
          },       
        ],
        isBusy: false,
      };
    },
    methods: {

      async myProvider() {
        try {
          const params = {
            id: this.dataUser.user_id,
          };
          const resul = await SettingsService.getTrackingUserTeamRank(
            params
          );
          return resul.data || [];
        } catch (error) {
          this.showErrorSwal(error);
        }
      },

    },
  };
  </script>