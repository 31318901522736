<template>
    <b-sidebar
      :id="isDarkSkin ? 'sidebar-design-material-chat-dark' : 'sidebar-material-chat-light'"
      v-model="onControl"
      header-class="px-2 text-bold"
      width="24.6%"
      no-close-on-backdrop
      shadow
      left
      @hidden="close"
    >
    <div class="h-100">
      <b-overlay :show="showInstruction"  :variant="skin" class="h-100">
        <div
          v-if="viewNumber == 2"
          >
            <div
              v-if="statusIgtv"
              class="px-2"
            >
              <form-igtv-video-reel
                v-if="typeForm == 3 || typeForm == 4 || typeForm == 5"
                :data-request="form"
                :type-modal="typeModal"
              />
            </div>

            <div 
              v-if="statusFlyer"
              class="px-2"
            >
                <form-story-flyer
                  v-if="typeForm == 6 || typeForm == 2 || typeForm == 1"
                  :data-request="form"
                  :type-modal="typeModal"
                />
            </div>

            <div
              v-if="statusCarousel"
              class="px-2"
            >
                <form-carousel
                  v-if="typeForm == 7"
                    :data-request="form"
                    :type-modal="typeModal"
                    @deletePiecesCarousel="deletePiecesCarousel"
                />
            </div>
        </div>
      </b-overlay >

    </div>

    </b-sidebar>
  </template>
  
  <script>

    import { mapActions, mapGetters } from 'vuex';


    import CreativeDesignService from '@/views/creative/views/creative-requests/service/CreativeDesign.service.js';
    import DragAndDrop from '@/views/commons/utilities/DragAndDrop.vue';
    import ContentTypePieces from '@/views/creative/views/creative-requests/components/modals/ContentTypesPieces.vue';
    import FormIgtvVideoReel from '@/views/creative/views/creative-requests/components/modals/FormIgtvVideoReel.vue';
    import FormStoryFlyer from '@/views/creative/views/creative-requests/components/modals/FormStoryFlyer.vue';
    import FormCarousel from '@/views/creative/views/creative-requests/components/modals/FormCarousel.vue';
    import flatPickr from 'vue-flatpickr-component';
    import { v4 as uuidv4 } from 'uuid'

    import FormButtons from '@/views/creative/views/creative-requests/components/modals/FormButtons.vue';
    import FormButtonsType from '@/views/creative/views/creative-requests/components/modals/FormButtonsType.vue';

    export default {

        name: 'SidebarInstruction',
        components: {
            DragAndDrop,
            ContentTypePieces,
            FormIgtvVideoReel,
            FormStoryFlyer,
            FormCarousel,
            flatPickr,
            FormButtons,
            FormButtonsType,
        },
        props: {
            requestMaterial: {
                type: Object,
                required: false,
                default: null,
            },
            typeModal: {
                type: String,
                default: 'VIEW',
                required: false,
            },
            idRequest: {
                type: Number,
                default: 0,
                required: false,
            },
            pieceSelected: {
                type: Number,
                default: 0,
                required: false,
            },
        },
        data() {
        return {
            onControl: false,
            dataRequest:null,
            baseImg: process.env.VUE_APP_BASE_URL_FRONT,
            showModal: false,
            form: {
                program_selected: null,
                request_date: null,
                content_type: null,
                initial: null,
                observation: null,
                pieces_qty: null,
                variants_qty: null,
                idRequest: null,
                content: [
                {
                    pieces_qty: null,
                    content_type: null,
                    parrilla: [],
                },
                ],
                parrilla: [],
                nameContentType: '',
            },
            programs: [],
            materialTypes: [],
            materialTypesCpy: [],
            files: [],
            nameDocument: null,
            viewNumber: 1,
            statusForm: false,
            selectFormId: 0,
            showInstruction:false,
            statusEditPieces: false,
            configDate: {
                allowInvalidPreload: true,
                enableTime: true,
                dateFormat: 'Y-m-d H:i:S',
                altFormat: 'm/d/Y h:i K',
                altInput: true,
                time_24hr: true,
                minDate: 'today',
            },
            arrayFiles: [],
            statusButtonPiece: true,

            statusFlyer: true,
            statusCarousel: true,
            statusIgtv: true,
            materialSelecteds: [],

            formData: [],
            typeForm: 0,
          };
        },
    
        computed: {
            ...mapGetters({
            currentUser: 'auth/currentUser',
            idTypeStore: 'CreativeDesign/G_REFRESH_ID_TYPE_CREATIVE_DESIGN',
            idPiece: 'CreativeDesign/G_REFRESH_ID_PIECE_CREATIVE_DESIGN',
            indexMaterialData:'CreativeDesign/G_ID_MATERIAL_DATA',
            materialData:'CreativeDesign/G_REFRESH_MATERIAL_DATA',
            skin: "appConfig/skin",
            }),
            titleModal() {
            const newOrEdit = this.typeModal == 'NEW' ? 'NEW ' : 'EDIT ';
            const contentToSee = this.viewNumber > 1 ? 'REQUEST (CONTENT GRID)' : 'REQUEST (FORM)';
            const fullTitle = `${newOrEdit}${contentToSee}`;
            return this.isParagonModule ? contentToSee : fullTitle;
            },
            titleButton() {
            return this.typeModal == 'ADD' ? 'SAVE' : 'UPDATE';
            },
            moduleId() {
            return this.$route.matched[0].meta.module;
            },
            disableEditPieces() {
            return this.moduleId == 12 || this.statusEditPieces;
            },
            isParagonModule() {
            return this.moduleId == 12;
            },
            isCreativeDesign() {
            return this.$route.matched[0].meta.module == 27;
            },
            disableEditContentType() {
            return this.isParagonModule || this.typeModal == 'EDIT';
            },
            textTooltipDisableEditPieces() {
            return this.disableEditPieces ? 'If you want to add more pieces, you can do it in the Content Grid option' : '';
            },
            totalParrila() {
            return this.form.parrilla.length;
            }

        },

        watch: {
          pieceSelected(newVal) {
            if (newVal) {
              this.getRequestContentGrid();
            }
          },
          indexMaterialData(newVal){
              this.getMaterialData();
            
          }
        },

        async created() {
            this.getMaterialData();

            try {
            this.showInstruction = true;
            await this.getPrograms();
            await this.getMaterialTypes();
            this.showModal = true;
            if (this.typeModal == 'EDIT') {
                this.statusEditPieces = true;
                await this.getRequest();
                this.nameContentType(this.form.content_type);
                this.showInstruction = false;
            }
            } catch (error) {
                throw error
            } finally {
              this.showInstruction = false;
            }
        },
        async mounted() {
            this.onControl = true;
            if (this.typeModal == 'VIEW') {
                this.getRequestContentGrid();
            }
        },
        methods: {

            getMaterialData(){
                this.dataRequest = {
                "content_type_id" : this.materialData[this.indexMaterialData].content_type_id,
                "detail_id" : this.materialData[this.indexMaterialData].detail_id
              };
            },

            close(){
                this.$emit('closeSidebar');
            },

            ...mapActions({
      UPDATE_ID_TYPE_CREATIVE_DESIGN: 'CreativeDesign/A_REFRESH_ID_TYPE_CREATIVE_DESIGN',
      UPDATE_ID_PIECE_CREATIVE_DESIGN: 'CreativeDesign/A_REFRESH_ID_PIECE_CREATIVE_DESIGN',
      FORM_DESIGN_REQUEST: 'CreativeDesign/A_FORM_DESIGN_REQUEST',
    }),
    async save(isDraft) {
      try {
        // const validateComponentForm = await this.$refs.component_form_flyer.validateForm();
        // if(!validateComponentForm) {
        //   return false;
        // }
        this.form.content.forEach(content => {          
          content.parrilla.forEach(item => {
            let auxFile = item.files.map(filez => {
              let uuid = uuidv4();
              let auxArrayFiles = {
                uuid: uuid,
                file: filez.fileapproved
              }
              this.arrayFiles.push(auxArrayFiles);
              return {
                uuid: uuid,             
              }
            })    
            item.files = auxFile;        
          })
        })

        this.statusForm = await this.$refs.form.validate();
        if (this.statusForm) {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            if (this.statusForm) {
              this.showInstruction = true;
              const params = {
                designRequestId: null,
                programId: this.form.program_selected,
                requestDate: this.form.request_date,
                contentType: this.form.content_type,
                piecesQty: this.form.pieces_qty,
                //variantQty: this.form.variants_qty,
                programInitial: this.form.initial,
                nameDocument: this.nameDocument,
                userId: this.currentUser.user_id,
                observation: this.form.observation,
                parrilla: this.form.parrilla,
                content: this.form.content,
                files: this.arrayFiles,
                isDraft: isDraft
              };
              await CreativeDesignService.saveRequest(params);
              this.showInstruction = false;
              this.showSuccessSwal('Success!', 'Request saved successfully!');
              this.refreshTable();
              this.showModal = false;
            }
          }
        }
      } catch (error) {
        this.showInstruction = false;
        this.showErrorSwal(error);
      }
    },
    async update() {
      try {
        const resultForm = await this.$refs.form.validate();
        if (resultForm) {
          const confirm = await this.showConfirmSwal();
          if (confirm.value) {
            this.showInstruction = true;
            const params = {
              programId: this.form.program_selected,
              requestDate: this.form.request_date,
              contentType: this.form.content_type,
              piecesQty: this.form.pieces_qty,
              variantQty: this.form.variants_qty,
              programInitial: this.form.initial,
              nameDocument: this.nameDocument,
              userId: this.currentUser.user_id,
              observation: this.form.observation,
              idRequest: this.form.idRequest,
              parrilla: this.form.parrilla,
              content: this.form.content,
            };
            await CreativeDesignService.updateRequest(params);
            this.showInstruction = false;
            this.showSuccessSwal('Success!', 'Request saved successfully!');
            this.refreshTable();
            this.showModal = false;
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
        this.showInstruction = false;
      }
    },
    closeModal() {
      this.UPDATE_ID_PIECE_CREATIVE_DESIGN(0);
      this.$emit('closeModal');
    },
    refreshTable() {
      this.$emit('refreshTable');
    },
    async getPrograms() {
      const { data } = await CreativeDesignService.getPrograms();
      this.programs = data;
    },
    async getMaterialTypes() {
      const { data } = await CreativeDesignService.getMaterialTypes();
      data.forEach(element => {
        if (element.id === 9 || element.id === 11) {
          element.name = element.name.toUpperCase();
        }
      });
      this.materialTypes = data;
      this.materialTypesCpy = data;      
    },
    cropImage(id) {
      switch (id) {
        case 1: // BC
          return {
            height: '43px',
            width: '70px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          };
        case 2:
          return { // BC
            height: '41px',
            width: '86px',
            objectFit: 'cover',
            objectPosition: '0 -17%',
            margin: '0 0 3px 0',
          };
        case 3: // CE
          return {
            height: '40px',
            width: '39px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
            margin: '0 0 4px 0',
          };
        case 4: // DS
          return {
            height: '42px',
            width: '57px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
            margin: '0 0 2px 0',
          };
        case 5: // TR
          return {
            height: '39px',
            width: '72px',
            objectFit: 'cover',
            objectPosition: '20% -5%',
            margin: '0 0 5px 0',
          };
        case 6:
          return {
            height: '41px',
            width: '50px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          };
        case 8:
          return {
            height: '41px',
            width: '50px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          };
        case 10:
          return {
            height: '41px',
            width: '36px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
            margin: '0 0 1px 0',
          };
        default:
          break;
      }
    },
    changeProgram(program) {
      this.form.program_selected = program.id;
      this.form.initial = program.initial;
    },
    async getRequest() {
      try {
        const params = {
          design_request_id: this.idRequest,
        };
        const { data } = await CreativeDesignService.getRequestDesign(params);
        const form_data = data[0];

        let parrilla_data = [];
        if (form_data.content_type_id == 7) {
          parrilla_data = form_data.parrilla;
        } else {
          parrilla_data = JSON.parse(form_data.content);
        }

        // if(parrilla_data.length){
        //   parrilla_data.forEach(element => {
        //     element.notes === 'null' ?  element.notes = '' : '';
        //     element.references === 'null' ? element.references = '' : '';
        //     element.text === 'null' ? element.text = '' : '';
        //   });
        // }

        this.form.program_selected = form_data.program_id;
        this.nameDocument = form_data.document_name;
        this.form.request_date = form_data.due_date;
        this.form.content_type = form_data.content_type_id;
        this.form.pieces_qty = form_data.pieces_quantity;
        this.form.variants_qty = form_data.variants_quantity;
        this.form.observation = form_data.observation;
        this.form.idRequest = form_data.designRequestId;
        this.form.parrilla = parrilla_data;
        this.form.content = JSON.parse(form_data.content);
        this.filterOptions();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    filterOptions(){
      this.materialSelecteds = [];
      let material = this.materialTypesCpy.filter(material => {
        return this.form.content.some(item => item.content_type == material.id)
      })
      material.forEach(item => {
        this.materialSelecteds.push(item.id);
      });
    },
    async activeView(num) {
      this.statusForm = await this.$refs.form.validate();
      if (this.statusForm) {
        this.viewNumber = num;
        this.statusEditPieces = true;
      }
    },
    selectForm(id) {
      this.selectFormId = id;
    },
    async deleteItems() {
      const confirm = await this.showConfirmSwal();
      if (confirm.value) {
        if (this.form.content[this.idTypeStore].parrilla[this.idPiece].content_grid_id) {
          try {
            this.form.content[this.idTypeStore].pieces_qty = this.form.parrilla.length - 1;
            this.form.content[this.idTypeStore].parrilla[this.idPiece].deleted = true;
            this.showInstruction = true;
            const params = {
              design_request_id: this.form.content[this.idTypeStore].content_grid_id,
              content_type_id: this.form.content[this.idTypeStore].content_type,
              userId: this.currentUser.user_id,
              program_initial: '',
              parrilla: this.form.content[this.idTypeStore].parrilla[this.idPiece],
            };
            const result_delete = await CreativeDesignService.deleteContentGrid(params);
            this.form.content[this.idTypeStore].parrilla.splice(this.idPiece, 1); //delete
            this.UPDATE_ID_PIECE_CREATIVE_DESIGN(0);
            this.showInstruction = false;
            this.showSuccessSwal('Success!', 'Request saved successfully!');
            this.refreshTable();
          } catch (error) {
            this.showInstruction = false;
            this.showErrorSwal(error);
          }
        }else{
          //delete new form
          this.form.content[this.idTypeStore].parrilla[this.idPiece].deleted= true;
          this.form.content[this.idTypeStore].pieces_qty = this.form.parrilla.length - 1;
          this.form.content[this.idTypeStore].parrilla.splice(this.idPiece, 1); //delete
          this.UPDATE_ID_PIECE_CREATIVE_DESIGN(0);
        }
      }
    },
    async editPiecesQuantity() {
      const confirm = await this.showConfirmSwal();
      if (confirm.value) {
        this.statusEditPieces = false;
      }
    },
    changeContentType(index) {
      const result = this.materialTypes.find(field => field.id == this.form.content[index].content_type);
      this.form.content[index].name = result.name;
      this.filterOptions();
    },
    nameContentType(contentTypeId) {
      const result = this.materialTypes.find(field => field.id == contentTypeId);
      this.form.nameContentType = result.name;
    },
    async deletePiecesCarousel(index) {
      try {
        this.showInstruction = true;
        const params = {
          programId: this.form.program_selected,
          requestDate: this.form.request_date,
          contentType: this.form.content_type,
          piecesQty: this.form.pieces_qty,
          variantQty: this.form.variants_qty,
          programInitial: this.form.initial,
          nameDocument: this.nameDocument,
          userId: this.currentUser.user_id,
          observation: this.form.observation,
          idRequest: this.form.idRequest,
          parrilla: this.form.parrilla,
        };
        await CreativeDesignService.updateRequest(params);
        this.showInstruction = false;
        this.form.parrilla[this.idPiece].pieces.splice(index, 1);
      } catch (error) {
        this.showErrorSwal(error);
        this.showInstruction = false;
      }
    },
    afterItem() {
      if ((this.idPiece + 1) < this.form.parrilla.length) {
        this.UPDATE_ID_PIECE_CREATIVE_DESIGN(this.idPiece + 1);
      }
    },
    beforeItem() {
      if (this.idPiece > 0) {
        this.UPDATE_ID_PIECE_CREATIVE_DESIGN(this.idPiece - 1);
      }
    },
    addContent(){
       this.form.content.push({
          pieces_qty: null,
          content_type: null,
          parrilla: [],
       });
    },
    addFormFields() {
      // FLYER - GIT
      if(this.form.content[this.idTypeStore].content_type == 6 
      || this.form.content[this.idTypeStore].content_type == 2 
      || this.form.content[this.idTypeStore].content_type == 1) {
        this.statusButtonPiece = false;
        const name = "";
        this.form.content[this.idTypeStore].parrilla.push({
          text: '',
          keywords: [],
          notes: '',
          references: '',
          variants: 0,
          uuid: uuidv4(),
          files: [],
          titleTab: name,
        });
        setTimeout(() => {
          this.statusButtonPiece = true;
        }, 300);
      }else if(this.form.content[this.idTypeStore].content_type == 3 ||
        this.form.content[this.idTypeStore].content_type == 4 ||
        this.form.content[this.idTypeStore].content_type == 5) {
        this.statusButtonPiece = false;
        const name = "";
        // IGTV
        this.form.content[this.idTypeStore].parrilla.push({
          start: '',
          body: '',
          outcome: '',
          end: '',
          closing: '',
          keywords: [],
          notes: '',
          references: '',
          uuid: uuidv4(),
          files: [],
          titleTab: name,
          variants: 0,
        });
        setTimeout(() => {
          this.statusButtonPiece = true;
        }, 300);
      }else if(this.form.content[this.idTypeStore].content_type == 7) {
        // CAROUSEL
        this.statusButtonPiece = false;
        const name = "";
        this.form.content[this.idTypeStore].parrilla.push({
          pieces: [
            { text: '' },
            { text: '' },
            { text: '' },
          ],
          keywords: [],
          notes: '',
          references: '',
          uuid: uuidv4(),
          files: [],
          titleTab: name,
          variants: 0,
        });
        setTimeout(() => {
          this.statusButtonPiece = true;
        }, 300);
      }
    },
    async createSubTab(){
      if (this.typeModal == 'EDIT') {
        if(this.form.content[this.idTypeStore] && (this.form.content[this.idTypeStore].parrilla.length > 0)) {
          this.form.content[this.idTypeStore].parrilla.splice(0, 1); //delete key 0
        }
        this.statusButtonPiece = false;
        this.statusFlyer = false;
        this.statusCarousel = false;
        this.statusIgtv = false;
        const params = {
          requestId: this.form.content[this.idTypeStore].content_id,
          userId: this.currentUser.user_id,
        };
        const result = await CreativeDesignService.getDataByRequestId(params);
        if(result){
          this.form.content[this.idTypeStore].parrilla = JSON.parse(result.data[0].parrilla);
          this.FORM_DESIGN_REQUEST(this.form);
          this.statusButtonPiece = true;
          this.statusFlyer = true;
          this.statusCarousel = true;
          this.statusIgtv = true;
        }
      }
    },
    async getRequestContentGrid() {
      this.viewNumber = 2;
      this.UPDATE_ID_TYPE_CREATIVE_DESIGN(0);
      this.form.content[0].content_type = this.dataRequest.content_type_id;

      if (this.dataRequest.content_type_id == 1 ||
      this.dataRequest.content_type_id == 2 ||
      this.dataRequest.content_type_id == 6) {
        this.statusFlyer = true;
      } else if (this.dataRequest.content_type_id == 3 ||
      this.dataRequest.content_type_id == 4 ||
      this.dataRequest.content_type_id == 5) {
        this.statusIgtv = true;
      } else if (this.dataRequest.content_type_id == 7) {
        this.statusCarousel = true;
      }

      this.statusIgtv = false;
      this.statusFlyer = false;
      this.statusCarousel = false;
      this.typeForm = this.dataRequest.content_type_id;

      this.showInstruction = true;
      

      const params = {
        content_type_id: this.dataRequest.content_type_id,
        content_grid_id: parseInt(this.pieceSelected) > 0 ? this.pieceSelected : this.dataRequest.detail_id,
      };
      const resultGrid = await CreativeDesignService.getContentGridById(params);
      if(resultGrid || resultGrid.length > 0 ) {
        resultGrid.data[0].keywords = JSON.parse(resultGrid.data[0].keywords);
        if(this.dataRequest.content_type_id == 7){
          resultGrid.data[0].pieces =  JSON.parse(resultGrid.data[0].pieces);
        }

        resultGrid.data[0].notes =  resultGrid.data[0].notes == 'null'  ?  '' : resultGrid.data[0].notes;
        resultGrid.data[0].references =  resultGrid.data[0].references == 'null' ?  '' : resultGrid.data[0].references;
        resultGrid.data[0].text =  resultGrid.data[0].text == 'null' ?  '' : resultGrid.data[0].text;

        this.form.content[0].parrilla[0] = resultGrid.data[0];

        this.statusIgtv = true;
          this.statusFlyer = true;
          this.statusCarousel = true;
          this.showInstruction = false;


      }
    },
            


        },
    };
  </script>
  
  <style scoped>
  


  </style>
  
  <style lang="scss" scoped>


  </style>
  