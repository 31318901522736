<template>
  <div>
    <b-modal
      size="lg"
      body-class="p-0"
      no-close-on-backdrop
      @hidden="$emit('hidden')"
      ref="modal-update-role-user-team-rank"
      id="modal-1"
      title="UPDATE USER ROLE"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <div class="form-group col-md-6">
              <b-form-group label="USER"
                >
                <b-form-input
                  disabled="disabled"
                  type="text"
                  v-model="dataUpdateUser.full_name"
                  class="form-control text-center rounded text-primary border-primary"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="form-group col-md-6">
              <b-form-group label="ROLE">
                <b-form-input
                  disabled="disabled"
                  type="text"
                  class="form-control text-center rounded text-primary border-primary"
                  v-model="dataUpdateUser.rol"
                >
                </b-form-input>
              </b-form-group>
            </div>

            <div class="form-group col-md-12">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="type"
              >
                <b-form-group :class="errors[0]" label="ROLES">
                  <v-select
                    v-model="dataUser.id_role"
                    label="name"
                    :options="optionsRoles"
                    :reduce="(option) => option.id"
                  >
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button class="" @click="cancel()">CANCEL</b-button>
        </div>
        <div>
          <b-button variant="primary" @click="updateRoleUser()"
            ><feather-icon icon="SaveIcon" class="mr-1" />SAVE</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import SettingsService from "@/views/social-network/views/settings/settings.service";
import vSelect from "vue-select";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },

  props: {
    dataUpdateUser: {
      type: Object,
    },
  },
  async created() {
    await this.getAllRole();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-update-role-user-team-rank");
  },
  data() {
    return {
      optionsRoles: [],
      dataUser: {
        id_user: "",
        id_role: "",
      },
    };
  },
  methods: {
    cancel() {
      this.$emit("hidden");
    },
    async getAllRole() {
      try {
        const { data } = await SettingsService.getAllRoles();
        this.optionsRoles = data;
      } catch (e) {
        console.log(error);
      }
    },
    async updateRoleUser() {
      try {
        const response = await this.showConfirmSwal();
        this.dataUser.id_user = this.dataUpdateUser.id_user;
        
        const params = {
          id_user: this.dataUser.id_user,
          id_rol: this.dataUser.id_role,
          created_by: this.currentUser.user_id,
        };
        if (response.isConfirmed) {
          this.addPreloader();
          const data = await SettingsService.updateRoleUser(params);
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            this.$emit('closeModalupdateRoleUser');
            //             this.$emit("refreshGrid", this.dataUser.id_user);
            // this.dataUser.id_user = "";
          }
        }
      } catch (error) {}
    },
    
  },
};
</script>
