export default [
    {
      type: 'datepicker',
      margin: true,
      showLabel: true,
      label: 'CREATED FROM',
      placeholder: 'Date',
      class: 'font-small-3',
      model: null,
      locale: 'en',
      dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
      cols: 6,
    },
    {
      type: 'datepicker',
      margin: true,
      showLabel: true,
      label: 'CREATED TO',
      placeholder: 'Date',
      class: 'font-small-3',
      model: null,
      locale: 'en',
      dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
      cols: 6,
    },
    {
      type: 'select',
      margin: true,
      showLabel: true,
      label: 'STATUS',
      model: null,
      options: [
        { id: 0, description: 'Inactive'},
        { id: 1, description: 'Active'}
      ],
      reduce: 'id',
      selectText: 'description',
      cols: 12,
    },
    {
      type: 'select',
      margin: true,
      showLabel: true,
      label: 'ROLE',
      model: null,
      options: [],
      reduce: 'id',
      selectText: 'name',
      cols: 12,
    },
  ]
  